/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Block Toolbar/Formatting Buttons
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
#start-resizable-editor-section {
  display: none; }

.block-editor ul.wp-block-archives {
  padding-left: 2.5em; }

.wp-block-audio {
  margin-left: 0;
  margin-right: 0; }

.edit-post-visual-editor .block-library-block__reusable-block-container .block-editor-writing-flow__click-redirect {
  min-height: auto; }

.block-editor-block-list__block[data-type="core/button"][data-align="center"] {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.block-editor-block-list__block[data-type="core/button"][data-align="right"] {
  /*!rtl:ignore*/
  text-align: right; }

.wp-block-button {
  position: relative; }
  .wp-block-button [contenteditable] {
    cursor: text; }
  .wp-block-button:not(.has-text-color):not(.is-style-outline) [data-rich-text-placeholder]::after {
    color: #fff; }
  .wp-block-button .block-editor-rich-text__editable:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2;
    outline: 2px solid transparent;
    outline-offset: -2px; }
  .wp-block-button [data-rich-text-placeholder]::after {
    opacity: 0.8; }

.wp-block-button__inline-link {
  color: #555d66;
  height: 0;
  overflow: hidden;
  max-width: 290px; }
  .wp-block-button__inline-link-input__suggestions {
    max-width: 290px; }
  @media (min-width: 782px) {
    .wp-block-button__inline-link {
      max-width: 260px; }
      .wp-block-button__inline-link-input__suggestions {
        max-width: 260px; } }
  @media (min-width: 960px) {
    .wp-block-button__inline-link {
      max-width: 290px; }
      .wp-block-button__inline-link-input__suggestions {
        max-width: 290px; } }
  .is-selected .wp-block-button__inline-link {
    height: auto;
    overflow: visible;
    margin-top: 16px; }

div[data-type="core/button"] {
  display: table; }

.wp-block-buttons .wp-block.block-editor-block-list__block[data-type="core/button"] {
  display: inline-block;
  width: auto; }

.wp-block-buttons[data-align="center"] .block-editor-block-list__layout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; }

.wp-block-buttons[data-align="right"] .block-editor-block-list__layout {
  display: flex;
  justify-content: flex-end; }

.wp-block-buttons .block-editor-block-list__layout > div:last-child {
  display: inline-block; }

.block-editor .wp-block-categories ul {
  padding-left: 2.5em; }
  .block-editor .wp-block-categories ul ul {
    margin-top: 6px; }

.wp-block-code .block-editor-plain-text {
  font-family: Menlo, Consolas, monaco, monospace;
  color: #23282d;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .wp-block-code .block-editor-plain-text {
      font-size: 13px; } }
  .wp-block-code .block-editor-plain-text:focus {
    box-shadow: none; }

.wp-block-columns .wp-block {
  max-width: none; }

.editor-styles-wrapper .block-editor-block-list__block.wp-block-column,
.editor-styles-wrapper .block-editor-block-list__block.wp-block-columns {
  margin-top: 0;
  margin-bottom: 0; }

@media (min-width: 600px) {
  .editor-styles-wrapper
.block-editor-block-list__block.wp-block-column:nth-child(even) {
    margin-left: 32px; } }

@media (min-width: 782px) {
  .editor-styles-wrapper
.block-editor-block-list__block.wp-block-column:not(:first-child) {
    margin-left: 32px; } }

.wp-block-column > *:first-child {
  margin-top: 0 !important; }

.wp-block-column > *:last-child {
  margin-bottom: 0 !important; }

.wp-block-cover-image.components-placeholder h2,
.wp-block-cover.components-placeholder h2 {
  color: inherit; }

.wp-block-cover-image .block-editor-block-list__layout,
.wp-block-cover .block-editor-block-list__layout {
  width: 100%; }

.wp-block-cover-image .block-editor-block-list__block,
.wp-block-cover .block-editor-block-list__block {
  color: #f8f9f9; }

.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  text-align: left; }

.wp-block-cover-image .wp-block-cover__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout,
.wp-block-cover .wp-block-cover__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }

.wp-block-cover-image .wp-block-cover__placeholder-background-options,
.wp-block-cover .wp-block-cover__placeholder-background-options {
  width: 100%; }

[data-align="left"] .wp-block-cover-image,
[data-align="right"] .wp-block-cover-image, [data-align="left"]
.wp-block-cover,
[data-align="right"]
.wp-block-cover {
  max-width: 290px;
  width: 100%; }

.block-library-cover__reset-button {
  margin-left: auto; }

.block-library-cover__resize-container:not(.is-resizing) {
  height: auto !important; }

.wp-block-embed {
  margin-left: 0;
  margin-right: 0;
  clear: both; }
  @media (min-width: 600px) {
    .wp-block-embed {
      min-width: 360px; }
      .wp-block-embed.components-placeholder {
        min-width: 0; } }
  .wp-block-embed.is-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    min-height: 200px;
    text-align: center;
    background: #f8f9f9; }
    .wp-block-embed.is-loading p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 13px; }
  .wp-block-embed .components-placeholder__error {
    word-break: break-word; }
  .wp-block-embed .components-placeholder__learn-more {
    margin-top: 1em; }

.block-library-embed__interactive-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0; }

.wp-block-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0; }
  .wp-block-file .wp-block-file__content-wrapper {
    flex-grow: 1; }
  .wp-block-file .wp-block-file__textlink {
    display: inline-block;
    min-width: 1em; }
    .wp-block-file .wp-block-file__textlink:focus {
      box-shadow: none; }
  .wp-block-file .wp-block-file__button-richtext-wrapper {
    display: inline-block;
    margin-left: 0.75em; }
  .wp-block-file .wp-block-file__copy-url-button {
    margin-left: 1em; }

.wp-block-freeform.block-library-rich-text__tinymce {
  /* Remove blue highlighting of selected images in WebKit */
  /* Image captions */
  /* WP Views */ }
  .wp-block-freeform.block-library-rich-text__tinymce p,
  .wp-block-freeform.block-library-rich-text__tinymce li {
    line-height: 1.8; }
  .wp-block-freeform.block-library-rich-text__tinymce ul,
  .wp-block-freeform.block-library-rich-text__tinymce ol {
    padding-left: 2.5em;
    margin-left: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce blockquote {
    margin: 0;
    box-shadow: inset 0 0 0 0 #e2e4e7;
    border-left: 4px solid #000;
    padding-left: 1em; }
  .wp-block-freeform.block-library-rich-text__tinymce pre {
    white-space: pre-wrap;
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 14px;
    color: #23282d; }
  .wp-block-freeform.block-library-rich-text__tinymce > *:first-child {
    margin-top: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce > *:last-child {
    margin-bottom: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce.mce-edit-focus {
    outline: none; }
  .wp-block-freeform.block-library-rich-text__tinymce a {
    color: #007fac; }
  .wp-block-freeform.block-library-rich-text__tinymce:focus a[data-mce-selected] {
    padding: 0 2px;
    margin: 0 -2px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #e5f5fa;
    background: #e5f5fa; }
  .wp-block-freeform.block-library-rich-text__tinymce code {
    padding: 2px;
    border-radius: 2px;
    color: #23282d;
    background: #f3f4f5;
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 14px; }
  .wp-block-freeform.block-library-rich-text__tinymce:focus code[data-mce-selected] {
    background: #e8eaeb; }
  .wp-block-freeform.block-library-rich-text__tinymce .alignright {
    /*rtl:ignore*/
    float: right;
    /*rtl:ignore*/
    margin: 0.5em 0 0.5em 1em; }
  .wp-block-freeform.block-library-rich-text__tinymce .alignleft {
    /*rtl:ignore*/
    float: left;
    /*rtl:ignore*/
    margin: 0.5em 1em 0.5em 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-block-freeform.block-library-rich-text__tinymce .wp-more-tag {
    width: 96%;
    height: 20px;
    display: block;
    margin: 15px auto;
    outline: 0;
    cursor: default;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAADtgAAAAoBAMAAAA86gLBAAAAJFBMVEVMaXG7u7vBwcHDw8POzs68vLzGxsbMzMy+vr7AwMDQ0NDGxsYKLGzpAAAADHRSTlMA///zWf+/f///TMxNVGuqAAABwklEQVR4Ae3dMXLaQBTH4bfj8UCpx8hq0vgKvgFNemhT6Qo6gg6R+0ZahM2QLmyBJ99XWP9V5+o3jIUcLQEAAAAAAAAAAAAAAAAAAAAAAABQ8j0WL9lfTtlt18uNXAUA8O/KVtfa1tdcrOdSh9gCQAMlh1hMNbZZ1bsrsQWABsrhLRbz7z5in/32UbfUMUbkMQCAh5RfGYv82UdMdZ6HS2wjT2ILAI8r3XmM2B3WvM59vfO2xXYW2yYAENuPU8S+X/N67mKxzy225yaxBQCxLV392UdcvwV0jPVUj98ntkBWT7C7+9u2/V/vGtvXIWJ6/4rtbottWa6Ri0NUT/u72LYttrb97LHdvUXMxxrb8TO2W2TF1rYbbLG1bbGNjMi4+2Sbi1FsbbvNFlvbFtt5fDnE3d9sP1/XeIyV2Nr2U2/guZUuptNrH/dPI9eLB6SaAEBs6wPJf3/PNk9tYgsAYrv/8TFuzx/fvkFqGtrEFgDEdpcZUb7ejXy6ntrEFgDENvL6gsas4vbdyKt4DACI7TxElJv/Z7udpqFNbAFAbKduy2uU2trttM/x28UWAAAAAAAAAAAAAAAAAAAAAAAAAADgDyPwGmGTCZp7AAAAAElFTkSuQmCC);
    background-size: 1900px 20px;
    background-repeat: no-repeat;
    background-position: center; }
  .wp-block-freeform.block-library-rich-text__tinymce img::selection {
    background-color: transparent; }
  .wp-block-freeform.block-library-rich-text__tinymce div.mceTemp {
    -ms-user-select: element; }
  .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption {
    margin: 0;
    /* dl browser reset */
    max-width: 100%; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption a,
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption img {
      display: block; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption,
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption * {
      -webkit-user-drag: none; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption .wp-caption-dd {
      padding-top: 0.5em;
      margin: 0;
      /* browser dd reset */ }
  .wp-block-freeform.block-library-rich-text__tinymce .wpview {
    width: 99.99%;
    /* All IE need hasLayout, incl. 11 (ugh, not again!!) */
    position: relative;
    clear: both;
    margin-bottom: 16px;
    border: 1px solid transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview iframe {
      display: block;
      max-width: 100%;
      background: transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .mce-shim {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected="2"] .mce-shim {
      display: none; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .loading-placeholder {
      border: 1px dashed #e8eaeb;
      padding: 10px; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .wpview-error {
      border: 1px solid #e8eaeb;
      padding: 1em 0;
      margin: 0;
      word-wrap: break-word; }
      .wp-block-freeform.block-library-rich-text__tinymce .wpview .wpview-error p {
        margin: 0;
        text-align: center; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected] .loading-placeholder,
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected] .wpview-error {
      border-color: transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .dashicons {
      display: block;
      margin: 0 auto;
      width: 32px;
      height: 32px;
      font-size: 32px; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview.wpview-type-gallery::after {
      content: "";
      display: table;
      clear: both; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery img[data-mce-selected]:focus {
    outline: none; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery a {
    cursor: default; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery {
    margin: auto -6px;
    padding: 6px 0;
    line-height: 1;
    overflow-x: hidden; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-item {
    float: left;
    margin: 0;
    text-align: center;
    padding: 6px;
    box-sizing: border-box; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-caption,
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-icon {
    margin: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-caption {
    font-size: 13px;
    margin: 4px 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-1 .gallery-item {
    width: 100%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-2 .gallery-item {
    width: 50%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-3 .gallery-item {
    width: 33.33333%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-4 .gallery-item {
    width: 25%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-5 .gallery-item {
    width: 20%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-6 .gallery-item {
    width: 16.66667%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-7 .gallery-item {
    width: 14.28571%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-8 .gallery-item {
    width: 12.5%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-9 .gallery-item {
    width: 11.11111%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery img {
    max-width: 100%;
    height: auto;
    border: none;
    padding: 0; }

div[data-type="core/freeform"]::before {
  transition: border-color 0.1s linear, box-shadow 0.1s linear;
  border: 1px solid #e2e4e7;
  outline: 1px solid transparent; }
  @media (prefers-reduced-motion: reduce) {
    div[data-type="core/freeform"]::before {
      transition-duration: 0s; } }

div[data-type="core/freeform"].is-selected::before {
  border-color: #1e1e1e; }

div[data-type="core/freeform"] .block-editor-block-contextual-toolbar + div {
  margin-top: 0;
  padding-top: 0; }

div[data-type="core/freeform"].is-selected .block-library-rich-text__tinymce::after {
  content: "";
  display: table;
  clear: both; }

.mce-toolbar-grp .mce-btn.mce-active button,
.mce-toolbar-grp .mce-btn.mce-active:hover button,
.mce-toolbar-grp .mce-btn.mce-active i,
.mce-toolbar-grp .mce-btn.mce-active:hover i {
  color: #23282d; }

.mce-toolbar-grp .mce-rtl .mce-flow-layout-item.mce-last {
  margin-right: 0;
  margin-left: 8px; }

.mce-toolbar-grp .mce-btn i {
  font-style: normal; }

.block-library-classic__toolbar {
  display: none;
  width: auto;
  margin: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 8px;
  border: 1px solid #e2e4e7;
  border-bottom: none;
  border-radius: 2px;
  margin-bottom: 8px;
  padding: 0; }
  div[data-type="core/freeform"].is-selected .block-library-classic__toolbar,
  div[data-type="core/freeform"].is-typing .block-library-classic__toolbar {
    display: block;
    border-color: #1e1e1e; }
  .block-library-classic__toolbar .mce-tinymce {
    box-shadow: none; }
  @media (min-width: 600px) {
    .block-library-classic__toolbar {
      padding: 0; } }
  .block-library-classic__toolbar:empty {
    display: block;
    background: #f5f5f5;
    border-bottom: 1px solid #e2e4e7; }
    .block-library-classic__toolbar:empty::before {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 13px;
      content: attr(data-placeholder);
      color: #555d66;
      line-height: 37px;
      padding: 14px; }
  .block-library-classic__toolbar div.mce-toolbar-grp {
    border-bottom: 1px solid #1e1e1e; }
  .block-library-classic__toolbar .mce-tinymce-inline,
  .block-library-classic__toolbar .mce-tinymce-inline > div,
  .block-library-classic__toolbar div.mce-toolbar-grp,
  .block-library-classic__toolbar div.mce-toolbar-grp > div,
  .block-library-classic__toolbar .mce-menubar,
  .block-library-classic__toolbar .mce-menubar > div {
    height: auto !important;
    width: 100% !important; }
  .block-library-classic__toolbar .mce-container-body.mce-abs-layout {
    overflow: visible; }
  .block-library-classic__toolbar .mce-menubar,
  .block-library-classic__toolbar div.mce-toolbar-grp {
    position: static; }
  .block-library-classic__toolbar .mce-toolbar-grp > div {
    padding: 1px 3px; }
  .block-library-classic__toolbar .mce-toolbar-grp .mce-toolbar:not(:first-child) {
    display: none; }
  .block-library-classic__toolbar.has-advanced-toolbar .mce-toolbar-grp .mce-toolbar {
    display: block; }

.wp-block-gallery li {
  list-style-type: none; }

.wp-block-gallery .block-editor-media-placeholder {
  margin-bottom: 12px;
  padding: 12px; }
  .wp-block-gallery .block-editor-media-placeholder .components-placeholder__label {
    display: none; }
  .wp-block-gallery .block-editor-media-placeholder .components-button {
    margin-bottom: 0; }

figure.wp-block-gallery {
  display: block;
  margin: 0; }

.blocks-gallery-grid.blocks-gallery-grid {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0; }

.blocks-gallery-item figure:not(.is-selected):focus,
.blocks-gallery-item img:focus {
  outline: none; }

.blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007cba;
  border-radius: 2px;
  outline: 2px solid transparent; }

body.admin-color-sunrise .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #d1864a; }

body.admin-color-ocean .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #a3b9a2; }

body.admin-color-midnight .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #e14d43; }

body.admin-color-ectoplasm .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #a7b656; }

body.admin-color-coffee .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #c2a68c; }

body.admin-color-blue .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #d9ab59; }

body.admin-color-light .blocks-gallery-item figure.is-selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0085ba; }
  .blocks-gallery-item figure.is-selected img {
    border-radius: 2px; }

.blocks-gallery-item figure.is-transient img {
  opacity: 0.3; }

.blocks-gallery-item .is-selected .block-library-gallery-item__move-menu,
.blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu {
  background: #fff;
  border: 1px solid #1e1e1e;
  border-radius: 4px;
  transition: box-shadow 0.2s ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu,
    .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu {
      transition-duration: 0s; } }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu:hover,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button {
    color: #1e1e1e;
    min-width: 24px;
    height: 24px; }
    @media (min-width: 600px) {
      .columns-7 .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button,
      .columns-8 .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button, .columns-7
      .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button,
      .columns-8
      .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button {
        padding: 0;
        width: inherit;
        height: inherit; } }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button:focus,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button:focus {
    color: inherit; }

.block-library-gallery-item__move-menu,
.block-library-gallery-item__inline-menu {
  margin: 8px;
  display: inline-flex;
  z-index: 20; }
  .block-library-gallery-item__move-menu .components-button,
  .block-library-gallery-item__inline-menu .components-button {
    color: transparent; }
  @media (min-width: 600px) {
    .columns-7 .block-library-gallery-item__move-menu,
    .columns-8 .block-library-gallery-item__move-menu, .columns-7
    .block-library-gallery-item__inline-menu,
    .columns-8
    .block-library-gallery-item__inline-menu {
      padding: 2px; } }

.block-library-gallery-item__inline-menu {
  position: absolute;
  top: -2px;
  right: -2px; }

.block-library-gallery-item__move-menu {
  position: absolute;
  top: -2px;
  left: -2px; }

.blocks-gallery-item__move-backward.components-button,
.blocks-gallery-item__move-forward.components-button,
.blocks-gallery-item__remove.components-button {
  padding: 0; }

.blocks-gallery-item .components-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px; }

/**
 * Group: All Alignment Settings
 */
.wp-block[data-type="core/group"] .block-editor-block-list__insertion-point {
  left: 0;
  right: 0; }

.wp-block[data-type="core/group"] > div > .wp-block-group.has-background > .wp-block-group__inner-container > .block-editor-inner-blocks {
  margin-top: -32px;
  margin-bottom: -32px; }

.wp-block[data-type="core/group"] > div > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block[data-align="full"] {
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px; }
  @media (min-width: 600px) {
    .wp-block[data-type="core/group"] > div > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block[data-align="full"] {
      padding-left: 58px;
      padding-right: 58px; } }

.wp-block[data-type="core/group"] > div > .wp-block-group.has-background > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block[data-align="full"] {
  margin-left: -30px;
  width: calc(100% + 60px); }

/**
 * Group: Full Width Alignment
 */
.wp-block[data-type="core/group"][data-align="full"] > div > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0; }
  .wp-block[data-type="core/group"][data-align="full"] > div > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout {
    margin-left: 0;
    margin-right: 0; }

.wp-block[data-type="core/group"][data-align="full"] > div > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block[data-align="full"] {
  padding-right: 0;
  padding-left: 0;
  left: 0;
  width: 100%;
  max-width: none; }

.wp-block[data-type="core/group"][data-align="full"] > div > .wp-block-group.has-background > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block[data-align="full"] {
  width: calc(100% + 60px); }

[data-type="core/group"].is-selected .block-list-appender {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/group"].is-selected .has-background .block-list-appender {
  margin-top: 18px;
  margin-bottom: 18px; }

.wp-block-html {
  margin-bottom: 28px; }
  .wp-block-html .block-library-html__preview-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .wp-block-html .block-editor-plain-text {
    font-family: Menlo, Consolas, monaco, monospace;
    color: #23282d;
    padding: 0.8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px;
    max-height: 250px;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px; }
    @media (min-width: 600px) {
      .wp-block-html .block-editor-plain-text {
        font-size: 13px; } }
    .wp-block-html .block-editor-plain-text:focus {
      box-shadow: none; }

.wp-block-image {
  position: relative;
  margin-left: 0;
  margin-right: 0; }
  .wp-block-image.is-transient img {
    opacity: 0.3; }
  .wp-block-image figcaption img {
    display: inline; }
  .wp-block-image .components-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px; }

.wp-block-image .components-resizable-box__container {
  display: inline-block; }
  .wp-block-image .components-resizable-box__container img {
    display: block;
    width: inherit;
    height: inherit; }

.wp-block-image.is-focused .components-resizable-box__handle {
  display: block; }

.block-editor-block-list__block[data-type="core/image"][data-align="center"] .wp-block-image {
  margin-left: auto;
  margin-right: auto; }

.block-editor-block-list__block[data-type="core/image"][data-align="center"][data-resized="false"] .wp-block-image > div {
  margin-left: auto;
  margin-right: auto; }

.block-editor-block-list__block[data-type="core/image"] .block-editor-block-toolbar .block-editor-url-input__button-modal {
  position: absolute;
  left: 0;
  right: 0;
  margin: -1px 0; }
  @media (min-width: 600px) {
    .block-editor-block-list__block[data-type="core/image"] .block-editor-block-toolbar .block-editor-url-input__button-modal {
      margin: -1px; } }

[data-type="core/image"][data-align="center"] figure,
[data-type="core/image"][data-align="left"] figure,
[data-type="core/image"][data-align="right"] figure {
  margin: 0; }

[data-type="core/image"][data-align="wide"] figure img,
[data-type="core/image"][data-align="full"] figure img {
  width: 100%; }

[data-type="core/image"] figure.is-resized {
  margin: 0; }

.wp-block-latest-comments.has-avatars .avatar {
  margin-right: 10px; }

.wp-block-latest-comments__comment-excerpt p {
  font-size: 14px;
  line-height: 1.8;
  margin: 5px 0 20px;
  padding-top: 0; }

.wp-block-latest-comments.has-avatars .wp-block-latest-comments__comment {
  min-height: 36px; }

.block-editor .wp-block-latest-posts {
  padding-left: 2.5em; }
  .block-editor .wp-block-latest-posts.is-grid {
    padding-left: 0; }

.wp-block-latest-posts li a > div {
  display: inline; }

.wp-block-legacy-widget__edit-container,
.wp-block-legacy-widget__preview {
  padding-left: 2.5em;
  padding-right: 2.5em; }

.wp-block-legacy-widget__edit-container .widget-inside {
  border: none;
  display: block;
  box-shadow: none; }

.wp-block-legacy-widget__update-button {
  margin-left: auto;
  display: block; }

.wp-block-legacy-widget__preview {
  overflow: auto; }

.wp-block-legacy-widget__edit-widget-title {
  margin: -14px -13px 0;
  background: #f8f9f9;
  color: #555d66;
  top: -13px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 8px 14px;
  font-weight: 600; }

.wp-block-media-text .__resizable_base__ {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
  -ms-grid-row: 2;
  grid-row: 2; }

.wp-block-media-text.is-vertically-aligned-top .block-editor-inner-blocks,
.wp-block-media-text.is-vertically-aligned-top .editor-media-container__resizer {
  -ms-grid-row-align: start;
      align-self: start; }

.wp-block-media-text .block-editor-inner-blocks,
.wp-block-media-text .editor-media-container__resizer,
.wp-block-media-text.is-vertically-aligned-center .block-editor-inner-blocks,
.wp-block-media-text.is-vertically-aligned-center .editor-media-container__resizer {
  -ms-grid-row-align: center;
      align-self: center; }

.wp-block-media-text.is-vertically-aligned-bottom .block-editor-inner-blocks,
.wp-block-media-text.is-vertically-aligned-bottom .editor-media-container__resizer {
  -ms-grid-row-align: end;
      align-self: end; }

.wp-block-media-text .editor-media-container__resizer {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: 100% !important; }

.wp-block-media-text.is-image-fill .editor-media-container__resizer {
  height: 100% !important; }

.wp-block-media-text.has-media-on-the-right .editor-media-container__resizer {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1; }

.wp-block-media-text .block-editor-inner-blocks {
  word-break: break-word;
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  text-align: initial;
  padding: 0 8% 0 8%; }

.wp-block-media-text.has-media-on-the-right .block-editor-inner-blocks {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1; }

.wp-block-media-text > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-editor-block-list__block {
  max-width: unset; }

.editor-media-container__resizer .components-resizable-box__handle {
  display: none; }

.wp-block-media-text.is-selected:not(.is-stacked-on-mobile) .editor-media-container__resizer .components-resizable-box__handle {
  display: block; }

@media (min-width: 600px) {
  .wp-block-media-text.is-selected.is-stacked-on-mobile .editor-media-container__resizer .components-resizable-box__handle {
    display: block; } }

@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile .block-editor-inner-blocks {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2; }
  .wp-block-media-text.is-stacked-on-mobile .editor-media-container__resizer {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .block-editor-inner-blocks {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .editor-media-container__resizer {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2; } }

.block-editor-block-list__block[data-type="core/more"] {
  max-width: 100%;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px; }

.block-editor .wp-block-more {
  display: block;
  text-align: center;
  white-space: nowrap; }
  .block-editor .wp-block-more input[type="text"] {
    position: relative;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #6c7781;
    border: none;
    box-shadow: none;
    white-space: nowrap;
    text-align: center;
    margin: 0;
    border-radius: 4px;
    background: #fff;
    padding: 6px 8px;
    height: 24px;
    max-width: 100%; }
    .block-editor .wp-block-more input[type="text"]:focus {
      box-shadow: none; }
  .block-editor .wp-block-more::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: 0;
    right: 0;
    border-top: 3px dashed #ccd0d4; }

[data-type="core/navigation"] .wp-block-navigation .block-editor-inner-blocks {
  flex: 1; }

[data-type="core/navigation"] .wp-block-navigation .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/navigation"] .wp-block-navigation.items-justification-left .block-editor-inner-blocks > .block-editor-block-list__layout {
  justify-content: flex-start; }

[data-type="core/navigation"] .wp-block-navigation.items-justification-center .block-editor-inner-blocks > .block-editor-block-list__layout {
  justify-content: center; }

[data-type="core/navigation"] .wp-block-navigation.items-justification-right .block-editor-inner-blocks > .block-editor-block-list__layout {
  justify-content: flex-end; }

[data-type="core/navigation"] .wp-block-navigation .block-editor-block-list__block::before {
  left: 0;
  right: 0; }

[data-type="core/navigation"].is-selected .wp-block-navigation .block-editor-block-list__block::before,
[data-type="core/navigation"].has-child-selected .wp-block-navigation .block-editor-block-list__block::before {
  border-color: transparent !important; }

[data-type="core/navigation"] .wp-block-navigation .block-editor-block-list__insertion-point {
  display: none; }

[data-type="core/navigation"] .wp-block-navigation .wp-block-navigation-link.is-selected .block-editor-rich-text__editable:focus {
  min-width: 20px; }

.wp-block-navigation .block-list-appender {
  margin: 0;
  display: flex;
  align-items: center; }

.wp-block-navigation__inserter-content {
  padding: 16px; }

/**
 * Colors Selector component
 */
.block-library-colors-selector {
  width: auto; }
  .block-library-colors-selector .block-library-colors-selector__toggle {
    display: block;
    margin: 0 auto;
    padding: 3px;
    width: auto; }
  .block-library-colors-selector .block-library-colors-selector__icon-container {
    height: 30px;
    position: relative;
    margin: 0 auto;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 4px; }
  .block-library-colors-selector .block-library-colors-selector__state-selection {
    margin-left: auto;
    margin-right: auto; }
  .block-library-colors-selector .block-library-colors-selector__state-selection {
    border-radius: 11px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    line-height: 20px;
    padding: 2px; }
    .block-library-colors-selector .block-library-colors-selector__state-selection.has-text-color > svg,
    .block-library-colors-selector .block-library-colors-selector__state-selection.has-text-color > svg path {
      color: inherit; }

.block-library-colors-selector__popover .color-palette-controller-container {
  padding: 16px; }

.block-library-colors-selector__popover .components-base-control__label {
  height: 20px;
  line-height: 20px; }

.block-library-colors-selector__popover .component-color-indicator {
  float: right;
  margin-top: 2px; }

.block-library-colors-selector__popover .components-panel__body-title {
  display: none; }

.wp-block-navigation-link__field .components-text-control__input.components-text-control__input,
.wp-block-navigation-link__container {
  border-radius: 0;
  line-height: 36px;
  min-height: 36px; }

/**
 * Adjust Navigation Item.
 */
.wp-block-navigation-link .block-editor-block-list__layout {
  display: block; }

.wp-block-navigation-link.is-editing, .wp-block-navigation-link.is-selected {
  min-width: 20px; }

.wp-block-navigation-link.has-link .wp-block-navigation-link__label {
  text-decoration: underline; }

.wp-block-navigation-link .block-editor-rich-text__editable.is-selected:not(.keep-placeholder-on-focus):not(:focus)[data-rich-text-placeholder]::after {
  display: inline-block; }

.wp-block-navigation-link .block-list-appender {
  margin: 16px;
  margin-left: 10px;
  margin-top: 10px; }

.wp-block-navigation-link__nofollow-external-link {
  display: block; }

.wp-block-navigation-link__separator {
  margin: 8px 0 8px;
  border-top: 1px solid #e2e4e7; }

.components-popover.wp-block-navigation-link__dropdown-content {
  margin-top: -1px;
  margin-left: -4px; }

.wp-block-navigation-link__dropdown-content .components-popover__content {
  padding: 8px 0; }

.wp-block-navigation .block-editor-block-list__block[data-type="core/navigation-link"] > .block-editor-block-list__insertion-point {
  display: none; }

.block-editor-block-list__block[data-type="core/nextpage"] {
  max-width: 100%;
  margin-top: 28px;
  margin-bottom: 28px; }

.wp-block-nextpage {
  display: block;
  text-align: center;
  white-space: nowrap; }
  .wp-block-nextpage > span {
    font-size: 13px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #6c7781;
    border-radius: 4px;
    background: #fff;
    padding: 6px 8px;
    height: 24px; }
  .wp-block-nextpage::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: 0;
    right: 0;
    border-top: 3px dashed #ccd0d4; }

.block-editor-block-list__block[data-type="core/paragraph"].has-drop-cap:focus {
  min-height: auto !important; }

.block-editor-block-list__block[data-type="core/paragraph"].is-selected::before {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: block-editor-block-list__block-fade-out-animation 0.3s ease-out 0.2s;
  animation-fill-mode: forwards; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__block[data-type="core/paragraph"].is-selected::before {
      animation-duration: 1ms; } }

.block-editor-block-list__block[data-type="core/paragraph"].is-selected:not(.is-typing)::before {
  background: rgba(0, 0, 0, 0.03); }
  .is-dark-theme .block-editor-block-list__block[data-type="core/paragraph"].is-selected:not(.is-typing)::before {
    background: rgba(255, 255, 255, 0.1); }

@keyframes block-editor-block-list__block-fade-out-animation {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.wp-block-post-excerpt__excerpt.is-inline {
  display: inline-block; }

.block-editor-block-list__block[data-type="core/pullquote"][data-align="left"] p, .block-editor-block-list__block[data-type="core/pullquote"][data-align="right"] p {
  font-size: 20px; }

.wp-block-pullquote blockquote p {
  font-size: 28px;
  line-height: 1.6; }

.wp-block-pullquote.is-style-solid-color {
  margin-left: 0;
  margin-right: 0; }
  .wp-block-pullquote.is-style-solid-color blockquote p {
    font-size: 32px; }
  .wp-block-pullquote.is-style-solid-color .wp-block-pullquote__citation {
    text-transform: none;
    font-style: normal; }

.wp-block-pullquote .wp-block-pullquote__citation {
  color: inherit; }

.wp-block-quote__citation {
  font-size: 13px; }

.block-editor .wp-block-rss {
  padding-left: 2.5em; }
  .block-editor .wp-block-rss.is-grid {
    padding-left: 0; }

.wp-block-rss li a > div {
  display: inline; }

.blocks-rss__placeholder-form {
  display: flex;
  align-items: stretch; }
  .blocks-rss__placeholder-form > * {
    margin-bottom: 8px; }
  @media (min-width: 782px) {
    .blocks-rss__placeholder-form > * {
      margin-bottom: 0; } }

.blocks-rss__placeholder-input {
  display: flex;
  align-items: stretch;
  flex-grow: 1; }
  .blocks-rss__placeholder-input .components-base-control__field {
    margin: 0;
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    margin-right: 8px; }

.wp-block-search__input {
  border-radius: 4px;
  border: 1px solid #7e8993;
  color: rgba(14, 28, 46, 0.62);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 8px; }
  .wp-block-search__input:focus {
    outline: none; }

.wp-block-search__button {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 -1px 0 #ccc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 6px 10px; }

.block-editor-block-list__block[data-type="core/separator"] {
  padding-top: 0.1px;
  padding-bottom: 0.1px; }
  .block-editor-block-list__block[data-type="core/separator"].block-editor-block-list__block {
    margin-top: 0;
    margin-bottom: 0; }

.wp-block-shortcode {
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: rgba(139, 139, 150, 0.1);
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 28px; }
  .wp-block-shortcode label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 600;
    flex-shrink: 0; }
  .wp-block-shortcode .block-editor-plain-text {
    width: 80%;
    max-height: 250px; }
  .wp-block-shortcode .dashicon {
    margin-right: 8px; }

.wp-social-link {
  padding: 6px; }

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  padding-left: 16px;
  padding-right: 16px; }

.wp-block-social-links div.block-editor-url-input {
  display: inline-block;
  margin-left: 8px; }

.wp-block-social-links .block-editor-block-list__layout {
  display: flex;
  justify-content: flex-start; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block::before {
  border-right: none;
  border-top: none;
  border-bottom: none; }

[data-type="core/social-links"].is-selected .wp-block-social-links .block-editor-block-list__block::before,
[data-type="core/social-links"].has-child-selected .wp-block-social-links .block-editor-block-list__block::before {
  border-color: transparent !important; }

[data-type="core/social-links"] .wp-block-social-links .block-editor-block-list__insertion-point {
  display: none; }

.wp-block-social-links .block-list-appender {
  margin: 0;
  display: flex;
  align-items: center; }

[data-type="core/social-links"][data-align="center"] .wp-block-social-links {
  justify-content: center; }

.block-editor-block-preview__content .wp-social-link:disabled {
  opacity: 1; }

[data-type="core/social-links"]:not(.is-selected):not(.has-child-selected) .wp-block-social-links {
  min-height: 36px; }

[data-type="core/social-links"] .wp-social-link__is-incomplete {
  transition: transform 0.1s ease;
  transform-origin: center center; }

[data-type="core/social-links"]:not(.is-selected):not(.has-child-selected) .wp-social-link__is-incomplete {
  opacity: 0;
  transform: scale(0);
  width: 0;
  padding: 0;
  margin-right: 0; }

.wp-social-link.wp-social-link__is-incomplete {
  opacity: 0.5; }
  @media (prefers-reduced-motion: reduce) {
    .wp-social-link.wp-social-link__is-incomplete {
      transition-duration: 0s; } }

.wp-block-social-links .is-selected .wp-social-link__is-incomplete,
.wp-social-link.wp-social-link__is-incomplete:hover,
.wp-social-link.wp-social-link__is-incomplete:focus {
  opacity: 1; }

[data-type="core/social-links"] .wp-social-link:focus {
  opacity: 1;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #007cba;
  outline: 2px solid transparent; }

.block-library-spacer__resize-container.is-selected {
  background: #f3f4f5; }
  .is-dark-theme .block-library-spacer__resize-container.is-selected {
    background: rgba(255, 255, 255, 0.15); }

.block-library-spacer__resize-container {
  clear: both;
  margin-bottom: 28px; }
  .block-library-spacer__resize-container .components-resizable-box__handle::before {
    content: none; }

.edit-post-visual-editor p.wp-block-subhead {
  color: #6c7781;
  font-size: 1.1em;
  font-style: italic; }

.block-editor-block-list__block[data-type="core/table"][data-align="left"], .block-editor-block-list__block[data-type="core/table"][data-align="right"], .block-editor-block-list__block[data-type="core/table"][data-align="center"] {
  height: auto; }
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] table, .block-editor-block-list__block[data-type="core/table"][data-align="right"] table, .block-editor-block-list__block[data-type="core/table"][data-align="center"] table {
    width: auto; }
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] th, .block-editor-block-list__block[data-type="core/table"][data-align="right"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="right"] th, .block-editor-block-list__block[data-type="core/table"][data-align="center"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="center"] th {
    word-break: break-word; }

.block-editor-block-list__block[data-type="core/table"][data-align="center"] {
  text-align: initial; }
  .block-editor-block-list__block[data-type="core/table"][data-align="center"] table {
    margin: 0 auto; }

.wp-block-table {
  margin: 0; }
  .wp-block-table table {
    border-collapse: collapse; }
  .wp-block-table td,
  .wp-block-table th {
    border: 1px solid; }
  .wp-block-table td.is-selected,
  .wp-block-table th.is-selected {
    border-color: #00a0d2;
    box-shadow: inset 0 0 0 1px #00a0d2;
    border-style: double; }
  .wp-block-table figcaption {
    color: #555d66;
    font-size: 13px;
    text-align: center; }

.blocks-table__placeholder-form.blocks-table__placeholder-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .blocks-table__placeholder-form.blocks-table__placeholder-form > * {
    margin-bottom: 8px; }
  @media (min-width: 782px) {
    .blocks-table__placeholder-form.blocks-table__placeholder-form {
      flex-direction: row;
      align-items: flex-end; }
      .blocks-table__placeholder-form.blocks-table__placeholder-form > * {
        margin-bottom: 0; } }

.blocks-table__placeholder-input {
  width: 112px;
  margin-right: 8px;
  margin-bottom: 0; }
  .blocks-table__placeholder-input input {
    height: 36px; }
  .blocks-table__placeholder-input .components-base-control__field {
    margin-bottom: 0; }

.block-editor .wp-block-tag-cloud a {
  display: inline-block;
  margin-right: 5px; }

.block-editor .wp-block-tag-cloud span {
  display: inline-block;
  margin-left: 5px;
  color: #8f98a1;
  text-decoration: none; }

.wp-block-template-part__placeholder-input-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.wp-block-template-part__placeholder-input {
  margin: 5px; }

.wp-block-template-part__placeholder-preview {
  margin-bottom: 15px;
  width: 100%; }
  .wp-block-template-part__placeholder-preview .block-editor-block-preview__container {
    padding: 1px; }
  .wp-block-template-part__placeholder-preview .block-editor-block-preview__content {
    position: initial; }

.wp-block-template-part__placeholder-preview-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px; }

.wp-block-text-columns .block-editor-rich-text__editable:focus {
  outline: 1px solid #e2e4e7; }

pre.wp-block-verse {
  color: #191e23;
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  padding: 1em;
  overflow: auto; }

.block-editor-block-list__block[data-align="center"] {
  text-align: center; }

.editor-video-poster-control .components-button {
  display: block;
  margin-right: 8px; }

.editor-video-poster-control .components-button + .components-button {
  margin-top: 1em; }

/**
 * Import styles from internal editor components used by the blocks.
 */
.block-editor-block-list__layout .reusable-block-edit-panel {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #1e1e1e;
  outline: 1px solid transparent; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__info {
    margin-right: auto; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__label {
    margin-right: 8px;
    white-space: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
    flex: 1 1 100%; }
  .block-editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
    flex-shrink: 0; }
  @media (min-width: 960px) {
    .block-editor-block-list__layout .reusable-block-edit-panel {
      flex-wrap: nowrap; }
      .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
        margin: 0; }
      .block-editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
        margin: 0 0 0 8px; } }

.is-navigate-mode .is-selected .reusable-block-edit-panel {
  border-color: #007cba;
  border-style: solid;
  border-bottom: 0; }
  .is-dark-theme .is-navigate-mode .is-selected .reusable-block-edit-panel {
    border-color: #007cba; }

/**
 * Editor Normalization Styles
 *
 * These are only output in the editor, but styles here are NOT prefixed .editor-styles-wrapper.
 * This allows us to create normalization styles that are easily overridden by editor styles.
 */
.editor-styles-wrapper .block-editor-block-list__block {
  margin-top: 28px;
  margin-bottom: 28px; }

#end-resizable-editor-section {
  display: none; }
