/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Block Toolbar/Formatting Buttons
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
#start-resizable-editor-section {
  display: none; }

.block-editor-block-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px; }
  .block-editor-block-icon.has-colors svg {
    fill: currentColor; }
  .block-editor-block-icon svg {
    min-width: 20px;
    min-height: 20px;
    max-width: 24px;
    max-height: 24px; }

.block-editor-block-inspector .components-base-control {
  margin-bottom: 24px; }
  .block-editor-block-inspector .components-base-control:last-child {
    margin-bottom: 8px; }

.block-editor-block-inspector .components-panel__body {
  border: none;
  border-top: 1px solid #e2e4e7; }

.block-editor-block-inspector .block-editor-block-card {
  padding: 16px; }

.block-editor-block-inspector__no-blocks {
  display: block;
  font-size: 13px;
  background: #fff;
  padding: 32px 16px;
  text-align: center; }

.block-editor-block-list__layout .block-editor-block-list__block.is-dragging > * {
  background: #f8f9f9; }

.block-editor-block-list__layout .block-editor-block-list__block.is-dragging > * > * {
  visibility: hidden; }

.block-editor-block-list__layout .block-editor-block-list__block .reusable-block-edit-panel * {
  z-index: 1; }

/**
 * General Post Content Layout
 */
.block-editor-block-list__layout {
  padding-left: 14px;
  padding-right: 14px;
  position: relative; }
  @media (min-width: 600px) {
    .block-editor-block-list__layout {
      padding-left: 58px;
      padding-right: 58px; } }
  .block-editor-block-list__layout .block-editor-block-list__layout {
    padding-left: 0;
    padding-right: 0; }

/**
 * Notices & Block Selected/Hover Styles.
 */
.block-editor-block-list__layout .block-editor-block-list__block {
  position: relative;
  overflow-wrap: break-word;
  /**
	 * Notices
	 */
  /**
	 * Block Layout
	 */ }
  .block-editor-block-list__layout .block-editor-block-list__block .components-placeholder .components-with-notices-ui {
    margin: -10px 0 12px 0; }
  .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui {
    margin: 0 0 12px 0;
    width: 100%; }
    .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui .components-notice {
      margin-left: 0;
      margin-right: 0; }
      .block-editor-block-list__layout .block-editor-block-list__block .components-with-notices-ui .components-notice .components-notice__content {
        font-size: 13px; }
  .block-editor-block-list__layout .block-editor-block-list__block:not([contenteditable]):focus {
    outline: none; }
    .block-editor-block-list__layout .block-editor-block-list__block:not([contenteditable]):focus::after {
      position: absolute;
      z-index: 1;
      pointer-events: none;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 0 2px #007cba;
      border-radius: 2px; }
      .is-dark-theme .block-editor-block-list__layout .block-editor-block-list__block:not([contenteditable]):focus::after {
        box-shadow: 0 0 0 2px #fff; }
  .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected) {
    opacity: 0.5;
    transition: opacity 0.1s linear; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected) {
        transition-duration: 0s; } }
    .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected):not(.is-focused) .block-editor-block-list__block, .block-editor-block-list__layout .block-editor-block-list__block.is-focus-mode:not(.is-multi-selected).is-focused {
      opacity: 1; }
  .block-editor-block-list__layout .block-editor-block-list__block.is-drop-target::before {
    content: "";
    position: absolute;
    z-index: 0;
    pointer-events: none;
    transition: border-color 0.1s linear, border-style 0.1s linear, box-shadow 0.1s linear;
    right: 0;
    left: 0;
    top: -14px;
    border-radius: 2px;
    border-top: 4px solid #007cba; }

/**
 * Cross-Block Selection
 */
.is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected::after,
.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected::after {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected .is-block-content, .is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected::after,
.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected .is-block-content,
.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected::after {
  box-shadow: 0 0 0 2px #007cba;
  border-radius: 2px;
  outline: 2px solid transparent; }
  .is-dark-theme .is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected .is-block-content, .is-dark-theme .is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected::after, .is-dark-theme
  .block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected .is-block-content, .is-dark-theme
  .block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected::after {
    box-shadow: 0 0 0 2px #fff; }

.is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block.is-selected .components-placeholder ::selection,
.block-editor-block-list__layout .block-editor-block-list__block.is-multi-selected .components-placeholder ::selection {
  background: transparent; }

.block-editor-block-list__layout .block-editor-block-list__block.is-block-collapsed::after {
  content: none; }

/**
 * Block styles and alignments
 */
.block-editor-block-list__layout .block-editor-block-list__block.has-warning {
  min-height: 36px; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning > * {
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning .block-editor-warning {
  pointer-events: all; }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.4); }

.block-editor-block-list__layout .block-editor-block-list__block.has-warning.is-multi-selected::after {
  background-color: transparent; }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-inner-blocks > .block-editor-block-list__layout.has-overlay::after {
  display: none; }

.block-editor-block-list__layout .block-editor-block-list__block.is-reusable > .block-editor-inner-blocks > .block-editor-block-list__layout.has-overlay .block-editor-block-list__layout.has-overlay::after {
  display: block; }

.is-navigate-mode .block-editor-block-list__layout .block-editor-block-list__block {
  cursor: default; }

.block-editor-block-list__layout .block-editor-block-list__block .alignleft,
.block-editor-block-list__layout .block-editor-block-list__block .alignright {
  z-index: 21; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="left"], .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] {
  z-index: 21;
  width: 100%;
  height: 0; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-align="left"]::before, .block-editor-block-list__layout .block-editor-block-list__block[data-align="right"]::before {
    content: none; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="left"] > .is-block-content {
  /*!rtl:begin:ignore*/
  float: left;
  margin-right: 2em;
  /*!rtl:end:ignore*/ }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="right"] > .is-block-content {
  /*!rtl:begin:ignore*/
  float: right;
  margin-left: 2em;
  /*!rtl:end:ignore*/ }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"], .block-editor-block-list__layout .block-editor-block-list__block[data-align="wide"], .block-editor-block-list__layout .block-editor-block-list__block.alignfull, .block-editor-block-list__layout .block-editor-block-list__block.alignwide {
  clear: both; }

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"], .block-editor-block-list__layout .block-editor-block-list__block.alignfull {
  margin-left: -14px;
  margin-right: -14px; }
  @media (min-width: 600px) {
    .block-editor-block-list__layout .block-editor-block-list__block[data-align="full"], .block-editor-block-list__layout .block-editor-block-list__block.alignfull {
      margin-left: -58px;
      margin-right: -58px; } }

.block-editor-block-list__layout .block-editor-block-list__block[data-clear="true"] {
  float: none; }

.block-editor-block-list__layout .block-editor-block-list__block .block-editor-block-list__layout .block-editor-default-block-appender .block-editor-inserter {
  left: auto;
  right: 8px; }

/**
 * In-Canvas Inserter
 */
.block-editor-block-list .block-editor-inserter {
  margin: 8px;
  cursor: move;
  cursor: grab; }

.block-editor-block-list__insertion-point {
  position: relative;
  z-index: 6;
  margin-top: -14px; }

.block-editor-block-list__insertion-point-indicator {
  position: absolute;
  top: calc(50% - 1px);
  height: 2px;
  left: 0;
  right: 0;
  background: #0085ba; }

body.admin-color-sunrise .block-editor-block-list__insertion-point-indicator {
  background: #d1864a; }

body.admin-color-ocean .block-editor-block-list__insertion-point-indicator {
  background: #a3b9a2; }

body.admin-color-midnight .block-editor-block-list__insertion-point-indicator {
  background: #e14d43; }

body.admin-color-ectoplasm .block-editor-block-list__insertion-point-indicator {
  background: #a7b656; }

body.admin-color-coffee .block-editor-block-list__insertion-point-indicator {
  background: #c2a68c; }

body.admin-color-blue .block-editor-block-list__insertion-point-indicator {
  background: #82b4cb; }

body.admin-color-light .block-editor-block-list__insertion-point-indicator {
  background: #0085ba; }

.block-editor-block-list__insertion-point-inserter {
  display: none;
  justify-content: center;
  cursor: text; }
  @media (min-width: 480px) {
    .block-editor-block-list__insertion-point-inserter {
      display: flex; } }
  .block-editor-block-list__insertion-point-inserter.is-inserter-hidden .block-editor-inserter__toggle {
    opacity: 0;
    pointer-events: none; }

.block-editor-block-list__block-popover-inserter {
  position: absolute;
  top: -9999em;
  margin-bottom: 14px; }
  .block-editor-block-list__block-popover-inserter.is-visible {
    position: static; }

.block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle.components-button,
.block-editor-default-block-appender .block-editor-inserter__toggle.components-button,
.block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button,
.block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button {
  background: #1e1e1e;
  border-radius: 2px;
  color: #fff;
  min-width: 24px;
  height: 24px; }
  .block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle.components-button:hover,
  .block-editor-default-block-appender .block-editor-inserter__toggle.components-button:hover,
  .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button:hover,
  .block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button:hover {
    color: #fff; }

.block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button,
.block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button {
  animation: block-editor-inserter__toggle__fade-in-animation-delayed 1.2s ease;
  animation-fill-mode: forwards; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button,
    .block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button {
      animation-duration: 1ms; } }
  .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button:hover,
  .block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button:hover {
    animation: block-editor-inserter__toggle__fade-in-animation 0.2s ease;
    animation-fill-mode: forwards; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button:hover,
      .block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button:hover {
        animation-duration: 1ms; } }

@keyframes block-editor-inserter__toggle__fade-in-animation-delayed {
  0% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes block-editor-inserter__toggle__fade-in-animation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.block-editor-block-list__block > .block-editor-block-list__insertion-point {
  position: absolute;
  top: -16px;
  height: 28px;
  bottom: auto;
  left: 14px;
  right: 14px; }

.block-editor-block-list__block .block-editor-block-list__block-html-textarea {
  display: block;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: 14px;
  line-height: 150%;
  transition: padding 0.2s linear; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-list__block .block-editor-block-list__block-html-textarea {
      transition-duration: 0s; } }
  .block-editor-block-list__block .block-editor-block-list__block-html-textarea:focus {
    box-shadow: none; }

/**
 * Block Toolbar, top and contextual.
 */
.block-editor-block-contextual-toolbar-wrapper {
  padding-left: 48px; }

.edit-post-header-toolbar__block-toolbar .components-toolbar,
.block-editor-block-contextual-toolbar .components-toolbar {
  height: 48px;
  background: none; }

.edit-post-header-toolbar__block-toolbar .components-button,
.block-editor-block-contextual-toolbar .components-button {
  height: 48px; }

/**
 * Block Toolbar when contextual.
 */
.block-editor-block-contextual-toolbar {
  border: 1px solid #1e1e1e;
  border-radius: 2px;
  background-color: #fff; }
  .block-editor-block-contextual-toolbar .block-editor-block-toolbar .components-toolbar {
    border-right-color: #1e1e1e; }

/**
 * Block Label for Navigation/Selection Mode
 */
.block-editor-block-list__breadcrumb {
  display: block;
  z-index: 22; }
  .block-editor-block-list__breadcrumb .components-toolbar {
    display: flex;
    border: none;
    background: none; }
    .block-editor-block-list__breadcrumb .components-toolbar .components-button {
      font-size: 13px;
      height: 48px;
      padding: 12px 16px;
      border: 1px solid #1e1e1e;
      border-radius: 2px;
      background-color: #fff; }
      .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        border: none;
        box-shadow: inset 0 0 0 1px rgb(0, 124, 186), 0 0 0 1px rgb(0, 124, 186); }
      body.admin-color-sunrise .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(209, 134, 74), 0 0 0 1px rgb(209, 134, 74); }
      body.admin-color-ocean .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(163, 185, 162), 0 0 0 1px rgb(163, 185, 162); }
      body.admin-color-midnight .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(225, 77, 67), 0 0 0 1px rgb(225, 77, 67); }
      body.admin-color-ectoplasm .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(167, 182, 86), 0 0 0 1px rgb(167, 182, 86); }
      body.admin-color-coffee .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(194, 166, 140), 0 0 0 1px rgb(194, 166, 140); }
      body.admin-color-blue .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(217, 171, 89), 0 0 0 1px rgb(217, 171, 89); }
      body.admin-color-light .block-editor-block-list__breadcrumb .components-toolbar .components-button:focus {
        box-shadow: inset 0 0 0 1px rgb(0, 133, 186), 0 0 0 1px rgb(0, 133, 186); }

/**
 * Warnings.
 */
.block-editor-block-list__block .block-editor-warning {
  z-index: 5;
  position: relative; }
  .block-editor-block-list__block .block-editor-warning.block-editor-block-list__block-crash-warning {
    margin-bottom: auto; }

/**
 * Popovers.
 */
.block-editor-block-list__insertion-point-popover.is-without-arrow {
  z-index: 28; }
  .block-editor-block-list__insertion-point-popover.is-without-arrow .components-popover__content.components-popover__content {
    background: none;
    border: none;
    box-shadow: none;
    overflow-y: visible;
    margin-left: 0; }

.components-popover.block-editor-block-list__block-popover {
  z-index: 29; }
  .components-popover.block-editor-block-list__block-popover .components-popover__content {
    margin: 0 !important;
    min-width: auto;
    width: max-content;
    background: none;
    border: none;
    box-shadow: none;
    overflow-y: visible;
    pointer-events: none; }
    .components-popover.block-editor-block-list__block-popover .components-popover__content > * {
      pointer-events: all; }
    .components-popover.block-editor-block-list__block-popover .components-popover__content .block-editor-block-list__breadcrumb,
    .components-popover.block-editor-block-list__block-popover .components-popover__content .block-editor-block-contextual-toolbar {
      margin-bottom: 16px;
      margin-left: -49px; }
    .components-popover.block-editor-block-list__block-popover .components-popover__content .block-editor-block-contextual-toolbar[data-align="full"],
    .components-popover.block-editor-block-list__block-popover .components-popover__content .block-editor-block-list__breadcrumb[data-align="full"] {
      margin-left: 0; }
  .is-dragging-components-draggable .components-popover.block-editor-block-list__block-popover {
    opacity: 0; }

.is-dragging-components-draggable .components-tooltip {
  display: none; }

.block-editor-block-list__block .block-list-appender {
  margin: 8px 0; }
  .has-background .block-editor-block-list__block .block-list-appender {
    margin: 20px 8px; }

.block-list-appender.is-drop-target > div::before {
  content: "";
  position: absolute;
  right: -8px;
  left: -8px;
  top: -8px;
  bottom: -8px;
  border-radius: 2px;
  border: 3px solid #0085ba; }

body.admin-color-sunrise .block-list-appender.is-drop-target > div::before {
  border: 3px solid #d1864a; }

body.admin-color-ocean .block-list-appender.is-drop-target > div::before {
  border: 3px solid #a3b9a2; }

body.admin-color-midnight .block-list-appender.is-drop-target > div::before {
  border: 3px solid #e14d43; }

body.admin-color-ectoplasm .block-list-appender.is-drop-target > div::before {
  border: 3px solid #a7b656; }

body.admin-color-coffee .block-list-appender.is-drop-target > div::before {
  border: 3px solid #c2a68c; }

body.admin-color-blue .block-list-appender.is-drop-target > div::before {
  border: 3px solid #82b4cb; }

body.admin-color-light .block-list-appender.is-drop-target > div::before {
  border: 3px solid #0085ba; }

.block-list-appender > .block-editor-inserter {
  display: block; }

.block-editor-block-breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0; }
  .block-editor-block-breadcrumb li {
    display: inline-block;
    margin: 0; }
    .block-editor-block-breadcrumb li:not(:last-child)::after {
      content: "\2192"; }

.block-editor-block-breadcrumb__button.components-button {
  height: 24px;
  line-height: 24px;
  padding: 0; }
  .block-editor-block-breadcrumb__button.components-button:hover:not(:disabled) {
    text-decoration: underline;
    box-shadow: none; }
  .block-editor-block-breadcrumb__button.components-button:focus {
    color: #191e23;
    outline-offset: -1px;
    outline: 1px dotted #555d66;
    outline-offset: -2px;
    box-shadow: none; }

.block-editor-block-breadcrumb__current {
  cursor: default; }

.block-editor-block-breadcrumb__button.components-button,
.block-editor-block-breadcrumb__current {
  color: #1e1e1e;
  padding: 0 8px;
  font-size: inherit; }

.block-editor-block-card {
  display: flex;
  align-items: flex-start; }

.block-editor-block-card__icon {
  border: 1px solid #ccd0d4;
  padding: 7px;
  margin-right: 10px;
  height: 36px;
  width: 36px; }

.block-editor-block-card__content {
  flex-grow: 1; }

.block-editor-block-card__title {
  font-weight: 500;
  margin-bottom: 5px; }

.block-editor-block-card__description {
  font-size: 13px; }

.block-editor-block-card .block-editor-block-icon {
  margin-left: -2px;
  margin-right: 10px;
  padding: 0 3px;
  width: 36px;
  height: 24px; }

/**
 * Invalid block comparison
 */
.block-editor-block-compare {
  overflow: auto;
  height: auto; }
  @media (min-width: 600px) {
    .block-editor-block-compare {
      max-height: 70%; } }

.block-editor-block-compare__wrapper {
  display: flex;
  padding-bottom: 16px; }
  .block-editor-block-compare__wrapper > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    padding: 0 16px 0 0;
    min-width: 200px; }
    .block-editor-block-compare__wrapper > div button {
      float: right; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__converted {
    border-left: 1px solid #ddd;
    padding-left: 15px;
    padding-right: 0; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__html {
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 12px;
    color: #23282d;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    line-height: 1.7; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span {
      background-color: #e6ffed;
      padding-top: 3px;
      padding-bottom: 3px; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span.block-editor-block-compare__added {
      background-color: #acf2bd; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__html span.block-editor-block-compare__removed {
      background-color: #d94f4f; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__preview {
    padding: 0;
    padding-top: 14px; }
    .block-editor-block-compare__wrapper .block-editor-block-compare__preview p {
      font-size: 12px;
      margin-top: 0; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__action {
    margin-top: 14px; }
  .block-editor-block-compare__wrapper .block-editor-block-compare__heading {
    font-size: 1em;
    font-weight: 400;
    margin: 0.67em 0; }

.block-editor-block-mobile-toolbar {
  display: flex;
  flex-direction: row;
  border-right: 1px solid #e2e4e7; }
  .block-editor-block-mobile-toolbar .block-editor-block-mover__control {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    padding: 3px;
    margin: 0;
    justify-content: center;
    align-items: center; }
    .block-editor-block-mobile-toolbar .block-editor-block-mover__control .dashicon {
      margin: auto; }
  .block-editor-block-mobile-toolbar .block-editor-block-mover {
    display: flex;
    margin-right: auto; }
    .block-editor-block-mobile-toolbar .block-editor-block-mover .block-editor-block-mover__control {
      float: left; }

.block-editor-block-mover {
  display: inline-flex;
  flex-direction: row; }

.block-editor-block-mover__description {
  display: none; }

.block-editor-block-mover__control.has-icon {
  padding: 0; }

.block-editor-block-mover .components-toolbar {
  flex-direction: column; }

.block-editor-block-mover.is-horizontal .components-toolbar {
  flex-direction: row; }

.block-editor-block-mover .components-toolbar .block-editor-block-mover__control-up svg {
  margin-bottom: -8px; }

.block-editor-block-mover .components-toolbar .block-editor-block-mover__control-up::before {
  bottom: 0;
  height: calc(100% - 8px); }

.block-editor-block-mover .components-toolbar .block-editor-block-mover__control-down svg {
  margin-top: -8px; }

.block-editor-block-mover .components-toolbar .block-editor-block-mover__control-down::before {
  top: 0;
  height: calc(100% - 8px); }

.block-editor-block-mover.is-horizontal .block-editor-block-mover__control.has-icon {
  height: 48px;
  width: 24px !important;
  min-width: 24px;
  padding-left: 0;
  padding-right: 0; }
  .block-editor-block-mover.is-horizontal .block-editor-block-mover__control.has-icon::before {
    top: 8px;
    bottom: 8px;
    min-width: 0;
    width: auto;
    height: auto; }

.block-editor-block-mover.is-horizontal .block-editor-block-mover__control-up.has-icon svg {
  margin-left: 0;
  margin-right: -8px;
  margin-bottom: 0; }

.block-editor-block-mover.is-horizontal .block-editor-block-mover__control-up.has-icon::before {
  left: 8px;
  right: 0; }

.block-editor-block-mover.is-horizontal .block-editor-block-mover__control-down.has-icon svg {
  margin-left: -8px;
  margin-right: 0;
  margin-top: 0; }

.block-editor-block-mover.is-horizontal .block-editor-block-mover__control-down.has-icon::before {
  left: 0;
  right: 8px; }

.block-editor-block-mover:not([draggable="false"]) {
  cursor: grab; }
  .block-editor-block-mover:not([draggable="false"]) .block-editor-block-mover__control {
    cursor: grab !important; }
    .block-editor-block-mover:not([draggable="false"]) .block-editor-block-mover__control:not(:disabled):not([aria-disabled="true"]):active {
      cursor: grabbing; }

.block-editor-block-navigation__container {
  padding: 7px; }

.block-editor-block-navigation__label {
  margin: 0 0 8px;
  color: #6c7781; }

.block-editor-block-navigation__list,
.block-editor-block-navigation__paragraph {
  padding: 0;
  margin: 0; }

.block-editor-block-navigation__list .block-editor-button-block-appender {
  outline: none;
  background: none;
  padding: 8px;
  padding: 6px;
  margin-left: 0.8em;
  width: calc(100% - 0.8em); }

.block-editor-block-navigation__list .block-editor-block-navigation__list {
  margin-top: 2px;
  border-left: 2px solid #a2aab2;
  margin-left: 1em; }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__list {
    margin-left: 1.5em; }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item {
    position: relative; }
    .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item::before {
      position: absolute;
      left: 0;
      background: #a2aab2;
      width: 0.5em;
      height: 2px;
      content: "";
      top: calc(50% - 1px); }
  .block-editor-block-navigation__list .block-editor-block-navigation__list .block-editor-block-navigation__item-button {
    margin-left: 0.8em;
    width: calc(100% - 0.8em);
    height: auto; }
  .block-editor-block-navigation__list .block-editor-block-navigation__list > li:last-child {
    position: relative; }
    .block-editor-block-navigation__list .block-editor-block-navigation__list > li:last-child::after {
      position: absolute;
      content: "";
      background: #fff;
      top: 19px;
      bottom: 0;
      left: -2px;
      width: 2px; }

.block-editor-block-navigation__item-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px;
  text-align: left;
  color: #40464d;
  border-radius: 2px; }
  .block-editor-block-navigation__item-button .block-editor-block-icon {
    margin-right: 6px; }
  .block-editor-block-navigation__item-button.is-selected, .block-editor-block-navigation__item-button.is-selected:focus {
    color: #fff;
    background: #1e1e1e; }

.components-popover.block-editor-block-navigation__popover {
  z-index: 99998; }

.block-editor-patterns {
  padding: 16px; }

.block-editor-patterns__item {
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 16px;
  border: 1px solid #e2e4e7;
  transition: all 0.05s ease-in-out;
  position: relative; }
  .block-editor-patterns__item:hover {
    background: #fff;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #555d66; }
  .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #007cba;
    outline: 2px solid transparent; }
  body.admin-color-sunrise .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #d1864a; }
  body.admin-color-ocean .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #a3b9a2; }
  body.admin-color-midnight .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #e14d43; }
  body.admin-color-ectoplasm .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #a7b656; }
  body.admin-color-coffee .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #c2a68c; }
  body.admin-color-blue .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #d9ab59; }
  body.admin-color-light .block-editor-patterns__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #0085ba; }

.block-editor-patterns__item-preview {
  padding: 16px; }

.block-editor-patterns__item-title {
  text-align: center;
  padding: 10px 0; }

.block-editor-block-preview__container {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .block-editor-block-preview__container.is-ready {
    overflow: visible; }

.block-editor-block-preview__content {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  text-align: initial;
  margin: 0;
  overflow: visible;
  min-height: auto; }
  .block-editor-block-preview__container .block-editor-block-preview__content.is-centered .block-editor-block-list__layout,
  .block-editor-block-preview__container .block-editor-block-preview__content.is-centered .block-editor-block-list__block {
    padding: 0; }
  .block-editor-block-preview__content .block-editor-block-list__insertion-point,
  .block-editor-block-preview__content .block-editor-block-drop-zone,
  .block-editor-block-preview__content .reusable-block-indicator,
  .block-editor-block-preview__content .block-list-appender {
    display: none; }

.block-editor-block-settings-menu__popover .components-dropdown-menu__menu {
  padding: 0; }

.block-editor-block-styles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.block-editor-block-styles__item {
  width: calc(50% - 4px);
  margin: 4px 0;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  padding: 6px;
  padding-top: calc(50% * 0.75 - 4px * 1.5); }
  .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #007cba;
    outline: 2px solid transparent; }
  body.admin-color-sunrise .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #d1864a; }
  body.admin-color-ocean .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #a3b9a2; }
  body.admin-color-midnight .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #e14d43; }
  body.admin-color-ectoplasm .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #a7b656; }
  body.admin-color-coffee .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #c2a68c; }
  body.admin-color-blue .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #d9ab59; }
  body.admin-color-light .block-editor-block-styles__item:focus {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #0085ba; }
  .block-editor-block-styles__item:hover {
    border-color: #007cba;
    color: #007cba !important; }
  body.admin-color-sunrise .block-editor-block-styles__item:hover {
    border-color: #d1864a;
    color: #d1864a !important; }
  body.admin-color-ocean .block-editor-block-styles__item:hover {
    border-color: #a3b9a2;
    color: #a3b9a2 !important; }
  body.admin-color-midnight .block-editor-block-styles__item:hover {
    border-color: #e14d43;
    color: #e14d43 !important; }
  body.admin-color-ectoplasm .block-editor-block-styles__item:hover {
    border-color: #a7b656;
    color: #a7b656 !important; }
  body.admin-color-coffee .block-editor-block-styles__item:hover {
    border-color: #c2a68c;
    color: #c2a68c !important; }
  body.admin-color-blue .block-editor-block-styles__item:hover {
    border-color: #d9ab59;
    color: #d9ab59 !important; }
  body.admin-color-light .block-editor-block-styles__item:hover {
    border-color: #0085ba;
    color: #0085ba !important; }
    .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #007cba; }
    body.admin-color-sunrise .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #d1864a; }
    body.admin-color-ocean .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #a3b9a2; }
    body.admin-color-midnight .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #e14d43; }
    body.admin-color-ectoplasm .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #a7b656; }
    body.admin-color-coffee .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #c2a68c; }
    body.admin-color-blue .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #d9ab59; }
    body.admin-color-light .block-editor-block-styles__item:hover .block-editor-block-styles__item-preview {
      border-color: #0085ba; }
  .block-editor-block-styles__item.is-active .block-editor-block-styles__item-label {
    font-weight: bold; }
  .block-editor-block-styles__item.is-active .block-editor-block-styles__item-preview {
    border: 2px solid #1e1e1e; }

.block-editor-block-styles__item-preview {
  outline: 1px solid transparent;
  padding: 0;
  border: 1px solid rgba(30, 30, 30, 0.2);
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  background: #fff;
  padding-top: 75%;
  margin-top: -75%; }
  .block-editor-block-styles__item-preview .block-editor-block-preview__container {
    padding-top: 0;
    margin: 0;
    margin-top: -75%; }

.block-editor-block-styles__item-label {
  text-align: center;
  padding: 4px 2px; }

.block-editor-block-switcher {
  position: relative; }

.block-editor-block-switcher__toggle {
  position: relative; }
  .block-editor-block-switcher__toggle::after {
    display: none;
    content: "";
    position: absolute;
    bottom: 1px;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-right-color: currentColor;
    border-bottom-color: currentColor; }

.components-button.block-editor-block-switcher__toggle,
.components-button.block-editor-block-switcher__no-switcher-icon {
  margin: 0;
  display: block;
  height: 48px; }
  .components-button.block-editor-block-switcher__toggle .block-editor-block-icon,
  .components-button.block-editor-block-switcher__no-switcher-icon .block-editor-block-icon {
    margin: auto; }

.components-button.block-editor-block-switcher__no-switcher-icon {
  width: 48px; }
  .components-button.block-editor-block-switcher__no-switcher-icon .block-editor-blocks-icon {
    margin-right: auto;
    margin-left: auto; }

.components-button.block-editor-block-switcher__no-switcher-icon:disabled {
  opacity: 0.84; }
  .components-button.block-editor-block-switcher__no-switcher-icon:disabled .block-editor-block-icon.has-colors {
    color: #1e1e1e !important; }

.block-editor-block-toolbar .components-toolbar .components-button.block-editor-block-switcher__toggle.has-icon {
  padding: 0; }
  .block-editor-block-toolbar .components-toolbar .components-button.block-editor-block-switcher__toggle.has-icon .block-editor-block-icon {
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center; }
  .block-editor-block-toolbar .components-toolbar .components-button.block-editor-block-switcher__toggle.has-icon::before {
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px; }

.components-popover.block-editor-block-switcher__popover .components-popover__content {
  min-width: 300px;
  max-width: calc(340px * 2);
  display: flex;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  padding: 0; }
  .components-popover.block-editor-block-switcher__popover .components-popover__content .components-menu-group {
    padding: 16px 24px; }
  .components-popover.block-editor-block-switcher__popover .components-popover__content .components-menu-group + .components-menu-group {
    border-color: #ccc; }

.block-editor-block-switcher__popover .components-popover__content .block-editor-block-switcher__container {
  min-width: 300px;
  max-width: 340px;
  width: 50%; }

.block-editor-block-switcher__popover .components-popover__content .block-editor-block-switcher__label {
  margin-bottom: 8px;
  color: #757575; }

@media (min-width: 782px) {
  .block-editor-block-switcher__popover .components-popover__content {
    position: relative; }
    .block-editor-block-switcher__popover .components-popover__content .block-editor-block-switcher__preview {
      border-left: 1px solid #e2e4e7;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
      background: #fff;
      width: 300px;
      height: auto;
      position: -webkit-sticky;
      position: sticky;
      -ms-grid-row-align: stretch;
          align-self: stretch;
      top: 0;
      padding: 10px; } }

.block-editor-block-switcher__popover .components-popover__content .components-panel__body {
  border: 0;
  position: relative;
  z-index: 1; }

.block-editor-block-switcher__popover .components-popover__content .components-panel__body + .components-panel__body {
  border-top: 1px solid #e2e4e7; }

.block-editor-block-switcher__popover .block-editor-block-styles {
  margin: 0 -3px; }

.block-editor-block-switcher__popover .block-editor-block-types-list {
  margin: 12px 0 0 0; }

.block-editor-block-switcher__preview-title {
  margin-bottom: 10px;
  color: #6c7781; }

.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__toggle,
.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__no-switcher-icon {
  height: 48px; }

.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__no-switcher-icon {
  width: 48px; }

.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__toggle::after {
  display: block; }

.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__toggle .block-editor-block-icon,
.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__toggle .block-editor-block-switcher__transform {
  width: 48px;
  height: 48px; }

.block-editor-block-contextual-toolbar .components-button.block-editor-block-switcher__toggle .block-editor-block-switcher__transform {
  padding: 12px; }

.block-editor-block-types-list {
  list-style: none;
  padding: 4px;
  margin-left: -4px;
  margin-right: -4px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap; }

.block-editor-block-variation-picker .components-placeholder__instructions {
  margin-bottom: 0; }

.block-editor-block-variation-picker .components-placeholder__fieldset {
  flex-direction: column; }

.block-editor-block-variation-picker.has-many-variations .components-placeholder__fieldset {
  max-width: 90%; }

.block-editor-block-variation-picker__variations.block-editor-block-variation-picker__variations {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 16px 0;
  padding: 0;
  list-style: none; }
  .block-editor-block-variation-picker__variations.block-editor-block-variation-picker__variations > li {
    list-style: none;
    margin: 8px 8px 0 0;
    flex-shrink: 1;
    max-width: 100px; }
  .block-editor-block-variation-picker__variations.block-editor-block-variation-picker__variations .block-editor-block-variation-picker__variation {
    padding: 8px; }

.block-editor-block-variation-picker__variation {
  width: 100%; }
  .block-editor-block-variation-picker__variation.components-button.has-icon {
    justify-content: center;
    width: auto; }
    .block-editor-block-variation-picker__variation.components-button.has-icon.is-secondary {
      background-color: #fff; }
  .block-editor-block-variation-picker__variation.components-button {
    height: auto;
    padding: 0; }
  .block-editor-block-variation-picker__variation::before {
    content: "";
    padding-bottom: 100%; }
  .block-editor-block-variation-picker__variation:first-child {
    margin-left: 0; }
  .block-editor-block-variation-picker__variation:last-child {
    margin-right: 0; }

.block-editor-button-block-appender {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 100%;
  height: auto;
  color: #1e1e1e;
  box-shadow: inset 0 0 0 1px #1e1e1e; }
  .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #007cba; }
  body.admin-color-sunrise .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #d1864a; }
  body.admin-color-ocean .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #a3b9a2; }
  body.admin-color-midnight .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #e14d43; }
  body.admin-color-ectoplasm .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #a7b656; }
  body.admin-color-coffee .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #c2a68c; }
  body.admin-color-blue .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #d9ab59; }
  body.admin-color-light .block-editor-button-block-appender:hover {
    box-shadow: inset 0 0 0 1px #0085ba; }
  .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #007cba; }
  body.admin-color-sunrise .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #d1864a; }
  body.admin-color-ocean .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #a3b9a2; }
  body.admin-color-midnight .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #e14d43; }
  body.admin-color-ectoplasm .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #a7b656; }
  body.admin-color-coffee .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #c2a68c; }
  body.admin-color-blue .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #d9ab59; }
  body.admin-color-light .block-editor-button-block-appender:focus {
    box-shadow: inset 0 0 0 2px #0085ba; }
  .block-editor-button-block-appender:active {
    color: #000; }
  .block-editor-button-block-appender.block-list-appender__toggle {
    background: #1e1e1e;
    color: #fff;
    box-shadow: none;
    width: 24px;
    height: 24px;
    padding: 0;
    margin-left: 8px; }
    .block-editor-button-block-appender.block-list-appender__toggle:active {
      color: #fff; }

.block-editor-color-gradient-control__color-indicator {
  margin-bottom: 8px; }

.block-editor-color-gradient-control__button-tabs {
  display: block;
  margin-bottom: 8px; }

.block-editor-panel-color-gradient-settings .component-color-indicator {
  vertical-align: text-bottom; }

.block-editor-panel-color-gradient-settings__panel-title .component-color-indicator {
  display: inline-block; }

.block-editor-panel-color-gradient-settings.is-opened .block-editor-panel-color-gradient-settings__panel-title .component-color-indicator {
  display: none; }

.block-editor-contrast-checker > .components-notice {
  margin: 0; }

.block-editor-default-block-appender {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .block-editor-default-block-appender[data-root-client-id=""] .block-editor-default-block-appender__content:hover {
    outline: 1px solid transparent; }
  .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
    font-family: "Noto Serif", serif;
    font-size: 16px;
    border: none;
    background: none;
    box-shadow: none;
    display: block;
    cursor: text;
    width: 100%;
    outline: 1px solid transparent;
    transition: 0.2s outline;
    resize: none;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 0 50px 0 0;
    color: rgba(14, 28, 46, 0.62); }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
        transition-duration: 0s; } }
    .is-dark-theme .block-editor-default-block-appender textarea.block-editor-default-block-appender__content {
      color: rgba(255, 255, 255, 0.65); }
  .block-editor-default-block-appender .components-drop-zone__content-icon {
    display: none; }

.block-editor-default-block-appender__content {
  line-height: 1.8; }

.block-editor-block-list__empty-block-inserter.block-editor-block-list__empty-block-inserter,
.block-editor-default-block-appender .block-editor-inserter {
  position: absolute;
  top: 2px;
  height: 32px; }
  .block-editor-block-list__empty-block-inserter.block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle,
  .block-editor-default-block-appender .block-editor-inserter .block-editor-inserter__toggle {
    margin-right: 0; }

.block-editor-block-list__empty-block-inserter,
.block-editor-default-block-appender .block-editor-inserter {
  right: 8px; }
  @media (min-width: 600px) {
    .block-editor-block-list__empty-block-inserter,
    .block-editor-default-block-appender .block-editor-inserter {
      display: flex;
      height: 100%; } }
  .block-editor-block-list__empty-block-inserter:disabled,
  .block-editor-default-block-appender .block-editor-inserter:disabled {
    display: none; }

@media (min-width: 600px) {
  .block-editor-default-block-appender .block-editor-inserter {
    align-items: center; } }

.block-editor-link-control {
  position: relative;
  min-width: 360px; }
  .components-popover__content .block-editor-link-control {
    min-width: auto;
    width: 90vw;
    max-width: 360px; }

.block-editor-link-control__search-input-wrapper {
  position: relative; }

.block-editor-link-control .block-editor-link-control__search-input.block-editor-link-control__search-input input[type="text"] {
  width: calc(100% - 32px);
  display: block;
  padding: 11px 16px;
  padding-right: 36px;
  margin: 16px;
  position: relative;
  border: 1px solid #e2e4e7;
  border-radius: 4px;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .block-editor-link-control .block-editor-link-control__search-input.block-editor-link-control__search-input input[type="text"] {
      font-size: 13px; } }
  .block-editor-link-control .block-editor-link-control__search-input.block-editor-link-control__search-input input[type="text"]:focus {
    color: #191e23;
    border-color: #007cba;
    box-shadow: 0 0 0 1px #007cba;
    outline: 2px solid transparent; }

.block-editor-link-control__search-error {
  margin: -8px 16px 16px; }

.block-editor-link-control__search-actions {
  position: absolute;
  /*
	 * Actions must be positioned on top of URLInput, since the input will grow
	 * when suggestions are rendered.
	 *
	 * Compensate for:
	 *  - Input margin ($grid-unit-20)
	 *  - Border (1px)
	 *  - Vertically, for the difference in height between the input (40px) and
	 *    the icon buttons.
	 *  - Horizontally, pad to the minimum of: default input padding, or the
	 *    equivalent of the vertical padding.
	 */
  top: 19px;
  right: 19px; }

.block-editor-link-control__search-results-wrapper {
  position: relative;
  margin-top: -15px; }
  .block-editor-link-control__search-results-wrapper::before, .block-editor-link-control__search-results-wrapper::after {
    content: "";
    position: absolute;
    left: -1px;
    right: 16px;
    display: block;
    pointer-events: none;
    z-index: 100; }
  .block-editor-link-control__search-results-wrapper::before {
    height: 8px;
    top: 0;
    bottom: auto;
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%); }
  .block-editor-link-control__search-results-wrapper::after {
    height: 16px;
    bottom: 0;
    top: auto;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%); }

.block-editor-link-control__search-results-label {
  padding: 15px 30px 0 30px;
  display: block;
  font-size: 1.1em; }

.block-editor-link-control__search-results {
  margin: 0;
  padding: 8px 16px 16px;
  max-height: 200px;
  overflow-y: auto; }
  .block-editor-link-control__search-results.is-loading {
    opacity: 0.2; }

.block-editor-link-control__search-item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  background: #fff;
  width: 100%;
  border: none;
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px;
  height: auto; }
  .block-editor-link-control__search-item:hover, .block-editor-link-control__search-item:focus {
    background-color: #edeff0; }
  .block-editor-link-control__search-item.is-selected {
    background: #f3f4f5; }
    .block-editor-link-control__search-item.is-selected .block-editor-link-control__search-item-type {
      background: #fff; }
  .block-editor-link-control__search-item.is-current {
    background: transparent;
    border: 0;
    width: 100%;
    cursor: default;
    padding: 16px;
    padding-left: 24px; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-header {
    display: block;
    margin-right: 24px;
    overflow: hidden;
    white-space: nowrap; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-icon {
    margin-right: 1em;
    min-width: 24px; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-info,
  .block-editor-link-control__search-item .block-editor-link-control__search-item-title {
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-title mark {
    color: #191e23; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-title {
    display: block;
    margin-bottom: 0.2em;
    font-weight: 500; }
    .block-editor-link-control__search-item .block-editor-link-control__search-item-title mark {
      font-weight: 700;
      color: #000;
      background-color: transparent; }
    .block-editor-link-control__search-item .block-editor-link-control__search-item-title span {
      font-weight: normal; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-info {
    display: block;
    color: #6c7781;
    font-size: 0.9em;
    line-height: 1.3; }
  .block-editor-link-control__search-item .block-editor-link-control__search-item-type {
    display: block;
    padding: 3px 8px;
    margin-left: auto;
    font-size: 0.9em;
    background-color: #f3f4f5;
    border-radius: 2px; }

.block-editor-link-control__loading {
  margin: 16px;
  display: flex;
  align-items: center; }
  .block-editor-link-control__loading .components-spinner {
    margin-top: 0; }

.components-button + .block-editor-link-control__search-create {
  margin-top: 20px;
  overflow: visible;
  padding: 12px 15px; }
  .components-button + .block-editor-link-control__search-create::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    display: block;
    width: 100%;
    border-top: 1px solid #e2e4e7; }

.block-editor-link-control__search-results div[role="menu"] > .block-editor-link-control__search-item.block-editor-link-control__search-item {
  padding: 10px; }

.block-editor-link-control__settings {
  border-top: 1px solid #e2e4e7;
  margin: 0;
  padding: 16px 24px; }
  .block-editor-link-control__settings :last-child {
    margin-bottom: 0; }

.block-editor-link-control__setting {
  margin-bottom: 16px; }
  .block-editor-link-control__setting :last-child {
    margin-bottom: 0; }

.block-editor-link-control .block-editor-link-control__search-input .components-spinner {
  display: block; }
  .block-editor-link-control .block-editor-link-control__search-input .components-spinner.components-spinner {
    position: absolute;
    left: auto;
    bottom: auto;
    /*
		 * Position spinner to the left of the actions.
		 *
		 * Compensate for:
		 *  - Input margin ($grid-unit-20)
		 *  - Border (1px)
		 *  - Vertically, for the difference in height between the input (40px)
		 *    and the spinner.
		 *  - Horizontally, adjust for the width occupied by the icon buttons,
		 *    then artificially create spacing that mimics as if the spinner
		 *    were center-padded to the same width as an icon button.
		 */
    top: 28px;
    right: 62px; }

.block-editor-link-control__search-item-action {
  margin-left: auto;
  flex-shrink: 0; }

.block-editor-image-size-control {
  margin-bottom: 1em; }
  .block-editor-image-size-control .block-editor-image-size-control__row {
    display: flex;
    justify-content: space-between; }
    .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__width,
    .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__height {
      margin-bottom: 0.5em; }
      .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__width input,
      .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__height input {
        line-height: 1.25; }
    .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__width {
      margin-right: 5px; }
    .block-editor-image-size-control .block-editor-image-size-control__row .block-editor-image-size-control__height {
      margin-left: 5px; }

.block-editor-block-list__layout.has-overlay::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -14px;
  bottom: -14px;
  left: -14px;
  z-index: 60; }

[data-align="full"] .has-overlay::after {
  right: 0;
  left: 0; }

.block-editor-block-types-list__list-item {
  display: block;
  width: 33.33%;
  padding: 0;
  margin: 0 0 12px; }

.components-button.block-editor-block-types-list__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  color: #32373c;
  padding: 0 4px;
  align-items: stretch;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  word-break: break-word;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.05s ease-in-out;
  position: relative;
  height: auto; }
  @media (prefers-reduced-motion: reduce) {
    .components-button.block-editor-block-types-list__item {
      transition-duration: 0s; } }
  .components-button.block-editor-block-types-list__item:disabled {
    opacity: 0.6;
    cursor: default; }
  .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #007cba;
    color: #007cba !important; }
  body.admin-color-sunrise .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #d1864a;
    color: #d1864a !important; }
  body.admin-color-ocean .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #a3b9a2;
    color: #a3b9a2 !important; }
  body.admin-color-midnight .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #e14d43;
    color: #e14d43 !important; }
  body.admin-color-ectoplasm .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #a7b656;
    color: #a7b656 !important; }
  body.admin-color-coffee .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #c2a68c;
    color: #c2a68c !important; }
  body.admin-color-blue .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #d9ab59;
    color: #d9ab59 !important; }
  body.admin-color-light .components-button.block-editor-block-types-list__item:not(:disabled):hover {
    border-color: #0085ba;
    color: #0085ba !important; }
  .components-button.block-editor-block-types-list__item:not(:disabled).is-active {
    color: #fff;
    background: #1e1e1e;
    outline: 2px solid transparent;
    outline-offset: -2px; }

.block-editor-block-types-list__item-icon {
  padding: 12px 20px;
  border-radius: 4px;
  color: #1e1e1e;
  transition: all 0.05s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-types-list__item-icon {
      transition-duration: 0s; } }
  .block-editor-block-types-list__item-icon .block-editor-block-icon {
    margin-left: auto;
    margin-right: auto; }
  .block-editor-block-types-list__item-icon svg {
    transition: all 0.15s ease-out; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-block-types-list__item-icon svg {
        transition-duration: 0s; } }

.block-editor-block-types-list__item-title {
  padding: 4px 2px 8px; }

.modal-open .block-editor-media-replace-flow__options {
  display: none; }

.block-editor-media-flow__url-input {
  padding: 0 15px;
  max-width: 255px;
  padding-bottom: 10px; }
  .block-editor-media-flow__url-input input {
    max-width: 180px; }

.block-editor-media-replace-flow__link-viewer .components-external-link__icon {
  display: none; }

.block-editor-media-replace-flow__link-viewer .components-visually-hidden {
  position: initial; }

.block-editor-media-replace-flow__link-viewer .components-button {
  flex-shrink: 0; }

.block-editor-media-placeholder__url-input-container .block-editor-media-placeholder__button {
  margin-bottom: 0; }

.block-editor-media-placeholder__url-input-form {
  display: flex; }
  .block-editor-media-placeholder__url-input-form input[type="url"].block-editor-media-placeholder__url-input-field {
    width: 100%;
    flex-grow: 1;
    border: none;
    border-radius: 0;
    margin: 2px; }
    @media (min-width: 600px) {
      .block-editor-media-placeholder__url-input-form input[type="url"].block-editor-media-placeholder__url-input-field {
        width: 300px; } }

.block-editor-media-placeholder__url-input-submit-button {
  flex-shrink: 1; }

.block-editor-media-placeholder__button {
  margin-bottom: 0.5rem; }

.block-editor-media-placeholder__cancel-button.is-link {
  margin: 1em;
  display: block; }

.block-editor-media-placeholder.is-appender {
  min-height: 0; }
  .block-editor-media-placeholder.is-appender:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px #007cba; }
  body.admin-color-sunrise .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #d1864a; }
  body.admin-color-ocean .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #a3b9a2; }
  body.admin-color-midnight .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #e14d43; }
  body.admin-color-ectoplasm .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #a7b656; }
  body.admin-color-coffee .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #c2a68c; }
  body.admin-color-blue .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #d9ab59; }
  body.admin-color-light .block-editor-media-placeholder.is-appender:hover {
    box-shadow: 0 0 0 1px #0085ba; }

.block-editor-multi-selection-inspector__card {
  display: flex;
  align-items: flex-start;
  padding: 16px; }

.block-editor-multi-selection-inspector__card-content {
  flex-grow: 1; }

.block-editor-multi-selection-inspector__card-title {
  font-weight: 500;
  margin-bottom: 5px; }

.block-editor-multi-selection-inspector__card-description {
  font-size: 13px; }

.block-editor-multi-selection-inspector__card .block-editor-block-icon {
  margin-left: -2px;
  margin-right: 10px;
  padding: 0 3px;
  width: 36px;
  height: 24px; }

.block-editor .block-editor-plain-text {
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%; }

.block-editor-responsive-block-control {
  margin-bottom: 28px;
  border-bottom: 1px solid #d7dade;
  padding-bottom: 14px; }
  .block-editor-responsive-block-control:last-child {
    padding-bottom: 0;
    border-bottom: 0; }

.block-editor-responsive-block-control__title {
  margin: 0;
  margin-bottom: 0.6em;
  margin-left: -3px; }

.block-editor-responsive-block-control__label {
  font-weight: 600;
  margin-bottom: 0.6em;
  margin-left: -3px; }

.block-editor-responsive-block-control__inner {
  margin-left: -1px; }

.block-editor-responsive-block-control__toggle {
  margin-left: 1px; }

.block-editor-responsive-block-control .components-base-control__help {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }

.block-editor-format-toolbar .components-dropdown-menu__toggle {
  justify-content: center; }

.block-editor-rich-text__editable > p:first-child {
  margin-top: 0; }

.block-editor-rich-text__editable a {
  color: #007fac; }

.block-editor-rich-text__editable code {
  padding: 2px;
  border-radius: 2px;
  color: #23282d;
  background: #f3f4f5;
  font-family: Menlo, Consolas, monaco, monospace;
  font-size: inherit; }

.block-editor-rich-text__editable [data-rich-text-placeholder] {
  pointer-events: none; }

.block-editor-rich-text__editable [data-rich-text-placeholder]::after {
  content: attr(data-rich-text-placeholder);
  opacity: 0.62; }

.block-editor-rich-text__editable:focus {
  outline: none; }
  .block-editor-rich-text__editable:focus [data-rich-text-format-boundary] {
    border-radius: 2px; }
  .block-editor-rich-text__editable:focus:not(.keep-placeholder-on-focus) [data-rich-text-placeholder]::after {
    display: none; }

figcaption.block-editor-rich-text__editable [data-rich-text-placeholder]::before {
  opacity: 0.8; }

.components-popover.block-editor-rich-text__inline-format-toolbar {
  z-index: 99998; }
  .components-popover.block-editor-rich-text__inline-format-toolbar .components-popover__content {
    width: auto;
    min-width: auto;
    margin-bottom: 8px;
    box-shadow: none;
    border: 1px solid #1e1e1e;
    border-radius: 2px;
    background-color: #fff; }
  .components-popover.block-editor-rich-text__inline-format-toolbar .components-toolbar {
    border: none; }
  .components-popover.block-editor-rich-text__inline-format-toolbar .components-toolbar__control,
  .components-popover.block-editor-rich-text__inline-format-toolbar .components-dropdown-menu__toggle {
    min-width: 48px;
    min-height: 48px;
    padding-left: 12px;
    padding-right: 12px; }

.block-editor-skip-to-selected-block {
  position: absolute;
  top: -9999em; }
  .block-editor-skip-to-selected-block:focus {
    height: auto;
    width: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 23px 14px;
    background: #f1f1f1;
    color: #11a0d2;
    line-height: normal;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    text-decoration: none;
    outline: none;
    z-index: 100000; }
  body.admin-color-sunrise .block-editor-skip-to-selected-block:focus {
    color: #c8b03c; }
  body.admin-color-ocean .block-editor-skip-to-selected-block:focus {
    color: #a89d8a; }
  body.admin-color-midnight .block-editor-skip-to-selected-block:focus {
    color: #77a6b9; }
  body.admin-color-ectoplasm .block-editor-skip-to-selected-block:focus {
    color: #c77430; }
  body.admin-color-coffee .block-editor-skip-to-selected-block:focus {
    color: #9fa47b; }
  body.admin-color-blue .block-editor-skip-to-selected-block:focus {
    color: #d9ab59; }
  body.admin-color-light .block-editor-skip-to-selected-block:focus {
    color: #c75726; }

.block-editor-tool-selector__help {
  margin-top: 8px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  padding: 12px 20px;
  border-top: 1px solid #e2e4e7;
  color: #6c7781; }

.block-editor-block-list__block .block-editor-url-input,
.components-popover .block-editor-url-input,
.block-editor-url-input {
  flex-grow: 1;
  position: relative;
  padding: 1px; }
  .block-editor-block-list__block .block-editor-url-input input[type="text"],
  .components-popover .block-editor-url-input input[type="text"],
  .block-editor-url-input input[type="text"] {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px; }
    @media (min-width: 600px) {
      .block-editor-block-list__block .block-editor-url-input input[type="text"],
      .components-popover .block-editor-url-input input[type="text"],
      .block-editor-url-input input[type="text"] {
        width: 300px; } }
    @media (min-width: 600px) {
      .block-editor-block-list__block .block-editor-url-input input[type="text"],
      .components-popover .block-editor-url-input input[type="text"],
      .block-editor-url-input input[type="text"] {
        font-size: 13px; } }
    .block-editor-block-list__block .block-editor-url-input input[type="text"]::-ms-clear,
    .components-popover .block-editor-url-input input[type="text"]::-ms-clear,
    .block-editor-url-input input[type="text"]::-ms-clear {
      display: none; }
  .block-editor-block-list__block .block-editor-url-input.has-border input[type="text"],
  .components-popover .block-editor-url-input.has-border input[type="text"],
  .block-editor-url-input.has-border input[type="text"] {
    border: 1px solid #555d66;
    border-radius: 4px; }
  .block-editor-block-list__block .block-editor-url-input.is-full-width,
  .components-popover .block-editor-url-input.is-full-width,
  .block-editor-url-input.is-full-width {
    width: 100%; }
    .block-editor-block-list__block .block-editor-url-input.is-full-width input[type="text"],
    .components-popover .block-editor-url-input.is-full-width input[type="text"],
    .block-editor-url-input.is-full-width input[type="text"] {
      width: 100%; }
    .block-editor-block-list__block .block-editor-url-input.is-full-width__suggestions,
    .components-popover .block-editor-url-input.is-full-width__suggestions,
    .block-editor-url-input.is-full-width__suggestions {
      width: 100%; }
  .block-editor-block-list__block .block-editor-url-input .components-spinner,
  .components-popover .block-editor-url-input .components-spinner,
  .block-editor-url-input .components-spinner {
    position: absolute;
    right: 8px;
    bottom: 17px;
    margin: 0; }

.block-editor-url-input__suggestions {
  max-height: 200px;
  transition: all 0.15s ease-in-out;
  padding: 4px 0;
  width: 302px;
  overflow-y: auto; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-url-input__suggestions {
      transition-duration: 0s; } }

.block-editor-url-input__suggestions,
.block-editor-url-input .components-spinner {
  display: none; }
  @media (min-width: 600px) {
    .block-editor-url-input__suggestions,
    .block-editor-url-input .components-spinner {
      display: inherit; } }

.block-editor-url-input__suggestion {
  padding: 4px 8px;
  color: #6c7781;
  display: block;
  font-size: 13px;
  cursor: pointer;
  background: #fff;
  width: 100%;
  border: none;
  text-align: left;
  border: none;
  box-shadow: none; }
  .block-editor-url-input__suggestion:hover {
    background: #e2e4e7; }
  .block-editor-url-input__suggestion:focus, .block-editor-url-input__suggestion.is-selected {
    background: rgb(0, 113, 158);
    color: #fff;
    outline: none; }
  body.admin-color-sunrise .block-editor-url-input__suggestion:focus, body.admin-color-sunrise .block-editor-url-input__suggestion.is-selected {
    background: rgb(178, 114, 63); }
  body.admin-color-ocean .block-editor-url-input__suggestion:focus, body.admin-color-ocean .block-editor-url-input__suggestion.is-selected {
    background: rgb(139, 157, 138); }
  body.admin-color-midnight .block-editor-url-input__suggestion:focus, body.admin-color-midnight .block-editor-url-input__suggestion.is-selected {
    background: rgb(191, 65, 57); }
  body.admin-color-ectoplasm .block-editor-url-input__suggestion:focus, body.admin-color-ectoplasm .block-editor-url-input__suggestion.is-selected {
    background: rgb(142, 155, 73); }
  body.admin-color-coffee .block-editor-url-input__suggestion:focus, body.admin-color-coffee .block-editor-url-input__suggestion.is-selected {
    background: rgb(165, 141, 119); }
  body.admin-color-blue .block-editor-url-input__suggestion:focus, body.admin-color-blue .block-editor-url-input__suggestion.is-selected {
    background: rgb(111, 153, 173); }
  body.admin-color-light .block-editor-url-input__suggestion:focus, body.admin-color-light .block-editor-url-input__suggestion.is-selected {
    background: rgb(0, 113, 158); }

.components-toolbar > .block-editor-url-input__button {
  position: inherit; }

.block-editor-url-input__button .block-editor-url-input__back {
  margin-right: 4px;
  overflow: visible; }
  .block-editor-url-input__button .block-editor-url-input__back::after {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 24px;
    right: -1px;
    background: #e2e4e7; }

.block-editor-url-input__button-modal {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e4e7;
  background: #fff; }

.block-editor-url-input__button-modal-line {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  align-items: flex-start; }
  .block-editor-url-input__button-modal-line .components-button {
    flex-shrink: 0;
    width: 36px;
    height: 36px; }

.block-editor-url-popover__additional-controls {
  border-top: 1px solid #e2e4e7; }

.block-editor-url-popover__additional-controls > div[role="menu"] .components-button:not(:disabled):not([aria-disabled="true"]):not(.is-secondary) > svg {
  box-shadow: none; }

.block-editor-url-popover__additional-controls div[role="menu"] > .components-button {
  padding-left: 2px; }

.block-editor-url-popover__row {
  display: flex; }

.block-editor-url-popover__row > :not(.block-editor-url-popover__settings-toggle) {
  flex-grow: 1; }

.block-editor-url-popover .components-button.has-icon {
  padding: 3px; }
  .block-editor-url-popover .components-button.has-icon > svg {
    padding: 5px;
    border-radius: 4px;
    height: 30px;
    width: 30px; }
  .block-editor-url-popover .components-button.has-icon:not(:disabled):focus {
    box-shadow: none; }
    .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(0, 124, 186), inset 0 0 0 4px #fff;
      outline: 2px solid transparent; }
    body.admin-color-sunrise .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(209, 134, 74), inset 0 0 0 4px #fff; }
    body.admin-color-ocean .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(163, 185, 162), inset 0 0 0 4px #fff; }
    body.admin-color-midnight .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(225, 77, 67), inset 0 0 0 4px #fff; }
    body.admin-color-ectoplasm .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(167, 182, 86), inset 0 0 0 4px #fff; }
    body.admin-color-coffee .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(194, 166, 140), inset 0 0 0 4px #fff; }
    body.admin-color-blue .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(217, 171, 89), inset 0 0 0 4px #fff; }
    body.admin-color-light .block-editor-url-popover .components-button.has-icon:not(:disabled):focus > svg {
      box-shadow: inset 0 0 0 2px rgb(0, 133, 186), inset 0 0 0 4px #fff; }

.block-editor-url-popover__settings-toggle {
  flex-shrink: 0;
  border-radius: 0;
  border-left: 1px solid #e2e4e7;
  margin-left: 1px; }
  .block-editor-url-popover__settings-toggle[aria-expanded="true"] .dashicon {
    transform: rotate(180deg); }

.block-editor-url-popover__input-container .components-base-control:last-child,
.block-editor-url-popover__input-container .components-base-control:last-child .components-base-control__field {
  margin-bottom: 0; }

.block-editor-url-popover__settings {
  display: block;
  padding: 16px;
  border-top: 1px solid #e2e4e7; }

.block-editor-url-popover__link-editor,
.block-editor-url-popover__link-viewer {
  display: flex; }
  .block-editor-url-popover__link-editor .block-editor-url-input .components-base-control__field,
  .block-editor-url-popover__link-viewer .block-editor-url-input .components-base-control__field {
    margin-bottom: 0; }
  .block-editor-url-popover__link-editor .block-editor-url-input .components-spinner,
  .block-editor-url-popover__link-viewer .block-editor-url-input .components-spinner {
    bottom: 9px; }

.block-editor-url-popover__link-viewer-url {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-url-popover__link-viewer-url.has-invalid-link {
    color: #d94f4f; }

.block-editor-warning {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  padding: 6px 12px;
  border: 1px solid #1e1e1e;
  border-radius: 2px;
  background-color: #fff; }
  .block-editor-warning .block-editor-warning__message {
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px;
    margin: 1em 0; }
  .block-editor-warning p.block-editor-warning__message.block-editor-warning__message {
    min-height: auto; }
  .block-editor-warning .block-editor-warning__contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%; }
  .block-editor-warning .block-editor-warning__actions {
    display: flex; }
  .block-editor-warning .block-editor-warning__action {
    margin: 0 0 0 8px; }

.block-editor-warning__secondary {
  margin: auto 0 auto 8px; }

.block-editor-writing-flow {
  display: flex;
  flex-direction: column; }

.block-editor-writing-flow__click-redirect {
  cursor: text; }

.html-anchor-control .components-external-link {
  display: block;
  margin-top: 8px; }

#end-resizable-editor-section {
  display: none; }

/**
 * Block Toolbar
 */
.block-editor-block-toolbar {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  position: relative;
  transition: border-color 0.1s linear, box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-block-toolbar {
      transition-duration: 0s; } }
  @media (min-width: 600px) {
    .block-editor-block-toolbar {
      overflow: inherit; } }
  .block-editor-block-toolbar .components-toolbar {
    background: none;
    line-height: 0;
    margin-top: -1px;
    margin-bottom: -1px;
    border: 0;
    border-right: 1px solid #e2e4e7; }
  .block-editor-block-toolbar > :last-child,
  .block-editor-block-toolbar > :last-child .components-toolbar {
    border-right: none; }

.block-editor-block-toolbar .components-button,
.block-editor-format-toolbar .components-button {
  position: relative;
  padding-left: 16px;
  padding-right: 16px; }
  .block-editor-block-toolbar .components-button:focus:enabled,
  .block-editor-format-toolbar .components-button:focus:enabled {
    box-shadow: none;
    outline: none; }
  .block-editor-block-toolbar .components-button::before,
  .block-editor-format-toolbar .components-button::before {
    content: "";
    position: absolute;
    display: block;
    border-radius: 2px;
    height: 32px;
    min-width: 32px;
    left: 8px;
    right: 8px; }
  .block-editor-block-toolbar .components-button svg,
  .block-editor-format-toolbar .components-button svg {
    position: relative;
    margin-left: auto;
    margin-right: auto; }
  .block-editor-block-toolbar .components-button.is-pressed,
  .block-editor-format-toolbar .components-button.is-pressed {
    color: #fff; }
    .block-editor-block-toolbar .components-button.is-pressed::before,
    .block-editor-format-toolbar .components-button.is-pressed::before {
      background: #1e1e1e; }
  .block-editor-block-toolbar .components-button:focus::before,
  .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(0, 124, 186), inset 0 0 0 4px #fff;
    outline: 2px solid transparent; }
  body.admin-color-sunrise .block-editor-block-toolbar .components-button:focus::before, body.admin-color-sunrise .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(209, 134, 74), inset 0 0 0 4px #fff; }
  body.admin-color-ocean .block-editor-block-toolbar .components-button:focus::before, body.admin-color-ocean .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(163, 185, 162), inset 0 0 0 4px #fff; }
  body.admin-color-midnight .block-editor-block-toolbar .components-button:focus::before, body.admin-color-midnight .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(225, 77, 67), inset 0 0 0 4px #fff; }
  body.admin-color-ectoplasm .block-editor-block-toolbar .components-button:focus::before, body.admin-color-ectoplasm .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(167, 182, 86), inset 0 0 0 4px #fff; }
  body.admin-color-coffee .block-editor-block-toolbar .components-button:focus::before, body.admin-color-coffee .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(194, 166, 140), inset 0 0 0 4px #fff; }
  body.admin-color-blue .block-editor-block-toolbar .components-button:focus::before, body.admin-color-blue .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(217, 171, 89), inset 0 0 0 4px #fff; }
  body.admin-color-light .block-editor-block-toolbar .components-button:focus::before, body.admin-color-light .block-editor-format-toolbar .components-button:focus::before {
    box-shadow: inset 0 0 0 2px rgb(0, 133, 186), inset 0 0 0 4px #fff; }
  .block-editor-block-toolbar .components-button.has-icon,
  .block-editor-format-toolbar .components-button.has-icon {
    padding-left: 8px;
    padding-right: 8px;
    min-width: 48px;
    justify-content: center; }
  .block-editor-block-toolbar .components-button.components-tab-button,
  .block-editor-format-toolbar .components-button.components-tab-button {
    font-weight: 500; }
    .block-editor-block-toolbar .components-button.components-tab-button span,
    .block-editor-format-toolbar .components-button.components-tab-button span {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      position: relative; }

.block-editor-block-toolbar .components-toolbar div > .components-button.has-icon,
.block-editor-format-toolbar .components-toolbar div > .components-button.has-icon {
  min-width: 36px;
  padding-left: 6px;
  padding-right: 6px; }
  .block-editor-block-toolbar .components-toolbar div > .components-button.has-icon svg,
  .block-editor-format-toolbar .components-toolbar div > .components-button.has-icon svg {
    min-width: 24px; }
  .block-editor-block-toolbar .components-toolbar div > .components-button.has-icon::before,
  .block-editor-format-toolbar .components-toolbar div > .components-button.has-icon::before {
    left: 2px;
    right: 2px; }

.block-editor-block-toolbar .components-toolbar div:first-child .components-button,
.block-editor-format-toolbar .components-toolbar div:first-child .components-button {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 6px; }
  .block-editor-block-toolbar .components-toolbar div:first-child .components-button::before,
  .block-editor-format-toolbar .components-toolbar div:first-child .components-button::before {
    left: 8px;
    right: 2px; }

.block-editor-block-toolbar .components-toolbar div:last-child .components-button,
.block-editor-format-toolbar .components-toolbar div:last-child .components-button {
  min-width: 42px;
  padding-left: 6px;
  padding-right: 11px; }
  .block-editor-block-toolbar .components-toolbar div:last-child .components-button::before,
  .block-editor-format-toolbar .components-toolbar div:last-child .components-button::before {
    left: 2px;
    right: 8px; }

.block-editor-block-toolbar .components-toolbar div:first-child:last-child > .components-button,
.block-editor-format-toolbar .components-toolbar div:first-child:last-child > .components-button {
  min-width: 48px;
  padding-left: 12px;
  padding-right: 12px; }
  .block-editor-block-toolbar .components-toolbar div:first-child:last-child > .components-button::before,
  .block-editor-format-toolbar .components-toolbar div:first-child:last-child > .components-button::before {
    left: 8px;
    right: 8px; }

.block-editor-block-toolbar__slot {
  display: inline-block;
  line-height: 0; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .block-editor-block-toolbar__slot {
      display: inline-flex; } }

.block-editor-block-toolbar__mover-switcher-container {
  display: flex; }

.block-editor-block-toolbar__block-switcher-wrapper .block-editor-block-switcher {
  display: block; }

@media (min-width: 782px) {
  .block-editor-block-toolbar.has-responsive-movers .block-editor-block-toolbar__mover-trigger-container {
    bottom: -1px;
    left: -1px;
    position: absolute;
    top: -1px;
    transform: translateX(-48px);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: -1; } }

@media (min-width: 782px) {
  .block-editor-block-toolbar.has-responsive-movers .block-editor-block-toolbar__mover-trigger-wrapper:not(:empty) {
    background-color: #fff;
    border: 1px solid #000;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: none;
    height: 100%;
    transition: all 60ms linear; } }

@media (prefers-reduced-motion: reduce) {
  .block-editor-block-toolbar.has-responsive-movers .block-editor-block-toolbar__mover-trigger-wrapper:not(:empty) {
    transition-duration: 0s; } }

html.block-editor-editor-skeleton__html-container {
  position: fixed;
  width: 100%; }
  @media (min-width: 782px) {
    html.block-editor-editor-skeleton__html-container {
      position: initial;
      width: initial; } }

.block-editor-editor-skeleton {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0; }
  @media (min-width: 782px) {
    .block-editor-editor-skeleton {
      top: 32px; }
      .is-fullscreen-mode .block-editor-editor-skeleton {
        top: 0; } }

.block-editor-editor-skeleton {
  /* Set left position when auto-fold is not on the body element. */
  left: 0; }
  @media (min-width: 782px) {
    .block-editor-editor-skeleton {
      left: 160px; } }

.auto-fold .block-editor-editor-skeleton {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */ }
  @media (min-width: 782px) {
    .auto-fold .block-editor-editor-skeleton {
      left: 36px; } }
  @media (min-width: 961px) {
    .auto-fold .block-editor-editor-skeleton {
      left: 160px; } }

/* Sidebar manually collapsed. */
.folded .block-editor-editor-skeleton {
  left: 0; }
  @media (min-width: 782px) {
    .folded .block-editor-editor-skeleton {
      left: 36px; } }

/* Mobile menu opened. */
@media (max-width: 782px) {
  .auto-fold .wp-responsive-open .block-editor-editor-skeleton {
    left: 190px; } }

/* In small screens with responsive menu expanded there is small white space. */
@media (max-width: 600px) {
  .auto-fold .wp-responsive-open .block-editor-editor-skeleton {
    margin-left: -18px; } }

body.is-fullscreen-mode .block-editor-editor-skeleton {
  left: 0 !important; }

.block-editor-editor-skeleton__body {
  flex-grow: 1;
  display: flex;
  overflow: auto;
  overscroll-behavior-y: none; }

.block-editor-editor-skeleton__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto; }

.block-editor-editor-skeleton__sidebar {
  display: block;
  width: auto;
  flex-shrink: 0;
  position: absolute;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  color: #1e1e1e; }
  @media (min-width: 782px) {
    .block-editor-editor-skeleton__sidebar {
      overflow: auto;
      border-left: 1px solid #e2e4e7;
      position: relative !important;
      z-index: 90; } }

.block-editor-editor-skeleton__header {
  flex-shrink: 0;
  height: auto;
  border-bottom: 1px solid #e2e4e7;
  z-index: 30;
  color: #1e1e1e;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  @media (min-width: 600px) {
    .block-editor-editor-skeleton__header {
      position: initial;
      top: 0; } }

.block-editor-editor-skeleton__footer {
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid #e2e4e7;
  color: #1e1e1e;
  display: none; }
  @media (min-width: 782px) {
    .block-editor-editor-skeleton__footer {
      display: block; } }

.block-editor-editor-skeleton__publish {
  z-index: 100000;
  position: fixed !important;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  width: 280px;
  color: #1e1e1e; }
  .block-editor-editor-skeleton__publish:focus {
    top: auto;
    bottom: 0; }

.block-editor-inserter {
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  line-height: 1.4; }
  @media (min-width: 782px) {
    .block-editor-inserter {
      position: relative; } }

@media (min-width: 782px) {
  .block-editor-inserter__popover > .components-popover__content {
    overflow-y: visible;
    height: 100vh;
    padding: 0; } }

.block-editor-inserter__toggle.components-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  transition: color 0.2s ease; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-inserter__toggle.components-button {
      transition-duration: 0s; } }

.block-editor-inserter__menu {
  height: 100%; }

.block-editor-inserter__main-area {
  width: auto;
  display: flex;
  flex-direction: column;
  height: 100%; }
  @media (min-width: 782px) {
    .block-editor-inserter__main-area {
      width: 400px;
      position: relative; } }

.block-editor-inserter__inline-elements {
  margin-top: -1px; }

.block-editor-inserter__menu.is-bottom::after {
  border-bottom-color: #fff; }

.components-popover.block-editor-inserter__popover {
  z-index: 99998; }

.components-popover input[type="search"].block-editor-inserter__search {
  display: block;
  margin: 16px;
  padding: 11px 16px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  flex-shrink: 0;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .components-popover input[type="search"].block-editor-inserter__search {
      font-size: 13px; } }
  .components-popover input[type="search"].block-editor-inserter__search:focus {
    color: #191e23;
    border-color: #007cba;
    box-shadow: 0 0 0 1px #007cba;
    outline: 2px solid transparent; }

.block-editor-inserter__results {
  flex-grow: 1;
  overflow: auto;
  position: relative;
  z-index: 1;
  padding: 0 16px 16px 16px; }
  .block-editor-inserter__results:focus {
    outline: 1px dotted #555d66; }
  .block-editor-inserter__results [role="presentation"] + .components-panel__body {
    border-top: none; }

.block-editor-inserter__popover .block-editor-block-types-list {
  margin: 0 -8px; }

.block-editor-inserter__reusable-blocks-panel {
  position: relative;
  text-align: right; }

.block-editor-inserter__manage-reusable-blocks {
  margin: 16px 0 0 16px; }

.block-editor-inserter__no-results {
  font-style: italic;
  padding: 24px;
  text-align: center; }

.block-editor-inserter__child-blocks {
  padding: 0 16px; }

.block-editor-inserter__parent-block-header {
  display: flex;
  align-items: center; }
  .block-editor-inserter__parent-block-header h2 {
    font-size: 13px; }
  .block-editor-inserter__parent-block-header .block-editor-block-icon {
    margin-right: 8px; }

.block-editor-inserter__menu-help-panel {
  display: none;
  border: 1px solid #ccc;
  width: 300px;
  min-height: 350px;
  margin-right: 20px;
  padding: 20px;
  background: #fff; }
  @media (min-width: 782px) {
    .block-editor-inserter__menu-help-panel {
      position: absolute;
      top: 0;
      left: calc(100% + 20px);
      display: flex;
      flex-direction: column; } }
  .block-editor-inserter__menu-help-panel .block-editor-block-card {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e2e4e7;
    animation: edit-post__fade-in-animation 0.2s ease-out 0s;
    animation-fill-mode: forwards; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor-inserter__menu-help-panel .block-editor-block-card {
        animation-duration: 1ms; } }
  .block-editor-inserter__menu-help-panel .block-editor-inserter__preview {
    display: flex;
    flex-grow: 1;
    overflow-y: auto; }

.block-editor-inserter__menu-help-panel-no-block {
  display: flex;
  height: 100%;
  flex-direction: column;
  animation: edit-post__fade-in-animation 0.2s ease-out 0s;
  animation-fill-mode: forwards; }
  @media (prefers-reduced-motion: reduce) {
    .block-editor-inserter__menu-help-panel-no-block {
      animation-duration: 1ms; } }
  .block-editor-inserter__menu-help-panel-no-block .block-editor-inserter__menu-help-panel-no-block-text {
    flex-grow: 1; }
    .block-editor-inserter__menu-help-panel-no-block .block-editor-inserter__menu-help-panel-no-block-text h4 {
      font-size: 18px; }
  .block-editor-inserter__menu-help-panel-no-block .components-notice {
    margin: 0; }
  .block-editor-inserter__menu-help-panel-no-block h4 {
    margin-top: 0; }

.block-editor-inserter__menu-help-panel-hover-area {
  flex-grow: 1;
  margin-top: 20px;
  padding: 20px;
  border: 1px dotted #e2e4e7;
  display: flex;
  align-items: center;
  text-align: center; }

.block-editor-inserter__menu-help-panel-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px; }

.block-editor-inserter__preview-content {
  border: 1px solid #e2e4e7;
  border-radius: 4px;
  min-height: 150px;
  display: -ms-grid;
  display: grid;
  flex-grow: 1; }
  .block-editor-inserter__preview-content .block-editor-block-preview__container {
    margin-right: 0;
    margin-left: 0;
    padding: 10px; }

.block-editor-inserter__preview-content-missing {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #606a73;
  border: 1px solid #e2e4e7;
  border-radius: 4px;
  align-items: center; }

.block-editor-inserter__tips {
  padding: 16px;
  flex-shrink: 0; }
