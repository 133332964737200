/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Block Toolbar/Formatting Buttons
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
@media (min-width: 782px) {
  body.js.is-fullscreen-mode {
    margin-top: -46px;
    height: calc(100% + 46px); } }
  @media (min-width: 782px) and (min-width: 782px) {
    body.js.is-fullscreen-mode {
      margin-top: -32px;
      height: calc(100% + 32px); } }

@media (min-width: 782px) {
    body.js.is-fullscreen-mode #adminmenumain,
    body.js.is-fullscreen-mode #wpadminbar {
      display: none; }
    body.js.is-fullscreen-mode #wpcontent,
    body.js.is-fullscreen-mode #wpfooter {
      margin-left: 0; }
    body.js.is-fullscreen-mode .edit-post-header {
      transform: translateY(-100%);
      animation: edit-post-fullscreen-mode__slide-in-animation 0.1s forwards; } }
    @media (min-width: 782px) and (prefers-reduced-motion: reduce) {
      body.js.is-fullscreen-mode .edit-post-header {
        animation-duration: 1ms; } }

@keyframes edit-post-fullscreen-mode__slide-in-animation {
  100% {
    transform: translateY(0%); } }

.edit-post-header {
  height: 60px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100vw; }
  @media (min-width: 280px) {
    .edit-post-header {
      flex-wrap: nowrap; } }
  .edit-post-header > .edit-post-header__settings {
    order: 1; }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .edit-post-header > .edit-post-header__settings {
        order: initial; } }

.edit-post-header__toolbar {
  display: flex;
  flex-grow: 1;
  padding-left: 24px; }

.edit-post-header__settings {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 16px; }

/**
 * Buttons in the Toolbar
 */
.edit-post-header__toolbar .block-editor-inserter__toggle {
  min-width: 32px;
  height: 32px;
  margin-right: 8px; }

.edit-post-header__settings .components-button.is-pressed {
  color: #fff;
  background: #1e1e1e; }

.edit-post-header__settings .components-button.editor-post-save-draft,
.edit-post-header__settings .editor-post-saved-state,
.edit-post-header__settings .components-button.editor-post-switch-to-draft,
.edit-post-header__settings .components-button.editor-post-preview,
.edit-post-header__settings .components-button.editor-post-preview__dropdown,
.edit-post-header__settings .components-button.editor-post-publish-button,
.edit-post-header__settings .components-button.editor-post-publish-panel__toggle {
  padding: 0 6px;
  margin-right: 4px; }
  @media (min-width: 600px) {
    .edit-post-header__settings .components-button.editor-post-save-draft,
    .edit-post-header__settings .editor-post-saved-state,
    .edit-post-header__settings .components-button.editor-post-switch-to-draft,
    .edit-post-header__settings .components-button.editor-post-preview,
    .edit-post-header__settings .components-button.editor-post-preview__dropdown,
    .edit-post-header__settings .components-button.editor-post-publish-button,
    .edit-post-header__settings .components-button.editor-post-publish-panel__toggle {
      padding: 0 12px;
      margin-right: 12px; } }

.edit-post-fullscreen-mode-close.has-icon {
  display: none; }
  @media (min-width: 782px) {
    .edit-post-fullscreen-mode-close.has-icon {
      display: flex;
      align-items: center;
      align-self: stretch;
      border: none;
      background: #23282e;
      color: #fff;
      border-radius: 0;
      height: auto;
      width: 60px; }
      .edit-post-fullscreen-mode-close.has-icon:hover {
        background: #32373d; }
      .edit-post-fullscreen-mode-close.has-icon:active {
        color: #fff; }
      .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(0, 124, 186), inset 0 0 0 3px #fff; }
      body.admin-color-sunrise .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(209, 134, 74), inset 0 0 0 3px #fff; }
      body.admin-color-ocean .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(163, 185, 162), inset 0 0 0 3px #fff; }
      body.admin-color-midnight .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(225, 77, 67), inset 0 0 0 3px #fff; }
      body.admin-color-ectoplasm .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(167, 182, 86), inset 0 0 0 3px #fff; }
      body.admin-color-coffee .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(194, 166, 140), inset 0 0 0 3px #fff; }
      body.admin-color-blue .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(217, 171, 89), inset 0 0 0 3px #fff; }
      body.admin-color-light .edit-post-fullscreen-mode-close.has-icon:focus {
        box-shadow: inset 0 0 0 2px rgb(0, 133, 186), inset 0 0 0 3px #fff; } }

.edit-post-header-toolbar {
  display: inline-flex;
  align-items: center; }
  .edit-post-header-toolbar > .components-button {
    display: none; }
    @media (min-width: 600px) {
      .edit-post-header-toolbar > .components-button {
        display: inline-flex; } }
  .edit-post-header-toolbar .block-editor-block-navigation {
    display: none; }
    @media (min-width: 600px) {
      .edit-post-header-toolbar .block-editor-block-navigation {
        display: flex; } }

.edit-post-header-toolbar__block-toolbar {
  position: absolute;
  top: 61px;
  left: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid #e2e4e7; }
  .edit-post-header-toolbar__block-toolbar:empty {
    display: none; }
  .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar .components-toolbar {
    border-top: none;
    border-bottom: none; }
  .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
    display: none; }
  @media (min-width: 782px) {
    .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
      display: block;
      right: 280px; } }
  @media (min-width: 1280px) {
    .edit-post-header-toolbar__block-toolbar {
      padding-left: 8px;
      position: static;
      left: auto;
      right: auto;
      background: none;
      border-bottom: none; }
      .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
        right: auto; }
      .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar {
        border-left: 1px solid #e2e4e7; }
      .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar .components-toolbar {
        height: 60px;
        padding: 6px 0; } }

.edit-post-more-menu {
  margin-left: -4px; }
  .edit-post-more-menu .components-button {
    width: auto;
    padding: 0 2px; }
  @media (min-width: 600px) {
    .edit-post-more-menu {
      margin-left: 0; }
      .edit-post-more-menu .components-button {
        padding: 0 4px; } }

.edit-post-more-menu__content .components-popover__content {
  min-width: 260px; }
  @media (min-width: 480px) {
    .edit-post-more-menu__content .components-popover__content {
      width: auto;
      max-width: 480px; } }
  .edit-post-more-menu__content .components-popover__content .components-dropdown-menu__menu {
    padding: 0; }

.components-popover.edit-post-more-menu__content {
  z-index: 99998; }

.edit-post-pinned-plugins {
  display: none; }
  @media (min-width: 600px) {
    .edit-post-pinned-plugins {
      display: flex; } }
  .edit-post-pinned-plugins .components-button {
    margin-left: 4px; }
    .edit-post-pinned-plugins .components-button.is-pressed {
      margin-left: 5px; }
    .edit-post-pinned-plugins .components-button svg {
      max-width: 24px;
      max-height: 24px; }
  .edit-post-pinned-plugins .components-button:not(.is-pressed) svg,
  .edit-post-pinned-plugins .components-button:not(.is-pressed) svg * {
    stroke: #1e1e1e;
    fill: #1e1e1e;
    stroke-width: 0; }
  .edit-post-pinned-plugins .components-button.is-pressed svg,
  .edit-post-pinned-plugins .components-button.is-pressed svg *,
  .edit-post-pinned-plugins .components-button.is-pressed:hover svg,
  .edit-post-pinned-plugins .components-button.is-pressed:hover svg * {
    stroke: #fff !important;
    fill: #fff !important;
    stroke-width: 0; }
  .edit-post-pinned-plugins .components-button:hover svg,
  .edit-post-pinned-plugins .components-button:hover svg * {
    stroke: #007cba !important;
    fill: #007cba !important;
    stroke-width: 0; }

.edit-post-keyboard-shortcut-help-modal__section {
  margin: 0 0 2rem 0; }

.edit-post-keyboard-shortcut-help-modal__main-shortcuts .edit-post-keyboard-shortcut-help-modal__shortcut-list {
  margin-top: -25px; }

.edit-post-keyboard-shortcut-help-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600; }

.edit-post-keyboard-shortcut-help-modal__shortcut {
  display: flex;
  align-items: baseline;
  padding: 0.6rem 0;
  border-top: 1px solid #e2e4e7;
  margin-bottom: 0; }
  .edit-post-keyboard-shortcut-help-modal__shortcut:last-child {
    border-bottom: 1px solid #e2e4e7; }
  .edit-post-keyboard-shortcut-help-modal__shortcut:empty {
    display: none; }

.edit-post-keyboard-shortcut-help-modal__shortcut-term {
  font-weight: 600;
  margin: 0 0 0 1rem;
  text-align: right; }

.edit-post-keyboard-shortcut-help-modal__shortcut-description {
  flex: 1;
  margin: 0;
  flex-basis: auto; }

.edit-post-keyboard-shortcut-help-modal__shortcut-key-combination {
  display: block;
  background: none;
  margin: 0;
  padding: 0; }
  .edit-post-keyboard-shortcut-help-modal__shortcut-key-combination + .edit-post-keyboard-shortcut-help-modal__shortcut-key-combination {
    margin-top: 10px; }

.edit-post-keyboard-shortcut-help-modal__shortcut-key {
  padding: 0.25rem 0.5rem;
  border-radius: 8%;
  margin: 0 0.2rem 0 0.2rem; }
  .edit-post-keyboard-shortcut-help-modal__shortcut-key:last-child {
    margin: 0 0 0 0.2rem; }

.edit-post-layout__metaboxes {
  flex-shrink: 0; }

.edit-post-layout__metaboxes:not(:empty) {
  border-top: 1px solid #e2e4e7;
  padding: 10px 0 10px;
  clear: both; }
  .edit-post-layout__metaboxes:not(:empty) .edit-post-meta-boxes-area {
    margin: auto 20px; }

.edit-post-layout .components-editor-notices__snackbar {
  position: fixed;
  right: 0;
  bottom: 20px;
  padding-left: 16px;
  padding-right: 16px; }

.edit-post-layout .components-editor-notices__snackbar {
  /* Set left position when auto-fold is not on the body element. */
  left: 0; }
  @media (min-width: 782px) {
    .edit-post-layout .components-editor-notices__snackbar {
      left: 160px; } }

.auto-fold .edit-post-layout .components-editor-notices__snackbar {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */ }
  @media (min-width: 782px) {
    .auto-fold .edit-post-layout .components-editor-notices__snackbar {
      left: 36px; } }
  @media (min-width: 961px) {
    .auto-fold .edit-post-layout .components-editor-notices__snackbar {
      left: 160px; } }

/* Sidebar manually collapsed. */
.folded .edit-post-layout .components-editor-notices__snackbar {
  left: 0; }
  @media (min-width: 782px) {
    .folded .edit-post-layout .components-editor-notices__snackbar {
      left: 36px; } }

/* Mobile menu opened. */
@media (max-width: 782px) {
  .auto-fold .wp-responsive-open .edit-post-layout .components-editor-notices__snackbar {
    left: 190px; } }

/* In small screens with responsive menu expanded there is small white space. */
@media (max-width: 600px) {
  .auto-fold .wp-responsive-open .edit-post-layout .components-editor-notices__snackbar {
    margin-left: -18px; } }

body.is-fullscreen-mode .edit-post-layout .components-editor-notices__snackbar {
  left: 0 !important; }

.edit-post-layout .editor-post-publish-panel {
  position: fixed;
  z-index: 100001;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto; }
  @media (min-width: 782px) {
    .edit-post-layout .editor-post-publish-panel {
      z-index: 99998;
      top: 32px;
      left: auto;
      width: 280px;
      border-left: 1px solid #e2e4e7;
      transform: translateX(100%);
      animation: edit-post-post-publish-panel__slide-in-animation 0.1s forwards; } }
  @media (min-width: 782px) and (prefers-reduced-motion: reduce) {
    .edit-post-layout .editor-post-publish-panel {
      animation-duration: 1ms; } }
  @media (min-width: 782px) {
      body.is-fullscreen-mode .edit-post-layout .editor-post-publish-panel {
        top: 0; }
      .is-focusing-regions .edit-post-layout .editor-post-publish-panel {
        transform: translateX(0%); } }

@keyframes edit-post-post-publish-panel__slide-in-animation {
  100% {
    transform: translateX(0%); } }

.block-editor-editor-skeleton__sidebar > div {
  height: 100%; }

.edit-post-layout .editor-post-publish-panel__header-publish-button {
  justify-content: center; }

.edit-post-layout__toggle-publish-panel,
.edit-post-layout__toogle-sidebar-panel {
  z-index: 100000;
  position: fixed !important;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  width: 280px;
  background-color: #fff;
  border: 1px dotted #e2e4e7;
  height: auto !important;
  padding: 24px;
  display: flex;
  justify-content: center; }
  .block-editor-editor-skeleton__publish:focus .edit-post-layout__toggle-publish-panel,
  .block-editor-editor-skeleton__publish:focus-within .edit-post-layout__toggle-publish-panel,
  .block-editor-editor-skeleton__sidebar:focus .edit-post-layout__toggle-publish-panel,
  .block-editor-editor-skeleton__sidebar:focus-within .edit-post-layout__toggle-publish-panel, .block-editor-editor-skeleton__publish:focus
  .edit-post-layout__toogle-sidebar-panel,
  .block-editor-editor-skeleton__publish:focus-within
  .edit-post-layout__toogle-sidebar-panel,
  .block-editor-editor-skeleton__sidebar:focus
  .edit-post-layout__toogle-sidebar-panel,
  .block-editor-editor-skeleton__sidebar:focus-within
  .edit-post-layout__toogle-sidebar-panel {
    top: auto;
    bottom: 0; }

.edit-post-layout__footer {
  display: none;
  z-index: 30; }
  @media (min-width: 782px) {
    .edit-post-layout__footer {
      display: flex;
      background: #fff;
      height: 24px;
      align-items: center;
      font-size: 13px;
      padding: 0 18px; } }
  .edit-post-layout__footer .table-of-contents {
    margin: auto 0 0 auto; }
  .edit-post-layout__footer .table-of-contents .components-button {
    height: 24px;
    padding: 0; }
    .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(0, 124, 186);
      outline: 1px solid transparent; }
    body.admin-color-sunrise .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(209, 134, 74); }
    body.admin-color-ocean .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(163, 185, 162); }
    body.admin-color-midnight .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(225, 77, 67); }
    body.admin-color-ectoplasm .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(167, 182, 86); }
    body.admin-color-coffee .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(194, 166, 140); }
    body.admin-color-blue .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(217, 171, 89); }
    body.admin-color-light .edit-post-layout__footer .table-of-contents .components-button:focus {
      box-shadow: inset 0 0 0 2px rgb(0, 133, 186); }

.edit-post-layout .block-editor-editor-skeleton__content {
  background-color: #ccd0d4; }

@media (min-width: 600px) {
  .edit-post-manage-blocks-modal {
    height: calc(100% - 60px - 60px); } }

.edit-post-manage-blocks-modal .components-modal__content {
  padding-bottom: 0;
  display: flex;
  flex-direction: column; }

.edit-post-manage-blocks-modal .components-modal__header {
  flex-shrink: 0;
  margin-bottom: 0; }

.edit-post-manage-blocks-modal__content {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  min-height: 0; }

.edit-post-manage-blocks-modal__no-results {
  font-style: italic;
  padding: 24px 0;
  text-align: center; }

.edit-post-manage-blocks-modal__search {
  margin: 16px 0; }
  .edit-post-manage-blocks-modal__search .components-base-control__field {
    margin-bottom: 0; }
  .edit-post-manage-blocks-modal__search .components-base-control__label {
    margin-top: -4px; }
  .edit-post-manage-blocks-modal__search input[type="search"].components-text-control__input {
    padding: 12px;
    border-radius: 4px; }

.edit-post-manage-blocks-modal__disabled-blocks-count {
  border-top: 1px solid #e2e4e7;
  margin-left: -24px;
  margin-right: -24px;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #f3f4f5; }

.edit-post-manage-blocks-modal__category {
  margin: 0 0 2rem 0; }

.edit-post-manage-blocks-modal__category-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 16px 0;
  background-color: #fff;
  z-index: 1; }
  .edit-post-manage-blocks-modal__category-title .components-base-control__field {
    margin-bottom: 0; }
  .edit-post-manage-blocks-modal__category-title .components-checkbox-control__label {
    font-size: 0.9rem;
    font-weight: 600; }

.edit-post-manage-blocks-modal__show-all {
  margin-right: 8px; }

.edit-post-manage-blocks-modal__checklist {
  margin-top: 0; }

.edit-post-manage-blocks-modal__checklist-item {
  margin-bottom: 0;
  padding-left: 16px;
  border-top: 1px solid #e2e4e7; }
  .edit-post-manage-blocks-modal__checklist-item:last-child {
    border-bottom: 1px solid #e2e4e7; }
  .edit-post-manage-blocks-modal__checklist-item .components-base-control__field {
    align-items: center;
    display: flex;
    margin: 0; }
  .components-modal__content .edit-post-manage-blocks-modal__checklist-item.components-checkbox-control__input-container {
    margin: 0 8px; }
  .edit-post-manage-blocks-modal__checklist-item .components-checkbox-control__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0.6rem 0 0.6rem 10px; }
  .edit-post-manage-blocks-modal__checklist-item .block-editor-block-icon {
    margin-right: 10px;
    fill: #555d66; }

.edit-post-manage-blocks-modal__results {
  height: 100%;
  overflow: auto;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  border-top: 1px solid #e2e4e7; }

.edit-post-meta-boxes-area {
  position: relative;
  /**
	 * The wordpress default for most meta-box elements is content-box. Some
	 * elements such as textarea and input are set to border-box in forms.css.
	 * These elements therefore specifically set back to border-box here, while
	 * other elements (such as .button) are unaffected by Gutenberg's style
	 * because of their higher specificity.
	 */
  /* Match width and positioning of the meta boxes. Override default styles. */
  /* Override Default meta box stylings */ }
  .edit-post-meta-boxes-area__container,
  .edit-post-meta-boxes-area .inside {
    box-sizing: content-box; }
  .edit-post-meta-boxes-area textarea,
  .edit-post-meta-boxes-area input {
    box-sizing: border-box; }
  .edit-post-meta-boxes-area #poststuff {
    margin: 0 auto;
    padding-top: 0;
    min-width: auto; }
  .edit-post-meta-boxes-area #poststuff h3.hndle,
  .edit-post-meta-boxes-area #poststuff .stuffbox > h3,
  .edit-post-meta-boxes-area #poststuff h2.hndle {
    /* WordPress selectors yolo */
    border-bottom: 1px solid #e2e4e7;
    box-sizing: border-box;
    color: inherit;
    font-weight: 600;
    outline: none;
    padding: 15px;
    position: relative;
    width: 100%; }
  .edit-post-meta-boxes-area .postbox {
    border: 0;
    color: inherit;
    margin-bottom: 0; }
  .edit-post-meta-boxes-area .postbox > .inside {
    border-bottom: 1px solid #e2e4e7;
    color: inherit;
    padding: 0 14px 14px;
    margin: 0; }
  .edit-post-meta-boxes-area .postbox .handlediv {
    height: 44px;
    width: 44px; }
  .edit-post-meta-boxes-area.is-loading::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: transparent;
    z-index: 1; }
  .edit-post-meta-boxes-area .components-spinner {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 5; }
  .edit-post-meta-boxes-area .is-hidden {
    display: none; }
  .edit-post-meta-boxes-area .metabox-location-side .postbox input[type="checkbox"] {
    border: 1px solid #6c7781; }
    .edit-post-meta-boxes-area .metabox-location-side .postbox input[type="checkbox"]:checked {
      background: #fff;
      border-color: #6c7781; }
    .edit-post-meta-boxes-area .metabox-location-side .postbox input[type="checkbox"]::before {
      margin: -3px -4px; }

.edit-post-meta-boxes-area__clear {
  clear: both; }

.editor-post-preview__dropdown {
  display: none;
  margin-right: 12px;
  box-shadow: inset 0 0 0 1px #ccc;
  border-radius: 2px;
  padding: 0; }

.editor-post-preview__button-toggle {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 12px; }
  .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(0, 124, 186); }
  body.admin-color-sunrise .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(209, 134, 74); }
  body.admin-color-ocean .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(163, 185, 162); }
  body.admin-color-midnight .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(225, 77, 67); }
  body.admin-color-ectoplasm .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(167, 182, 86); }
  body.admin-color-coffee .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(194, 166, 140); }
  body.admin-color-blue .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(217, 171, 89); }
  body.admin-color-light .editor-post-preview__button-toggle:focus:not(:disabled) {
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px rgb(0, 133, 186); }

.editor-post-preview__button-resize.editor-post-preview__button-resize {
  padding-left: 40px; }
  .editor-post-preview__button-resize.editor-post-preview__button-resize.has-icon {
    padding-left: 8px; }

.editor-post-preview__button-separator {
  border-left: 1px solid #ccc;
  padding: 6px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center; }

.editor-post-preview__dropdown-content .components-popover__content {
  overflow-y: visible;
  padding-bottom: 0; }

.editor-post-preview__dropdown-content .components-menu-group + .components-menu-group {
  border-top: 1px solid #ccc;
  padding: 8px 12px;
  margin-left: -12px;
  margin-right: -12px; }

.editor-post-preview__grouping-external {
  display: flex;
  position: relative; }

.editor-post-preview__button-external {
  padding-left: 40px;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: space-between; }

@media (min-width: 600px) {
  .editor-post-preview {
    display: none; }
  .editor-post-preview__dropdown {
    display: flex; } }

.edit-post-sidebar {
  background: #fff;
  color: #555d66;
  overflow: visible; }
  @media (min-width: 600px) {
    .edit-post-sidebar {
      z-index: auto;
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (min-width: 782px) {
    .edit-post-sidebar {
      width: 280px; } }
  .edit-post-sidebar > .components-panel {
    border-left: none;
    border-right: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: auto;
    max-height: calc(100vh - 146px);
    margin-top: -1px;
    margin-bottom: -1px;
    position: relative; }
    @media (min-width: 600px) {
      .edit-post-sidebar > .components-panel {
        overflow: visible;
        height: auto;
        max-height: none; } }
  .edit-post-sidebar > .components-panel .components-panel__header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 50px; }
    @media (min-width: 600px) {
      .edit-post-sidebar > .components-panel .components-panel__header {
        position: inherit;
        top: auto;
        left: auto;
        right: auto; } }
  .edit-post-sidebar p {
    margin-top: 0; }
  .edit-post-sidebar h2,
  .edit-post-sidebar h3 {
    font-size: 13px;
    color: #555d66;
    margin-bottom: 1.5em; }
  .edit-post-sidebar hr {
    border-top: none;
    border-bottom: 1px solid #e2e4e7;
    margin: 1.5em 0; }
  .edit-post-sidebar div.components-toolbar {
    box-shadow: none;
    margin-bottom: 1.5em; }
    .edit-post-sidebar div.components-toolbar:last-child {
      margin-bottom: 0; }
  .edit-post-sidebar p + div.components-toolbar {
    margin-top: -1em; }
  .edit-post-sidebar .block-editor-skip-to-selected-block:focus {
    top: auto;
    right: 10px;
    bottom: 10px;
    left: auto; }

/* Text Editor specific */
.components-panel__header.edit-post-sidebar__header {
  background: #fff;
  padding-right: 8px; }
  .components-panel__header.edit-post-sidebar__header .edit-post-sidebar__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  @media (min-width: 782px) {
    .components-panel__header.edit-post-sidebar__header {
      display: none; } }

.components-panel__header.edit-post-sidebar__panel-tabs {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 4px;
  border-top: 0;
  margin-top: 0; }
  .components-panel__header.edit-post-sidebar__panel-tabs .components-button.has-icon {
    display: none;
    margin-left: auto; }
    @media (min-width: 782px) {
      .components-panel__header.edit-post-sidebar__panel-tabs .components-button.has-icon {
        display: flex; } }

.edit-post-sidebar__panel-tab {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  height: 50px;
  padding: 3px 15px;
  margin-left: 0;
  font-weight: 400;
  outline-offset: -1px;
  transition: box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .edit-post-sidebar__panel-tab {
      transition-duration: 0s; } }
  .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba;
    font-weight: 600;
    position: relative; }
  body.admin-color-sunrise .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #837425; }
  body.admin-color-ocean .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #5e7d5e; }
  body.admin-color-midnight .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #497b8d; }
  body.admin-color-ectoplasm .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #523f6d; }
  body.admin-color-coffee .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #59524c; }
  body.admin-color-blue .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #417e9B; }
  body.admin-color-light .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba; }
    .edit-post-sidebar__panel-tab.is-active::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 1px;
      right: 0;
      left: 0;
      border-bottom: 3px solid transparent; }
  .edit-post-sidebar__panel-tab:focus:not(:disabled) {
    color: #191e23;
    outline-offset: -1px;
    outline: 1px dotted #555d66;
    box-shadow: none; }

.components-panel__body.is-opened.edit-post-last-revision__panel {
  padding: 0; }

.editor-post-last-revision__title.components-button {
  padding: 13px 16px; }

.editor-post-author__select {
  margin: -5px 0;
  width: 100%; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .editor-post-author__select {
      width: auto; } }

.edit-post-post-link__link-post-name {
  font-weight: 600; }

.edit-post-post-link__preview-label {
  margin: 0; }

.edit-post-post-link__link {
  text-align: left;
  word-wrap: break-word;
  display: block; }

/* rtl:begin:ignore */
.edit-post-post-link__preview-link-container {
  direction: ltr; }

/* rtl:end:ignore */
.edit-post-post-schedule {
  width: 100%;
  position: relative; }

.components-button.edit-post-post-schedule__toggle {
  text-align: right; }

.editor-post-slug__input {
  margin: -5px 0;
  padding: 2px; }

.edit-post-post-status .edit-post-post-publish-dropdown__switch-to-draft {
  margin-top: 15px;
  width: 100%;
  text-align: center; }

.edit-post-post-visibility {
  width: 100%; }

@media (min-width: 782px) {
  .edit-post-post-visibility__dialog .components-popover__content {
    width: 257px; } }

.edit-post-post-visibility__dialog-legend {
  font-weight: 600; }

.edit-post-post-visibility__choice {
  margin: 10px 0; }

.edit-post-post-visibility__dialog-radio,
.edit-post-post-visibility__dialog-label {
  vertical-align: top; }

.edit-post-post-visibility__dialog-password-input {
  width: calc(100% - 20px);
  margin-left: 20px; }

.edit-post-post-visibility__dialog-info {
  color: #7e8993;
  padding-left: 20px;
  font-style: italic;
  margin: 4px 0 0;
  line-height: 1.4; }

.components-panel__header.edit-post-sidebar__panel-tabs {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 4px;
  border-top: 0; }
  .components-panel__header.edit-post-sidebar__panel-tabs ul {
    display: flex; }
  .components-panel__header.edit-post-sidebar__panel-tabs li {
    margin: 0; }

.components-button.edit-post-sidebar__panel-tab {
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  padding: 3px 15px;
  margin-left: 0;
  font-weight: 400;
  color: #191e23;
  outline-offset: -1px;
  transition: box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .components-button.edit-post-sidebar__panel-tab {
      transition-duration: 0s; } }
  .components-button.edit-post-sidebar__panel-tab::after {
    content: attr(data-label);
    display: block;
    font-weight: 600;
    height: 0;
    overflow: hidden;
    speak: none;
    visibility: hidden; }
  .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #007cba;
    font-weight: 600;
    position: relative; }
  body.admin-color-sunrise .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #837425; }
  body.admin-color-ocean .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #5e7d5e; }
  body.admin-color-midnight .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #497b8d; }
  body.admin-color-ectoplasm .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #523f6d; }
  body.admin-color-coffee .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #59524c; }
  body.admin-color-blue .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #417e9B; }
  body.admin-color-light .components-button.edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -4px #007cba; }
    .components-button.edit-post-sidebar__panel-tab.is-active::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 1px;
      right: 0;
      left: 0;
      border-bottom: 4px solid transparent; }
  .components-button.edit-post-sidebar__panel-tab:focus {
    background-color: transparent;
    color: #191e23;
    outline-offset: -1px;
    outline: 1px dotted #555d66; }

/* Text Editor specific */
.components-panel__header.edit-post-sidebar-header__small {
  background: #fff;
  padding-right: 4px; }
  .components-panel__header.edit-post-sidebar-header__small .edit-post-sidebar__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  @media (min-width: 782px) {
    .components-panel__header.edit-post-sidebar-header__small {
      display: none; } }

.components-panel__header.edit-post-sidebar-header {
  padding-right: 4px;
  background: #f3f4f5; }
  .components-panel__header.edit-post-sidebar-header .components-button.has-icon {
    display: none;
    margin-left: auto; }
    .components-panel__header.edit-post-sidebar-header .components-button.has-icon ~ .components-button {
      margin-left: 0; }
    @media (min-width: 782px) {
      .components-panel__header.edit-post-sidebar-header .components-button.has-icon {
        display: flex; } }

.edit-post-text-editor {
  position: relative;
  width: 100%;
  background-color: #fff;
  flex-grow: 1;
  padding-top: 56px; }
  .edit-post-text-editor .editor-post-title__block textarea {
    border: 1px solid #e2e4e7;
    margin-bottom: 4px;
    padding: 14px; }
    .edit-post-text-editor .editor-post-title__block textarea:focus, .edit-post-text-editor .editor-post-title__block textarea:hover {
      border: 1px solid #000; }
  .edit-post-text-editor .editor-post-permalink {
    margin-top: -6px;
    box-shadow: none;
    border: none;
    outline: 1px solid #1e1e1e; }
  @media (min-width: 600px) {
    .edit-post-text-editor .editor-post-title,
    .edit-post-text-editor .editor-post-title__block {
      padding: 0; } }

.edit-post-text-editor__toolbar {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  height: 48px;
  line-height: 48px;
  padding: 0 8px 0 16px;
  display: flex; }
  .edit-post-text-editor__toolbar h2 {
    margin: 0 auto 0 0;
    font-size: 13px;
    color: #555d66; }
  .edit-post-text-editor__toolbar .components-button svg {
    order: 1; }

.edit-post-text-editor__body {
  max-width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 40px; }
  @media (min-width: 600px) {
    .edit-post-text-editor__body {
      max-width: 580px;
      margin-left: auto;
      margin-right: auto; } }

.edit-post-visual-editor {
  position: relative;
  padding-top: 50px;
  background-color: #fff;
  flex: 1 1 auto; }
  .edit-post-visual-editor .components-button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .edit-post-visual-editor {
      flex-basis: 100%; } }

.edit-post-visual-editor > .block-editor__typewriter,
.edit-post-visual-editor > .block-editor__typewriter > div,
.edit-post-visual-editor > .block-editor__typewriter > div > .block-editor-writing-flow,
.edit-post-visual-editor > .block-editor__typewriter > div > .block-editor-writing-flow > .block-editor-writing-flow__click-redirect {
  height: 100%; }

.edit-post-visual-editor .block-editor-writing-flow__click-redirect {
  min-height: 40vh;
  width: 100%; }

.has-metaboxes .edit-post-visual-editor .block-editor-writing-flow__click-redirect {
  height: 0; }

.edit-post-visual-editor .block-editor-block-list__block {
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 600px) {
  .editor-post-title {
    padding-left: 58px;
    padding-right: 58px; } }

.edit-post-visual-editor .editor-post-title__block {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px; }
  .edit-post-visual-editor .editor-post-title__block > div {
    margin-left: 0;
    margin-right: 0; }
  @media (min-width: 600px) {
    .edit-post-visual-editor .editor-post-title__block > div {
      margin-left: -16px;
      margin-right: -16px; } }

.edit-post-options-modal__section {
  margin: 0 0 2rem 0; }

.edit-post-options-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600; }

.edit-post-options-modal__option {
  border-top: 1px solid #e2e4e7; }
  .edit-post-options-modal__option:last-child {
    border-bottom: 1px solid #e2e4e7; }
  .edit-post-options-modal__option .components-base-control__field {
    align-items: center;
    display: flex;
    margin: 0; }
  .edit-post-options-modal__option .components-checkbox-control__label {
    flex-grow: 1;
    padding: 0.6rem 0 0.6rem 10px; }

.edit-post-options-modal__custom-fields-confirmation-message, .edit-post-options-modal__custom-fields-confirmation-button {
  margin: 0 0 0.6rem 48px; }
  @media (min-width: 782px) {
    .edit-post-options-modal__custom-fields-confirmation-message, .edit-post-options-modal__custom-fields-confirmation-button {
      margin-left: 38px; } }
  @media (min-width: 600px) {
    .edit-post-options-modal__custom-fields-confirmation-message, .edit-post-options-modal__custom-fields-confirmation-button {
      max-width: 300px; } }

.edit-post-welcome-guide__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  @media (min-width: 600px) {
    .edit-post-welcome-guide__page {
      min-height: 300px;
      padding-left: 344px; } }

.edit-post-welcome-guide__heading {
  font-family: "Noto Serif", serif;
  font-size: 21px;
  line-height: 1.4;
  margin: 8px 0; }

.edit-post-welcome-guide__image {
  background: #66c6e4;
  border-radius: 4px;
  height: 200px;
  margin: 8px 0; }
  @media (min-width: 600px) {
    .edit-post-welcome-guide__image {
      position: absolute;
      left: 0;
      top: 50%;
      height: 300px;
      width: 320px;
      margin-top: -150px; } }

.edit-post-welcome-guide__text {
  font-size: 16px;
  line-height: 1.4;
  margin: 8px 0; }

.edit-post-welcome-guide__inserter-icon {
  margin: 0 4px;
  position: relative;
  top: 4px; }

@media (min-width: 600px) {
  .edit-post-welcome-guide {
    width: 600px; } }

/**
 * Animations
 */
@keyframes edit-post__fade-in-animation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html.wp-toolbar {
  background: #fff; }

body.block-editor-page {
  background: #fff;
  /* We hide legacy notices in Gutenberg Based Pages, because they were not designed in a way that scaled well.
	   Plugins can use Gutenberg notices if they need to pass on information to the user when they are editing. */ }
  body.block-editor-page #wpcontent {
    padding-left: 0; }
  body.block-editor-page #wpbody-content {
    padding-bottom: 0; }
  body.block-editor-page #wpbody-content > div:not(.block-editor):not(#screen-meta) {
    display: none; }
  body.block-editor-page #wpfooter {
    display: none; }
  body.block-editor-page .a11y-speak-region {
    left: -1px;
    top: -1px; }
  body.block-editor-page ul#adminmenu a.wp-has-current-submenu::after,
  body.block-editor-page ul#adminmenu > li.current > a.current::after {
    border-right-color: #fff; }
  body.block-editor-page .media-frame select.attachment-filters:last-of-type {
    width: auto;
    max-width: 100%; }

.edit-post-header,
.edit-post-visual-editor,
.edit-post-text-editor,
.edit-post-sidebar,
.editor-post-publish-panel,
.components-popover,
.components-modal__frame {
  box-sizing: border-box; }
  .edit-post-header *,
  .edit-post-header *::before,
  .edit-post-header *::after,
  .edit-post-visual-editor *,
  .edit-post-visual-editor *::before,
  .edit-post-visual-editor *::after,
  .edit-post-text-editor *,
  .edit-post-text-editor *::before,
  .edit-post-text-editor *::after,
  .edit-post-sidebar *,
  .edit-post-sidebar *::before,
  .edit-post-sidebar *::after,
  .editor-post-publish-panel *,
  .editor-post-publish-panel *::before,
  .editor-post-publish-panel *::after,
  .components-popover *,
  .components-popover *::before,
  .components-popover *::after,
  .components-modal__frame *,
  .components-modal__frame *::before,
  .components-modal__frame *::after {
    box-sizing: inherit; }
  .edit-post-header .input-control,
  .edit-post-header input[type="text"],
  .edit-post-header input[type="search"],
  .edit-post-header input[type="radio"],
  .edit-post-header input[type="tel"],
  .edit-post-header input[type="time"],
  .edit-post-header input[type="url"],
  .edit-post-header input[type="week"],
  .edit-post-header input[type="password"],
  .edit-post-header input[type="checkbox"],
  .edit-post-header input[type="color"],
  .edit-post-header input[type="date"],
  .edit-post-header input[type="datetime"],
  .edit-post-header input[type="datetime-local"],
  .edit-post-header input[type="email"],
  .edit-post-header input[type="month"],
  .edit-post-header input[type="number"],
  .edit-post-header select,
  .edit-post-header textarea,
  .edit-post-visual-editor .input-control,
  .edit-post-visual-editor input[type="text"],
  .edit-post-visual-editor input[type="search"],
  .edit-post-visual-editor input[type="radio"],
  .edit-post-visual-editor input[type="tel"],
  .edit-post-visual-editor input[type="time"],
  .edit-post-visual-editor input[type="url"],
  .edit-post-visual-editor input[type="week"],
  .edit-post-visual-editor input[type="password"],
  .edit-post-visual-editor input[type="checkbox"],
  .edit-post-visual-editor input[type="color"],
  .edit-post-visual-editor input[type="date"],
  .edit-post-visual-editor input[type="datetime"],
  .edit-post-visual-editor input[type="datetime-local"],
  .edit-post-visual-editor input[type="email"],
  .edit-post-visual-editor input[type="month"],
  .edit-post-visual-editor input[type="number"],
  .edit-post-visual-editor select,
  .edit-post-visual-editor textarea,
  .edit-post-text-editor .input-control,
  .edit-post-text-editor input[type="text"],
  .edit-post-text-editor input[type="search"],
  .edit-post-text-editor input[type="radio"],
  .edit-post-text-editor input[type="tel"],
  .edit-post-text-editor input[type="time"],
  .edit-post-text-editor input[type="url"],
  .edit-post-text-editor input[type="week"],
  .edit-post-text-editor input[type="password"],
  .edit-post-text-editor input[type="checkbox"],
  .edit-post-text-editor input[type="color"],
  .edit-post-text-editor input[type="date"],
  .edit-post-text-editor input[type="datetime"],
  .edit-post-text-editor input[type="datetime-local"],
  .edit-post-text-editor input[type="email"],
  .edit-post-text-editor input[type="month"],
  .edit-post-text-editor input[type="number"],
  .edit-post-text-editor select,
  .edit-post-text-editor textarea,
  .edit-post-sidebar .input-control,
  .edit-post-sidebar input[type="text"],
  .edit-post-sidebar input[type="search"],
  .edit-post-sidebar input[type="radio"],
  .edit-post-sidebar input[type="tel"],
  .edit-post-sidebar input[type="time"],
  .edit-post-sidebar input[type="url"],
  .edit-post-sidebar input[type="week"],
  .edit-post-sidebar input[type="password"],
  .edit-post-sidebar input[type="checkbox"],
  .edit-post-sidebar input[type="color"],
  .edit-post-sidebar input[type="date"],
  .edit-post-sidebar input[type="datetime"],
  .edit-post-sidebar input[type="datetime-local"],
  .edit-post-sidebar input[type="email"],
  .edit-post-sidebar input[type="month"],
  .edit-post-sidebar input[type="number"],
  .edit-post-sidebar select,
  .edit-post-sidebar textarea,
  .editor-post-publish-panel .input-control,
  .editor-post-publish-panel input[type="text"],
  .editor-post-publish-panel input[type="search"],
  .editor-post-publish-panel input[type="radio"],
  .editor-post-publish-panel input[type="tel"],
  .editor-post-publish-panel input[type="time"],
  .editor-post-publish-panel input[type="url"],
  .editor-post-publish-panel input[type="week"],
  .editor-post-publish-panel input[type="password"],
  .editor-post-publish-panel input[type="checkbox"],
  .editor-post-publish-panel input[type="color"],
  .editor-post-publish-panel input[type="date"],
  .editor-post-publish-panel input[type="datetime"],
  .editor-post-publish-panel input[type="datetime-local"],
  .editor-post-publish-panel input[type="email"],
  .editor-post-publish-panel input[type="month"],
  .editor-post-publish-panel input[type="number"],
  .editor-post-publish-panel select,
  .editor-post-publish-panel textarea,
  .components-popover .input-control,
  .components-popover input[type="text"],
  .components-popover input[type="search"],
  .components-popover input[type="radio"],
  .components-popover input[type="tel"],
  .components-popover input[type="time"],
  .components-popover input[type="url"],
  .components-popover input[type="week"],
  .components-popover input[type="password"],
  .components-popover input[type="checkbox"],
  .components-popover input[type="color"],
  .components-popover input[type="date"],
  .components-popover input[type="datetime"],
  .components-popover input[type="datetime-local"],
  .components-popover input[type="email"],
  .components-popover input[type="month"],
  .components-popover input[type="number"],
  .components-popover select,
  .components-popover textarea,
  .components-modal__frame .input-control,
  .components-modal__frame input[type="text"],
  .components-modal__frame input[type="search"],
  .components-modal__frame input[type="radio"],
  .components-modal__frame input[type="tel"],
  .components-modal__frame input[type="time"],
  .components-modal__frame input[type="url"],
  .components-modal__frame input[type="week"],
  .components-modal__frame input[type="password"],
  .components-modal__frame input[type="checkbox"],
  .components-modal__frame input[type="color"],
  .components-modal__frame input[type="date"],
  .components-modal__frame input[type="datetime"],
  .components-modal__frame input[type="datetime-local"],
  .components-modal__frame input[type="email"],
  .components-modal__frame input[type="month"],
  .components-modal__frame input[type="number"],
  .components-modal__frame select,
  .components-modal__frame textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding: 6px 8px;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.1s linear;
    border-radius: 4px;
    border: 1px solid #7e8993;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px;
    /* Override core line-height. To be reviewed. */
    line-height: normal; }
    @media (prefers-reduced-motion: reduce) {
      .edit-post-header .input-control,
      .edit-post-header input[type="text"],
      .edit-post-header input[type="search"],
      .edit-post-header input[type="radio"],
      .edit-post-header input[type="tel"],
      .edit-post-header input[type="time"],
      .edit-post-header input[type="url"],
      .edit-post-header input[type="week"],
      .edit-post-header input[type="password"],
      .edit-post-header input[type="checkbox"],
      .edit-post-header input[type="color"],
      .edit-post-header input[type="date"],
      .edit-post-header input[type="datetime"],
      .edit-post-header input[type="datetime-local"],
      .edit-post-header input[type="email"],
      .edit-post-header input[type="month"],
      .edit-post-header input[type="number"],
      .edit-post-header select,
      .edit-post-header textarea,
      .edit-post-visual-editor .input-control,
      .edit-post-visual-editor input[type="text"],
      .edit-post-visual-editor input[type="search"],
      .edit-post-visual-editor input[type="radio"],
      .edit-post-visual-editor input[type="tel"],
      .edit-post-visual-editor input[type="time"],
      .edit-post-visual-editor input[type="url"],
      .edit-post-visual-editor input[type="week"],
      .edit-post-visual-editor input[type="password"],
      .edit-post-visual-editor input[type="checkbox"],
      .edit-post-visual-editor input[type="color"],
      .edit-post-visual-editor input[type="date"],
      .edit-post-visual-editor input[type="datetime"],
      .edit-post-visual-editor input[type="datetime-local"],
      .edit-post-visual-editor input[type="email"],
      .edit-post-visual-editor input[type="month"],
      .edit-post-visual-editor input[type="number"],
      .edit-post-visual-editor select,
      .edit-post-visual-editor textarea,
      .edit-post-text-editor .input-control,
      .edit-post-text-editor input[type="text"],
      .edit-post-text-editor input[type="search"],
      .edit-post-text-editor input[type="radio"],
      .edit-post-text-editor input[type="tel"],
      .edit-post-text-editor input[type="time"],
      .edit-post-text-editor input[type="url"],
      .edit-post-text-editor input[type="week"],
      .edit-post-text-editor input[type="password"],
      .edit-post-text-editor input[type="checkbox"],
      .edit-post-text-editor input[type="color"],
      .edit-post-text-editor input[type="date"],
      .edit-post-text-editor input[type="datetime"],
      .edit-post-text-editor input[type="datetime-local"],
      .edit-post-text-editor input[type="email"],
      .edit-post-text-editor input[type="month"],
      .edit-post-text-editor input[type="number"],
      .edit-post-text-editor select,
      .edit-post-text-editor textarea,
      .edit-post-sidebar .input-control,
      .edit-post-sidebar input[type="text"],
      .edit-post-sidebar input[type="search"],
      .edit-post-sidebar input[type="radio"],
      .edit-post-sidebar input[type="tel"],
      .edit-post-sidebar input[type="time"],
      .edit-post-sidebar input[type="url"],
      .edit-post-sidebar input[type="week"],
      .edit-post-sidebar input[type="password"],
      .edit-post-sidebar input[type="checkbox"],
      .edit-post-sidebar input[type="color"],
      .edit-post-sidebar input[type="date"],
      .edit-post-sidebar input[type="datetime"],
      .edit-post-sidebar input[type="datetime-local"],
      .edit-post-sidebar input[type="email"],
      .edit-post-sidebar input[type="month"],
      .edit-post-sidebar input[type="number"],
      .edit-post-sidebar select,
      .edit-post-sidebar textarea,
      .editor-post-publish-panel .input-control,
      .editor-post-publish-panel input[type="text"],
      .editor-post-publish-panel input[type="search"],
      .editor-post-publish-panel input[type="radio"],
      .editor-post-publish-panel input[type="tel"],
      .editor-post-publish-panel input[type="time"],
      .editor-post-publish-panel input[type="url"],
      .editor-post-publish-panel input[type="week"],
      .editor-post-publish-panel input[type="password"],
      .editor-post-publish-panel input[type="checkbox"],
      .editor-post-publish-panel input[type="color"],
      .editor-post-publish-panel input[type="date"],
      .editor-post-publish-panel input[type="datetime"],
      .editor-post-publish-panel input[type="datetime-local"],
      .editor-post-publish-panel input[type="email"],
      .editor-post-publish-panel input[type="month"],
      .editor-post-publish-panel input[type="number"],
      .editor-post-publish-panel select,
      .editor-post-publish-panel textarea,
      .components-popover .input-control,
      .components-popover input[type="text"],
      .components-popover input[type="search"],
      .components-popover input[type="radio"],
      .components-popover input[type="tel"],
      .components-popover input[type="time"],
      .components-popover input[type="url"],
      .components-popover input[type="week"],
      .components-popover input[type="password"],
      .components-popover input[type="checkbox"],
      .components-popover input[type="color"],
      .components-popover input[type="date"],
      .components-popover input[type="datetime"],
      .components-popover input[type="datetime-local"],
      .components-popover input[type="email"],
      .components-popover input[type="month"],
      .components-popover input[type="number"],
      .components-popover select,
      .components-popover textarea,
      .components-modal__frame .input-control,
      .components-modal__frame input[type="text"],
      .components-modal__frame input[type="search"],
      .components-modal__frame input[type="radio"],
      .components-modal__frame input[type="tel"],
      .components-modal__frame input[type="time"],
      .components-modal__frame input[type="url"],
      .components-modal__frame input[type="week"],
      .components-modal__frame input[type="password"],
      .components-modal__frame input[type="checkbox"],
      .components-modal__frame input[type="color"],
      .components-modal__frame input[type="date"],
      .components-modal__frame input[type="datetime"],
      .components-modal__frame input[type="datetime-local"],
      .components-modal__frame input[type="email"],
      .components-modal__frame input[type="month"],
      .components-modal__frame input[type="number"],
      .components-modal__frame select,
      .components-modal__frame textarea {
        transition-duration: 0s; } }
    @media (min-width: 600px) {
      .edit-post-header .input-control,
      .edit-post-header input[type="text"],
      .edit-post-header input[type="search"],
      .edit-post-header input[type="radio"],
      .edit-post-header input[type="tel"],
      .edit-post-header input[type="time"],
      .edit-post-header input[type="url"],
      .edit-post-header input[type="week"],
      .edit-post-header input[type="password"],
      .edit-post-header input[type="checkbox"],
      .edit-post-header input[type="color"],
      .edit-post-header input[type="date"],
      .edit-post-header input[type="datetime"],
      .edit-post-header input[type="datetime-local"],
      .edit-post-header input[type="email"],
      .edit-post-header input[type="month"],
      .edit-post-header input[type="number"],
      .edit-post-header select,
      .edit-post-header textarea,
      .edit-post-visual-editor .input-control,
      .edit-post-visual-editor input[type="text"],
      .edit-post-visual-editor input[type="search"],
      .edit-post-visual-editor input[type="radio"],
      .edit-post-visual-editor input[type="tel"],
      .edit-post-visual-editor input[type="time"],
      .edit-post-visual-editor input[type="url"],
      .edit-post-visual-editor input[type="week"],
      .edit-post-visual-editor input[type="password"],
      .edit-post-visual-editor input[type="checkbox"],
      .edit-post-visual-editor input[type="color"],
      .edit-post-visual-editor input[type="date"],
      .edit-post-visual-editor input[type="datetime"],
      .edit-post-visual-editor input[type="datetime-local"],
      .edit-post-visual-editor input[type="email"],
      .edit-post-visual-editor input[type="month"],
      .edit-post-visual-editor input[type="number"],
      .edit-post-visual-editor select,
      .edit-post-visual-editor textarea,
      .edit-post-text-editor .input-control,
      .edit-post-text-editor input[type="text"],
      .edit-post-text-editor input[type="search"],
      .edit-post-text-editor input[type="radio"],
      .edit-post-text-editor input[type="tel"],
      .edit-post-text-editor input[type="time"],
      .edit-post-text-editor input[type="url"],
      .edit-post-text-editor input[type="week"],
      .edit-post-text-editor input[type="password"],
      .edit-post-text-editor input[type="checkbox"],
      .edit-post-text-editor input[type="color"],
      .edit-post-text-editor input[type="date"],
      .edit-post-text-editor input[type="datetime"],
      .edit-post-text-editor input[type="datetime-local"],
      .edit-post-text-editor input[type="email"],
      .edit-post-text-editor input[type="month"],
      .edit-post-text-editor input[type="number"],
      .edit-post-text-editor select,
      .edit-post-text-editor textarea,
      .edit-post-sidebar .input-control,
      .edit-post-sidebar input[type="text"],
      .edit-post-sidebar input[type="search"],
      .edit-post-sidebar input[type="radio"],
      .edit-post-sidebar input[type="tel"],
      .edit-post-sidebar input[type="time"],
      .edit-post-sidebar input[type="url"],
      .edit-post-sidebar input[type="week"],
      .edit-post-sidebar input[type="password"],
      .edit-post-sidebar input[type="checkbox"],
      .edit-post-sidebar input[type="color"],
      .edit-post-sidebar input[type="date"],
      .edit-post-sidebar input[type="datetime"],
      .edit-post-sidebar input[type="datetime-local"],
      .edit-post-sidebar input[type="email"],
      .edit-post-sidebar input[type="month"],
      .edit-post-sidebar input[type="number"],
      .edit-post-sidebar select,
      .edit-post-sidebar textarea,
      .editor-post-publish-panel .input-control,
      .editor-post-publish-panel input[type="text"],
      .editor-post-publish-panel input[type="search"],
      .editor-post-publish-panel input[type="radio"],
      .editor-post-publish-panel input[type="tel"],
      .editor-post-publish-panel input[type="time"],
      .editor-post-publish-panel input[type="url"],
      .editor-post-publish-panel input[type="week"],
      .editor-post-publish-panel input[type="password"],
      .editor-post-publish-panel input[type="checkbox"],
      .editor-post-publish-panel input[type="color"],
      .editor-post-publish-panel input[type="date"],
      .editor-post-publish-panel input[type="datetime"],
      .editor-post-publish-panel input[type="datetime-local"],
      .editor-post-publish-panel input[type="email"],
      .editor-post-publish-panel input[type="month"],
      .editor-post-publish-panel input[type="number"],
      .editor-post-publish-panel select,
      .editor-post-publish-panel textarea,
      .components-popover .input-control,
      .components-popover input[type="text"],
      .components-popover input[type="search"],
      .components-popover input[type="radio"],
      .components-popover input[type="tel"],
      .components-popover input[type="time"],
      .components-popover input[type="url"],
      .components-popover input[type="week"],
      .components-popover input[type="password"],
      .components-popover input[type="checkbox"],
      .components-popover input[type="color"],
      .components-popover input[type="date"],
      .components-popover input[type="datetime"],
      .components-popover input[type="datetime-local"],
      .components-popover input[type="email"],
      .components-popover input[type="month"],
      .components-popover input[type="number"],
      .components-popover select,
      .components-popover textarea,
      .components-modal__frame .input-control,
      .components-modal__frame input[type="text"],
      .components-modal__frame input[type="search"],
      .components-modal__frame input[type="radio"],
      .components-modal__frame input[type="tel"],
      .components-modal__frame input[type="time"],
      .components-modal__frame input[type="url"],
      .components-modal__frame input[type="week"],
      .components-modal__frame input[type="password"],
      .components-modal__frame input[type="checkbox"],
      .components-modal__frame input[type="color"],
      .components-modal__frame input[type="date"],
      .components-modal__frame input[type="datetime"],
      .components-modal__frame input[type="datetime-local"],
      .components-modal__frame input[type="email"],
      .components-modal__frame input[type="month"],
      .components-modal__frame input[type="number"],
      .components-modal__frame select,
      .components-modal__frame textarea {
        font-size: 13px;
        /* Override core line-height. To be reviewed. */
        line-height: normal; } }
    .edit-post-header .input-control:focus,
    .edit-post-header input[type="text"]:focus,
    .edit-post-header input[type="search"]:focus,
    .edit-post-header input[type="radio"]:focus,
    .edit-post-header input[type="tel"]:focus,
    .edit-post-header input[type="time"]:focus,
    .edit-post-header input[type="url"]:focus,
    .edit-post-header input[type="week"]:focus,
    .edit-post-header input[type="password"]:focus,
    .edit-post-header input[type="checkbox"]:focus,
    .edit-post-header input[type="color"]:focus,
    .edit-post-header input[type="date"]:focus,
    .edit-post-header input[type="datetime"]:focus,
    .edit-post-header input[type="datetime-local"]:focus,
    .edit-post-header input[type="email"]:focus,
    .edit-post-header input[type="month"]:focus,
    .edit-post-header input[type="number"]:focus,
    .edit-post-header select:focus,
    .edit-post-header textarea:focus,
    .edit-post-visual-editor .input-control:focus,
    .edit-post-visual-editor input[type="text"]:focus,
    .edit-post-visual-editor input[type="search"]:focus,
    .edit-post-visual-editor input[type="radio"]:focus,
    .edit-post-visual-editor input[type="tel"]:focus,
    .edit-post-visual-editor input[type="time"]:focus,
    .edit-post-visual-editor input[type="url"]:focus,
    .edit-post-visual-editor input[type="week"]:focus,
    .edit-post-visual-editor input[type="password"]:focus,
    .edit-post-visual-editor input[type="checkbox"]:focus,
    .edit-post-visual-editor input[type="color"]:focus,
    .edit-post-visual-editor input[type="date"]:focus,
    .edit-post-visual-editor input[type="datetime"]:focus,
    .edit-post-visual-editor input[type="datetime-local"]:focus,
    .edit-post-visual-editor input[type="email"]:focus,
    .edit-post-visual-editor input[type="month"]:focus,
    .edit-post-visual-editor input[type="number"]:focus,
    .edit-post-visual-editor select:focus,
    .edit-post-visual-editor textarea:focus,
    .edit-post-text-editor .input-control:focus,
    .edit-post-text-editor input[type="text"]:focus,
    .edit-post-text-editor input[type="search"]:focus,
    .edit-post-text-editor input[type="radio"]:focus,
    .edit-post-text-editor input[type="tel"]:focus,
    .edit-post-text-editor input[type="time"]:focus,
    .edit-post-text-editor input[type="url"]:focus,
    .edit-post-text-editor input[type="week"]:focus,
    .edit-post-text-editor input[type="password"]:focus,
    .edit-post-text-editor input[type="checkbox"]:focus,
    .edit-post-text-editor input[type="color"]:focus,
    .edit-post-text-editor input[type="date"]:focus,
    .edit-post-text-editor input[type="datetime"]:focus,
    .edit-post-text-editor input[type="datetime-local"]:focus,
    .edit-post-text-editor input[type="email"]:focus,
    .edit-post-text-editor input[type="month"]:focus,
    .edit-post-text-editor input[type="number"]:focus,
    .edit-post-text-editor select:focus,
    .edit-post-text-editor textarea:focus,
    .edit-post-sidebar .input-control:focus,
    .edit-post-sidebar input[type="text"]:focus,
    .edit-post-sidebar input[type="search"]:focus,
    .edit-post-sidebar input[type="radio"]:focus,
    .edit-post-sidebar input[type="tel"]:focus,
    .edit-post-sidebar input[type="time"]:focus,
    .edit-post-sidebar input[type="url"]:focus,
    .edit-post-sidebar input[type="week"]:focus,
    .edit-post-sidebar input[type="password"]:focus,
    .edit-post-sidebar input[type="checkbox"]:focus,
    .edit-post-sidebar input[type="color"]:focus,
    .edit-post-sidebar input[type="date"]:focus,
    .edit-post-sidebar input[type="datetime"]:focus,
    .edit-post-sidebar input[type="datetime-local"]:focus,
    .edit-post-sidebar input[type="email"]:focus,
    .edit-post-sidebar input[type="month"]:focus,
    .edit-post-sidebar input[type="number"]:focus,
    .edit-post-sidebar select:focus,
    .edit-post-sidebar textarea:focus,
    .editor-post-publish-panel .input-control:focus,
    .editor-post-publish-panel input[type="text"]:focus,
    .editor-post-publish-panel input[type="search"]:focus,
    .editor-post-publish-panel input[type="radio"]:focus,
    .editor-post-publish-panel input[type="tel"]:focus,
    .editor-post-publish-panel input[type="time"]:focus,
    .editor-post-publish-panel input[type="url"]:focus,
    .editor-post-publish-panel input[type="week"]:focus,
    .editor-post-publish-panel input[type="password"]:focus,
    .editor-post-publish-panel input[type="checkbox"]:focus,
    .editor-post-publish-panel input[type="color"]:focus,
    .editor-post-publish-panel input[type="date"]:focus,
    .editor-post-publish-panel input[type="datetime"]:focus,
    .editor-post-publish-panel input[type="datetime-local"]:focus,
    .editor-post-publish-panel input[type="email"]:focus,
    .editor-post-publish-panel input[type="month"]:focus,
    .editor-post-publish-panel input[type="number"]:focus,
    .editor-post-publish-panel select:focus,
    .editor-post-publish-panel textarea:focus,
    .components-popover .input-control:focus,
    .components-popover input[type="text"]:focus,
    .components-popover input[type="search"]:focus,
    .components-popover input[type="radio"]:focus,
    .components-popover input[type="tel"]:focus,
    .components-popover input[type="time"]:focus,
    .components-popover input[type="url"]:focus,
    .components-popover input[type="week"]:focus,
    .components-popover input[type="password"]:focus,
    .components-popover input[type="checkbox"]:focus,
    .components-popover input[type="color"]:focus,
    .components-popover input[type="date"]:focus,
    .components-popover input[type="datetime"]:focus,
    .components-popover input[type="datetime-local"]:focus,
    .components-popover input[type="email"]:focus,
    .components-popover input[type="month"]:focus,
    .components-popover input[type="number"]:focus,
    .components-popover select:focus,
    .components-popover textarea:focus,
    .components-modal__frame .input-control:focus,
    .components-modal__frame input[type="text"]:focus,
    .components-modal__frame input[type="search"]:focus,
    .components-modal__frame input[type="radio"]:focus,
    .components-modal__frame input[type="tel"]:focus,
    .components-modal__frame input[type="time"]:focus,
    .components-modal__frame input[type="url"]:focus,
    .components-modal__frame input[type="week"]:focus,
    .components-modal__frame input[type="password"]:focus,
    .components-modal__frame input[type="checkbox"]:focus,
    .components-modal__frame input[type="color"]:focus,
    .components-modal__frame input[type="date"]:focus,
    .components-modal__frame input[type="datetime"]:focus,
    .components-modal__frame input[type="datetime-local"]:focus,
    .components-modal__frame input[type="email"]:focus,
    .components-modal__frame input[type="month"]:focus,
    .components-modal__frame input[type="number"]:focus,
    .components-modal__frame select:focus,
    .components-modal__frame textarea:focus {
      color: #191e23;
      border-color: #007cba;
      box-shadow: 0 0 0 1px #007cba;
      outline: 2px solid transparent; }
  .edit-post-header input[type="number"],
  .edit-post-visual-editor input[type="number"],
  .edit-post-text-editor input[type="number"],
  .edit-post-sidebar input[type="number"],
  .editor-post-publish-panel input[type="number"],
  .components-popover input[type="number"],
  .components-modal__frame input[type="number"] {
    padding-left: 4px;
    padding-right: 4px; }
  .edit-post-header select,
  .edit-post-visual-editor select,
  .edit-post-text-editor select,
  .edit-post-sidebar select,
  .editor-post-publish-panel select,
  .components-popover select,
  .components-modal__frame select {
    padding: 3px 24px 3px 8px;
    font-size: 13px;
    color: #555d66; }
    .edit-post-header select:focus,
    .edit-post-visual-editor select:focus,
    .edit-post-text-editor select:focus,
    .edit-post-sidebar select:focus,
    .editor-post-publish-panel select:focus,
    .components-popover select:focus,
    .components-modal__frame select:focus {
      border-color: #008dbe;
      outline: 2px solid transparent;
      outline-offset: 0; }
  .edit-post-header input[type="checkbox"],
  .edit-post-header input[type="radio"],
  .edit-post-visual-editor input[type="checkbox"],
  .edit-post-visual-editor input[type="radio"],
  .edit-post-text-editor input[type="checkbox"],
  .edit-post-text-editor input[type="radio"],
  .edit-post-sidebar input[type="checkbox"],
  .edit-post-sidebar input[type="radio"],
  .editor-post-publish-panel input[type="checkbox"],
  .editor-post-publish-panel input[type="radio"],
  .components-popover input[type="checkbox"],
  .components-popover input[type="radio"],
  .components-modal__frame input[type="checkbox"],
  .components-modal__frame input[type="radio"] {
    border: 2px solid #757575;
    margin-right: 12px;
    transition: none; }
    .edit-post-header input[type="checkbox"]:focus,
    .edit-post-header input[type="radio"]:focus,
    .edit-post-visual-editor input[type="checkbox"]:focus,
    .edit-post-visual-editor input[type="radio"]:focus,
    .edit-post-text-editor input[type="checkbox"]:focus,
    .edit-post-text-editor input[type="radio"]:focus,
    .edit-post-sidebar input[type="checkbox"]:focus,
    .edit-post-sidebar input[type="radio"]:focus,
    .editor-post-publish-panel input[type="checkbox"]:focus,
    .editor-post-publish-panel input[type="radio"]:focus,
    .components-popover input[type="checkbox"]:focus,
    .components-popover input[type="radio"]:focus,
    .components-modal__frame input[type="checkbox"]:focus,
    .components-modal__frame input[type="radio"]:focus {
      border-color: #757575;
      box-shadow: 0 0 0 1px #757575; }
    .edit-post-header input[type="checkbox"]:checked,
    .edit-post-header input[type="radio"]:checked,
    .edit-post-visual-editor input[type="checkbox"]:checked,
    .edit-post-visual-editor input[type="radio"]:checked,
    .edit-post-text-editor input[type="checkbox"]:checked,
    .edit-post-text-editor input[type="radio"]:checked,
    .edit-post-sidebar input[type="checkbox"]:checked,
    .edit-post-sidebar input[type="radio"]:checked,
    .editor-post-publish-panel input[type="checkbox"]:checked,
    .editor-post-publish-panel input[type="radio"]:checked,
    .components-popover input[type="checkbox"]:checked,
    .components-popover input[type="radio"]:checked,
    .components-modal__frame input[type="checkbox"]:checked,
    .components-modal__frame input[type="radio"]:checked {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .edit-post-header input[type="checkbox"]:checked, body.admin-color-sunrise .edit-post-header input[type="radio"]:checked, body.admin-color-sunrise .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-sunrise .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-sunrise .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-sunrise .edit-post-text-editor input[type="radio"]:checked, body.admin-color-sunrise .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-sunrise .edit-post-sidebar input[type="radio"]:checked, body.admin-color-sunrise .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-sunrise .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-sunrise .components-popover input[type="checkbox"]:checked, body.admin-color-sunrise .components-popover input[type="radio"]:checked, body.admin-color-sunrise .components-modal__frame input[type="checkbox"]:checked, body.admin-color-sunrise .components-modal__frame input[type="radio"]:checked {
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .edit-post-header input[type="checkbox"]:checked, body.admin-color-ocean .edit-post-header input[type="radio"]:checked, body.admin-color-ocean .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-ocean .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-ocean .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-ocean .edit-post-text-editor input[type="radio"]:checked, body.admin-color-ocean .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-ocean .edit-post-sidebar input[type="radio"]:checked, body.admin-color-ocean .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-ocean .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-ocean .components-popover input[type="checkbox"]:checked, body.admin-color-ocean .components-popover input[type="radio"]:checked, body.admin-color-ocean .components-modal__frame input[type="checkbox"]:checked, body.admin-color-ocean .components-modal__frame input[type="radio"]:checked {
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .edit-post-header input[type="checkbox"]:checked, body.admin-color-midnight .edit-post-header input[type="radio"]:checked, body.admin-color-midnight .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-midnight .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-midnight .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-midnight .edit-post-text-editor input[type="radio"]:checked, body.admin-color-midnight .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-midnight .edit-post-sidebar input[type="radio"]:checked, body.admin-color-midnight .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-midnight .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-midnight .components-popover input[type="checkbox"]:checked, body.admin-color-midnight .components-popover input[type="radio"]:checked, body.admin-color-midnight .components-modal__frame input[type="checkbox"]:checked, body.admin-color-midnight .components-modal__frame input[type="radio"]:checked {
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .edit-post-header input[type="checkbox"]:checked, body.admin-color-ectoplasm .edit-post-header input[type="radio"]:checked, body.admin-color-ectoplasm .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-ectoplasm .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-ectoplasm .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-ectoplasm .edit-post-text-editor input[type="radio"]:checked, body.admin-color-ectoplasm .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-ectoplasm .edit-post-sidebar input[type="radio"]:checked, body.admin-color-ectoplasm .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-ectoplasm .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-ectoplasm .components-popover input[type="checkbox"]:checked, body.admin-color-ectoplasm .components-popover input[type="radio"]:checked, body.admin-color-ectoplasm .components-modal__frame input[type="checkbox"]:checked, body.admin-color-ectoplasm .components-modal__frame input[type="radio"]:checked {
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .edit-post-header input[type="checkbox"]:checked, body.admin-color-coffee .edit-post-header input[type="radio"]:checked, body.admin-color-coffee .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-coffee .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-coffee .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-coffee .edit-post-text-editor input[type="radio"]:checked, body.admin-color-coffee .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-coffee .edit-post-sidebar input[type="radio"]:checked, body.admin-color-coffee .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-coffee .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-coffee .components-popover input[type="checkbox"]:checked, body.admin-color-coffee .components-popover input[type="radio"]:checked, body.admin-color-coffee .components-modal__frame input[type="checkbox"]:checked, body.admin-color-coffee .components-modal__frame input[type="radio"]:checked {
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .edit-post-header input[type="checkbox"]:checked, body.admin-color-blue .edit-post-header input[type="radio"]:checked, body.admin-color-blue .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-blue .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-blue .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-blue .edit-post-text-editor input[type="radio"]:checked, body.admin-color-blue .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-blue .edit-post-sidebar input[type="radio"]:checked, body.admin-color-blue .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-blue .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-blue .components-popover input[type="checkbox"]:checked, body.admin-color-blue .components-popover input[type="radio"]:checked, body.admin-color-blue .components-modal__frame input[type="checkbox"]:checked, body.admin-color-blue .components-modal__frame input[type="radio"]:checked {
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .edit-post-header input[type="checkbox"]:checked, body.admin-color-light .edit-post-header input[type="radio"]:checked, body.admin-color-light .edit-post-visual-editor input[type="checkbox"]:checked, body.admin-color-light .edit-post-visual-editor input[type="radio"]:checked, body.admin-color-light .edit-post-text-editor input[type="checkbox"]:checked, body.admin-color-light .edit-post-text-editor input[type="radio"]:checked, body.admin-color-light .edit-post-sidebar input[type="checkbox"]:checked, body.admin-color-light .edit-post-sidebar input[type="radio"]:checked, body.admin-color-light .editor-post-publish-panel input[type="checkbox"]:checked, body.admin-color-light .editor-post-publish-panel input[type="radio"]:checked, body.admin-color-light .components-popover input[type="checkbox"]:checked, body.admin-color-light .components-popover input[type="radio"]:checked, body.admin-color-light .components-modal__frame input[type="checkbox"]:checked, body.admin-color-light .components-modal__frame input[type="radio"]:checked {
      background: #11a0d2;
      border-color: #11a0d2; }
    .edit-post-header input[type="checkbox"]:checked:focus,
    .edit-post-header input[type="radio"]:checked:focus,
    .edit-post-visual-editor input[type="checkbox"]:checked:focus,
    .edit-post-visual-editor input[type="radio"]:checked:focus,
    .edit-post-text-editor input[type="checkbox"]:checked:focus,
    .edit-post-text-editor input[type="radio"]:checked:focus,
    .edit-post-sidebar input[type="checkbox"]:checked:focus,
    .edit-post-sidebar input[type="radio"]:checked:focus,
    .editor-post-publish-panel input[type="checkbox"]:checked:focus,
    .editor-post-publish-panel input[type="radio"]:checked:focus,
    .components-popover input[type="checkbox"]:checked:focus,
    .components-popover input[type="radio"]:checked:focus,
    .components-modal__frame input[type="checkbox"]:checked:focus,
    .components-modal__frame input[type="radio"]:checked:focus {
      box-shadow: 0 0 0 2px #757575; }
  .edit-post-header input[type="checkbox"],
  .edit-post-visual-editor input[type="checkbox"],
  .edit-post-text-editor input[type="checkbox"],
  .edit-post-sidebar input[type="checkbox"],
  .editor-post-publish-panel input[type="checkbox"],
  .components-popover input[type="checkbox"],
  .components-modal__frame input[type="checkbox"] {
    border-radius: 2px; }
    .edit-post-header input[type="checkbox"]:checked::before, .edit-post-header input[type="checkbox"][aria-checked="mixed"]::before,
    .edit-post-visual-editor input[type="checkbox"]:checked::before,
    .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"]::before,
    .edit-post-text-editor input[type="checkbox"]:checked::before,
    .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"]::before,
    .edit-post-sidebar input[type="checkbox"]:checked::before,
    .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"]::before,
    .editor-post-publish-panel input[type="checkbox"]:checked::before,
    .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"]::before,
    .components-popover input[type="checkbox"]:checked::before,
    .components-popover input[type="checkbox"][aria-checked="mixed"]::before,
    .components-modal__frame input[type="checkbox"]:checked::before,
    .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
      margin: -3px -5px;
      color: #fff; }
      @media (min-width: 782px) {
        .edit-post-header input[type="checkbox"]:checked::before, .edit-post-header input[type="checkbox"][aria-checked="mixed"]::before,
        .edit-post-visual-editor input[type="checkbox"]:checked::before,
        .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"]::before,
        .edit-post-text-editor input[type="checkbox"]:checked::before,
        .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"]::before,
        .edit-post-sidebar input[type="checkbox"]:checked::before,
        .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"]::before,
        .editor-post-publish-panel input[type="checkbox"]:checked::before,
        .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"]::before,
        .components-popover input[type="checkbox"]:checked::before,
        .components-popover input[type="checkbox"][aria-checked="mixed"]::before,
        .components-modal__frame input[type="checkbox"]:checked::before,
        .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
          margin: -4px 0 0 -5px; } }
    .edit-post-header input[type="checkbox"][aria-checked="mixed"],
    .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"],
    .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"],
    .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"],
    .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"],
    .components-popover input[type="checkbox"][aria-checked="mixed"],
    .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .edit-post-header input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .components-popover input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #11a0d2;
      border-color: #11a0d2; }
      .edit-post-header input[type="checkbox"][aria-checked="mixed"]::before,
      .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"]::before,
      .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"]::before,
      .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"]::before,
      .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"]::before,
      .components-popover input[type="checkbox"][aria-checked="mixed"]::before,
      .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
        content: "\f460";
        float: left;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        /* stylelint-disable */
        font: normal 30px/1 dashicons;
        /* stylelint-enable */
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        @media (min-width: 782px) {
          .edit-post-header input[type="checkbox"][aria-checked="mixed"]::before,
          .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"]::before,
          .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"]::before,
          .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"]::before,
          .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"]::before,
          .components-popover input[type="checkbox"][aria-checked="mixed"]::before,
          .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
            float: none;
            font-size: 21px; } }
      .edit-post-header input[type="checkbox"][aria-checked="mixed"]:focus,
      .edit-post-visual-editor input[type="checkbox"][aria-checked="mixed"]:focus,
      .edit-post-text-editor input[type="checkbox"][aria-checked="mixed"]:focus,
      .edit-post-sidebar input[type="checkbox"][aria-checked="mixed"]:focus,
      .editor-post-publish-panel input[type="checkbox"][aria-checked="mixed"]:focus,
      .components-popover input[type="checkbox"][aria-checked="mixed"]:focus,
      .components-modal__frame input[type="checkbox"][aria-checked="mixed"]:focus {
        box-shadow: 0 0 0 2px #555d66; }
  .edit-post-header input[type="radio"],
  .edit-post-visual-editor input[type="radio"],
  .edit-post-text-editor input[type="radio"],
  .edit-post-sidebar input[type="radio"],
  .editor-post-publish-panel input[type="radio"],
  .components-popover input[type="radio"],
  .components-modal__frame input[type="radio"] {
    border-radius: 50%; }
    .edit-post-header input[type="radio"]:checked::before,
    .edit-post-visual-editor input[type="radio"]:checked::before,
    .edit-post-text-editor input[type="radio"]:checked::before,
    .edit-post-sidebar input[type="radio"]:checked::before,
    .editor-post-publish-panel input[type="radio"]:checked::before,
    .components-popover input[type="radio"]:checked::before,
    .components-modal__frame input[type="radio"]:checked::before {
      width: 6px;
      height: 6px;
      margin: 6px 0 0 6px;
      background-color: #fff; }
      @media (min-width: 782px) {
        .edit-post-header input[type="radio"]:checked::before,
        .edit-post-visual-editor input[type="radio"]:checked::before,
        .edit-post-text-editor input[type="radio"]:checked::before,
        .edit-post-sidebar input[type="radio"]:checked::before,
        .editor-post-publish-panel input[type="radio"]:checked::before,
        .components-popover input[type="radio"]:checked::before,
        .components-modal__frame input[type="radio"]:checked::before {
          margin: 3px 0 0 3px; } }
  .edit-post-header input::-webkit-input-placeholder,
  .edit-post-header textarea::-webkit-input-placeholder,
  .edit-post-visual-editor input::-webkit-input-placeholder,
  .edit-post-visual-editor textarea::-webkit-input-placeholder,
  .edit-post-text-editor input::-webkit-input-placeholder,
  .edit-post-text-editor textarea::-webkit-input-placeholder,
  .edit-post-sidebar input::-webkit-input-placeholder,
  .edit-post-sidebar textarea::-webkit-input-placeholder,
  .editor-post-publish-panel input::-webkit-input-placeholder,
  .editor-post-publish-panel textarea::-webkit-input-placeholder,
  .components-popover input::-webkit-input-placeholder,
  .components-popover textarea::-webkit-input-placeholder,
  .components-modal__frame input::-webkit-input-placeholder,
  .components-modal__frame textarea::-webkit-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .edit-post-header input::-moz-placeholder,
  .edit-post-header textarea::-moz-placeholder,
  .edit-post-visual-editor input::-moz-placeholder,
  .edit-post-visual-editor textarea::-moz-placeholder,
  .edit-post-text-editor input::-moz-placeholder,
  .edit-post-text-editor textarea::-moz-placeholder,
  .edit-post-sidebar input::-moz-placeholder,
  .edit-post-sidebar textarea::-moz-placeholder,
  .editor-post-publish-panel input::-moz-placeholder,
  .editor-post-publish-panel textarea::-moz-placeholder,
  .components-popover input::-moz-placeholder,
  .components-popover textarea::-moz-placeholder,
  .components-modal__frame input::-moz-placeholder,
  .components-modal__frame textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(14, 28, 46, 0.62); }
  .edit-post-header input:-ms-input-placeholder,
  .edit-post-header textarea:-ms-input-placeholder,
  .edit-post-visual-editor input:-ms-input-placeholder,
  .edit-post-visual-editor textarea:-ms-input-placeholder,
  .edit-post-text-editor input:-ms-input-placeholder,
  .edit-post-text-editor textarea:-ms-input-placeholder,
  .edit-post-sidebar input:-ms-input-placeholder,
  .edit-post-sidebar textarea:-ms-input-placeholder,
  .editor-post-publish-panel input:-ms-input-placeholder,
  .editor-post-publish-panel textarea:-ms-input-placeholder,
  .components-popover input:-ms-input-placeholder,
  .components-popover textarea:-ms-input-placeholder,
  .components-modal__frame input:-ms-input-placeholder,
  .components-modal__frame textarea:-ms-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .is-dark-theme .edit-post-header input::-webkit-input-placeholder, .is-dark-theme
  .edit-post-header textarea::-webkit-input-placeholder, .is-dark-theme
  .edit-post-visual-editor input::-webkit-input-placeholder, .is-dark-theme
  .edit-post-visual-editor textarea::-webkit-input-placeholder, .is-dark-theme
  .edit-post-text-editor input::-webkit-input-placeholder, .is-dark-theme
  .edit-post-text-editor textarea::-webkit-input-placeholder, .is-dark-theme
  .edit-post-sidebar input::-webkit-input-placeholder, .is-dark-theme
  .edit-post-sidebar textarea::-webkit-input-placeholder, .is-dark-theme
  .editor-post-publish-panel input::-webkit-input-placeholder, .is-dark-theme
  .editor-post-publish-panel textarea::-webkit-input-placeholder, .is-dark-theme
  .components-popover input::-webkit-input-placeholder, .is-dark-theme
  .components-popover textarea::-webkit-input-placeholder, .is-dark-theme
  .components-modal__frame input::-webkit-input-placeholder, .is-dark-theme
  .components-modal__frame textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .edit-post-header input::-moz-placeholder, .is-dark-theme
  .edit-post-header textarea::-moz-placeholder, .is-dark-theme
  .edit-post-visual-editor input::-moz-placeholder, .is-dark-theme
  .edit-post-visual-editor textarea::-moz-placeholder, .is-dark-theme
  .edit-post-text-editor input::-moz-placeholder, .is-dark-theme
  .edit-post-text-editor textarea::-moz-placeholder, .is-dark-theme
  .edit-post-sidebar input::-moz-placeholder, .is-dark-theme
  .edit-post-sidebar textarea::-moz-placeholder, .is-dark-theme
  .editor-post-publish-panel input::-moz-placeholder, .is-dark-theme
  .editor-post-publish-panel textarea::-moz-placeholder, .is-dark-theme
  .components-popover input::-moz-placeholder, .is-dark-theme
  .components-popover textarea::-moz-placeholder, .is-dark-theme
  .components-modal__frame input::-moz-placeholder, .is-dark-theme
  .components-modal__frame textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .edit-post-header input:-ms-input-placeholder, .is-dark-theme
  .edit-post-header textarea:-ms-input-placeholder, .is-dark-theme
  .edit-post-visual-editor input:-ms-input-placeholder, .is-dark-theme
  .edit-post-visual-editor textarea:-ms-input-placeholder, .is-dark-theme
  .edit-post-text-editor input:-ms-input-placeholder, .is-dark-theme
  .edit-post-text-editor textarea:-ms-input-placeholder, .is-dark-theme
  .edit-post-sidebar input:-ms-input-placeholder, .is-dark-theme
  .edit-post-sidebar textarea:-ms-input-placeholder, .is-dark-theme
  .editor-post-publish-panel input:-ms-input-placeholder, .is-dark-theme
  .editor-post-publish-panel textarea:-ms-input-placeholder, .is-dark-theme
  .components-popover input:-ms-input-placeholder, .is-dark-theme
  .components-popover textarea:-ms-input-placeholder, .is-dark-theme
  .components-modal__frame input:-ms-input-placeholder, .is-dark-theme
  .components-modal__frame textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }

@media (min-width: 600px) {
  .block-editor__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: calc(100vh - 46px); } }

@media (min-width: 782px) {
  .block-editor__container {
    min-height: calc(100vh - 32px); }
    body.is-fullscreen-mode .block-editor__container {
      min-height: 100vh; } }

.block-editor__container img {
  max-width: 100%;
  height: auto; }

.block-editor__container iframe {
  width: 100%; }

.block-editor__container .components-navigate-regions {
  height: 100%; }

.wp-block {
  max-width: 580px; }
  .wp-block[data-align="wide"], .wp-block.alignwide {
    max-width: 1100px; }
  .wp-block[data-align="full"], .wp-block.alignfull {
    max-width: none; }
