@charset "UTF-8";
/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block UI.
 */
/**
 * Border radii.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Block Toolbar/Formatting Buttons
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.editor-autocompleters__block .block-editor-block-icon {
  margin-right: 8px; }

.editor-autocompleters__user .editor-autocompleters__no-avatar::before {
  /* stylelint-disable */
  font: normal 20px/1 dashicons;
  /* stylelint-enable */
  content: "\f110";
  margin-right: 5px;
  vertical-align: middle; }

.editor-autocompleters__user .editor-autocompleters__user-avatar {
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: none;
  width: 24px;
  height: 24px; }

.editor-autocompleters__user .editor-autocompleters__user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  flex-shrink: 0;
  flex-grow: 1; }

.editor-autocompleters__user .editor-autocompleters__user-slug {
  margin-left: 8px;
  color: #8f98a1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: none;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0; }

.editor-autocompleters__user:hover .editor-autocompleters__user-slug {
  color: #66c6e4; }

.document-outline {
  margin: 20px 0; }
  .document-outline ul {
    margin: 0;
    padding: 0; }

.document-outline__item {
  display: flex;
  margin: 4px 0; }
  .document-outline__item a {
    text-decoration: none; }
  .document-outline__item .document-outline__emdash::before {
    color: #e2e4e7;
    margin-right: 4px; }
  .document-outline__item.is-h2 .document-outline__emdash::before {
    content: "—"; }
  .document-outline__item.is-h3 .document-outline__emdash::before {
    content: "——"; }
  .document-outline__item.is-h4 .document-outline__emdash::before {
    content: "———"; }
  .document-outline__item.is-h5 .document-outline__emdash::before {
    content: "————"; }
  .document-outline__item.is-h6 .document-outline__emdash::before {
    content: "—————"; }

.document-outline__button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: flex-start;
  margin: 0 0 0 -1px;
  padding: 2px 5px 2px 1px;
  color: #23282d;
  text-align: left; }
  .document-outline__button:disabled {
    cursor: default; }
  .document-outline__button:focus {
    box-shadow: 0 0 0 1px rgb(0, 124, 186);
    outline: 1px solid transparent; }
  body.admin-color-sunrise .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(209, 134, 74); }
  body.admin-color-ocean .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(163, 185, 162); }
  body.admin-color-midnight .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(225, 77, 67); }
  body.admin-color-ectoplasm .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(167, 182, 86); }
  body.admin-color-coffee .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(194, 166, 140); }
  body.admin-color-blue .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(217, 171, 89); }
  body.admin-color-light .document-outline__button:focus{
    box-shadow: 0 0 0 1px rgb(0, 133, 186); }

.document-outline__level {
  background: #e2e4e7;
  color: #23282d;
  border-radius: 3px;
  font-size: 13px;
  padding: 1px 6px;
  margin-right: 4px; }
  .is-invalid .document-outline__level {
    background: #f0b849; }

.document-outline__item-content {
  padding: 1px 0; }

.components-editor-notices__dismissible {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  color: #191e23; }

.components-editor-notices__pinned {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  color: #191e23; }

.components-editor-notices__dismissible .components-notice,
.components-editor-notices__pinned .components-notice {
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  min-height: 60px; }
  .components-editor-notices__dismissible .components-notice .components-notice__dismiss,
  .components-editor-notices__pinned .components-notice .components-notice__dismiss {
    margin-top: 12px; }

.components-editor-notices__snackbar {
  width: 100%; }

.editor-entities-saved-states__save-button {
  display: block;
  margin-left: auto;
  margin-right: 0; }

.editor-error-boundary {
  margin: auto;
  max-width: 780px;
  padding: 20px;
  margin-top: 60px;
  box-shadow: 0 3px 30px rgba(25, 30, 35, 0.2); }

.editor-page-attributes__template {
  margin-bottom: 10px; }
  .editor-page-attributes__template label,
  .editor-page-attributes__template select {
    width: 100%; }

.editor-page-attributes__order {
  width: 100%; }
  .editor-page-attributes__order .components-base-control__field {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .editor-page-attributes__order input {
    width: 66px; }

.editor-post-excerpt__textarea {
  width: 100%;
  margin-bottom: 10px; }

.editor-post-featured-image {
  padding: 0; }
  .editor-post-featured-image__container {
    margin-bottom: 1em;
    position: relative; }
  .editor-post-featured-image .components-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px; }
  .editor-post-featured-image .components-button + .components-button {
    display: block;
    margin-top: 1em; }
  .editor-post-featured-image .components-responsive-wrapper__content {
    max-width: 100%;
    width: auto; }

.editor-post-featured-image__toggle,
.editor-post-featured-image__preview {
  display: block;
  width: 100%;
  padding: 0;
  transition: all 0.1s ease-out;
  box-shadow: 0 0 0 0 #00a0d2; }
  @media (prefers-reduced-motion: reduce) {
    .editor-post-featured-image__toggle,
    .editor-post-featured-image__preview {
      transition-duration: 0s; } }

.editor-post-featured-image__preview {
  height: auto; }

.editor-post-featured-image__preview:not(:disabled):not([aria-disabled="true"]):focus {
  box-shadow: 0 0 0 4px #00a0d2; }

.editor-post-featured-image__toggle {
  border: 1px dashed #a2aab2;
  background-color: #edeff0;
  min-height: 90px;
  line-height: 20px;
  padding: 8px 0;
  text-align: center; }
  .editor-post-featured-image__toggle:hover {
    background-color: #f8f9f9; }

.editor-post-format {
  flex-direction: column;
  align-items: stretch;
  width: 100%; }

.editor-post-format__content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.editor-post-format__suggestion {
  text-align: right;
  font-size: 13px; }

.editor-post-last-revision__title {
  width: 100%;
  font-weight: 600; }
  .editor-post-last-revision__title .dashicon {
    margin-right: 5px; }

.components-button:not(:disabled):not([aria-disabled="true"]).editor-post-last-revision__title {
  height: auto; }
  .components-button:not(:disabled):not([aria-disabled="true"]).editor-post-last-revision__title:hover, .components-button:not(:disabled):not([aria-disabled="true"]).editor-post-last-revision__title:active {
    background: #f3f4f5 !important;
    border: none !important;
    box-shadow: none !important; }
  .components-button:not(:disabled):not([aria-disabled="true"]).editor-post-last-revision__title:focus {
    color: #191e23;
    border: none;
    box-shadow: none;
    outline-offset: -2px;
    outline: 1px dotted #555d66; }

.editor-post-locked-modal {
  height: auto;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  max-width: 480px; }
  .editor-post-locked-modal .components-modal__header {
    height: 36px; }
  .editor-post-locked-modal .components-modal__content {
    height: auto; }

.editor-post-locked-modal__buttons {
  margin-top: 10px; }
  .editor-post-locked-modal__buttons .components-button {
    margin-right: 5px; }

.editor-post-locked-modal__avatar {
  float: left;
  margin: 5px;
  margin-right: 15px; }

.editor-post-permalink {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 8px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  white-space: nowrap;
  background-clip: padding-box;
  border: 1px solid #1e1e1e;
  border-radius: 2px;
  background-color: #fff;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 480px) {
    .editor-post-permalink {
      padding: 4px; } }
  @media (min-width: 600px) {
    .editor-post-permalink {
      margin-left: -1px;
      margin-right: -1px; } }
  .editor-post-permalink.editor-post-permalink > * {
    margin-bottom: 8px; }
    @media (min-width: 480px) {
      .editor-post-permalink.editor-post-permalink > * {
        margin-bottom: 0; } }
  .editor-post-permalink button {
    flex-shrink: 0; }

.editor-post-permalink__copy {
  border-radius: 4px;
  padding: 6px; }

.editor-post-permalink__copy.is-copied {
  opacity: 0.3; }

.editor-post-permalink__label {
  margin: 0 10px 0 5px;
  font-weight: 600; }

.editor-post-permalink__link {
  color: #7e8993;
  text-decoration: underline;
  margin-right: 10px;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: left; }

.editor-post-permalink-editor {
  width: 100%;
  min-width: 20%;
  display: inline-flex;
  align-items: center; }
  .editor-post-permalink-editor .editor-post-permalink__editor-container {
    flex: 0 1 100%;
    display: flex;
    overflow: hidden;
    padding: 1px 0; }
    .editor-post-permalink-editor .editor-post-permalink__editor-container .editor-post-permalink-editor__prefix {
      flex: 1 1 auto; }
      @media (min-width: 600px) {
        .editor-post-permalink-editor .editor-post-permalink__editor-container .editor-post-permalink-editor__prefix {
          flex: 1 0 auto; } }
    .editor-post-permalink-editor .editor-post-permalink__editor-container .editor-post-permalink-editor__edit {
      flex: 1 1 100%; }
  .editor-post-permalink-editor .editor-post-permalink-editor__save {
    margin-left: auto; }

.editor-post-permalink-editor__prefix {
  color: #6c7781;
  min-width: 20%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis; }

.editor-post-permalink input[type="text"].editor-post-permalink-editor__edit {
  min-width: 10%;
  width: 100%;
  margin: 0 3px;
  padding: 2px 4px; }

.editor-post-permalink-editor__suffix {
  color: #6c7781;
  margin-right: 6px;
  flex: 0 0 0%; }

.editor-post-permalink-editor__prefix {
  text-align: left; }

/* rtl:begin:ignore */
.editor-post-permalink__link {
  text-align: left; }

.editor-post-permalink__editor-container,
.editor-post-permalink__link {
  direction: ltr; }

.editor-post-permalink__link::after {
  content: "";
  display: block;
  position: absolute;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 90%);
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 20%;
  height: auto; }

/* rtl:end:ignore */
.editor-post-publish-button__button.has-changes-dot::before {
  background: currentcolor;
  border-radius: 4px;
  content: "";
  height: 8px;
  margin: auto 5px auto -3px;
  width: 8px; }

.editor-post-publish-panel {
  background: #fff; }

.editor-post-publish-panel__content {
  min-height: calc(100% - 144px); }
  .editor-post-publish-panel__content .components-spinner {
    display: block;
    margin: 100px auto 0; }

.editor-post-publish-panel__header {
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  height: 60px;
  border-bottom: 1px solid #e2e4e7;
  display: flex;
  align-items: center;
  align-content: space-between; }
  .editor-post-publish-panel__header .components-button.has-icon {
    position: absolute;
    right: 8px; }

.editor-post-publish-panel__header-publish-button {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  text-align: right;
  flex-wrap: nowrap; }

.editor-post-publish-panel__header-published {
  flex-grow: 1; }

.editor-post-publish-panel__footer {
  padding: 16px; }

.components-button.editor-post-publish-panel__toggle.is-primary {
  display: inline-flex;
  align-items: center; }
  .components-button.editor-post-publish-panel__toggle.is-primary.is-busy .dashicon {
    display: none; }
  .components-button.editor-post-publish-panel__toggle.is-primary .dashicon {
    margin-right: -4px; }

.editor-post-publish-panel__link {
  font-weight: 400;
  padding-left: 4px; }

.editor-post-publish-panel__prepublish {
  padding: 16px; }
  .editor-post-publish-panel__prepublish strong {
    color: #191e23; }
  .editor-post-publish-panel__prepublish .components-panel__body {
    background: #fff;
    margin-left: -16px;
    margin-right: -16px; }
  .editor-post-publish-panel__prepublish .editor-post-visibility__dialog-legend {
    display: none; }
  .editor-post-publish-panel__prepublish .components-datetime {
    padding: 0; }

.post-publish-panel__postpublish .components-panel__body {
  border-bottom: 1px solid #e2e4e7;
  border-top: none; }

.post-publish-panel__postpublish-buttons {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  margin: -5px; }
  .post-publish-panel__postpublish-buttons > * {
    flex-grow: 1;
    margin: 5px; }
  .post-publish-panel__postpublish-buttons .components-button {
    height: auto;
    justify-content: center;
    padding: 3px 10px 4px;
    line-height: 1.6;
    text-align: center;
    white-space: normal; }
  .post-publish-panel__postpublish-buttons .components-clipboard-button {
    width: 100%; }

.post-publish-panel__postpublish-post-address {
  margin-bottom: 16px; }
  .post-publish-panel__postpublish-post-address input[readonly] {
    padding: 10px;
    background: #e8eaeb;
    overflow: hidden;
    text-overflow: ellipsis; }

.post-publish-panel__postpublish-header {
  font-weight: 500; }

.post-publish-panel__postpublish-subheader {
  margin: 0 0 8px; }

.post-publish-panel__tip {
  color: #f0b849; }

.editor-post-saved-state {
  display: flex;
  align-items: center;
  width: 28px;
  padding: 12px 4px;
  color: #757575;
  overflow: hidden;
  white-space: nowrap; }
  .editor-post-saved-state svg {
    display: inline-block;
    flex: 0 0 auto;
    fill: currentColor;
    margin-right: 8px; }
  @media (min-width: 600px) {
    .editor-post-saved-state {
      width: auto;
      padding: 8px 12px;
      text-indent: inherit; }
      .editor-post-saved-state .dashicon {
        margin-right: 4px; } }

.editor-post-taxonomies__hierarchical-terms-list {
  max-height: 14em;
  overflow: auto;
  padding-left: 2px; }

.editor-post-taxonomies__hierarchical-terms-choice {
  margin-bottom: 8px; }

.editor-post-taxonomies__hierarchical-terms-subchoices {
  margin-top: 8px;
  margin-left: 16px; }

.components-button.editor-post-taxonomies__hierarchical-terms-submit,
.components-button.editor-post-taxonomies__hierarchical-terms-add {
  margin-top: 12px; }

.editor-post-taxonomies__hierarchical-terms-label {
  display: inline-block;
  margin-top: 12px; }

.editor-post-taxonomies__hierarchical-terms-input {
  margin-top: 8px;
  width: 100%; }

.editor-post-taxonomies__hierarchical-terms-filter {
  margin-bottom: 8px;
  width: 100%; }

.editor-post-text-editor {
  border: 1px solid #e2e4e7 !important;
  display: block;
  margin: 0 0 2em;
  width: 100%;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  font-family: Menlo, Consolas, monaco, monospace !important;
  line-height: 150%;
  border-radius: 0 !important;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px !important; }
  @media (min-width: 600px) {
    .editor-post-text-editor {
      font-size: 14px !important; } }
  .editor-post-text-editor:hover, .editor-post-text-editor:focus {
    border: 1px solid #1e1e1e !important;
    box-shadow: none !important;
    outline-offset: -2px !important; }

.editor-post-text-editor__toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .editor-post-text-editor__toolbar button {
    height: 30px;
    background: none;
    padding: 0 8px;
    margin: 3px 4px;
    text-align: center;
    cursor: pointer;
    font-family: Menlo, Consolas, monaco, monospace;
    color: #555d66;
    border: 1px solid transparent; }
    .editor-post-text-editor__toolbar button:first-child {
      margin-left: 0; }
    .editor-post-text-editor__toolbar button:hover, .editor-post-text-editor__toolbar button:focus {
      outline: none;
      border: 1px solid #555d66; }

.editor-post-text-editor__bold {
  font-weight: 600; }

.editor-post-text-editor__italic {
  font-style: italic; }

.editor-post-text-editor__link {
  text-decoration: underline;
  color: #0085ba; }

body.admin-color-sunrise .editor-post-text-editor__link{
  color: #d1864a; }

body.admin-color-ocean .editor-post-text-editor__link{
  color: #a3b9a2; }

body.admin-color-midnight .editor-post-text-editor__link{
  color: #e14d43; }

body.admin-color-ectoplasm .editor-post-text-editor__link{
  color: #a7b656; }

body.admin-color-coffee .editor-post-text-editor__link{
  color: #c2a68c; }

body.admin-color-blue .editor-post-text-editor__link{
  color: #82b4cb; }

body.admin-color-light .editor-post-text-editor__link{
  color: #0085ba; }

.editor-post-text-editor__del {
  text-decoration: line-through; }

.edit-post-post-visibility__dialog,
.editor-post-visibility__dialog-fieldset {
  padding: 4px;
  padding-top: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-legend,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-legend {
    font-weight: 600;
    margin-bottom: 1em;
    margin-top: 0.5em;
    padding: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-radio,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-radio {
    margin-top: 2px; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-label,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-label {
    font-weight: 600; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-info,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-info {
    margin-top: 0;
    margin-left: 28px; }
  .edit-post-post-visibility__dialog .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__choice:last-child .editor-post-visibility__dialog-info {
    margin-bottom: 0; }
  .edit-post-post-visibility__dialog .editor-post-visibility__dialog-password-input,
  .editor-post-visibility__dialog-fieldset .editor-post-visibility__dialog-password-input {
    margin-left: 28px; }

.editor-post-title__block {
  position: relative;
  padding: 5px 0;
  font-size: 16px; }
  @media (min-width: 600px) {
    .editor-post-title__block {
      padding: 5px 2px; } }
  .editor-post-title__block .editor-post-title__input {
    display: block;
    width: 100%;
    margin: 0;
    box-shadow: none;
    background: transparent;
    font-family: "Noto Serif", serif;
    line-height: 1.4;
    color: #191e23;
    transition: border 0.1s ease-out, box-shadow 0.1s linear;
    padding: 19px 14px;
    word-break: keep-all;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    outline: 1px solid transparent;
    font-size: 2.44em;
    font-weight: 600; }
    @media (prefers-reduced-motion: reduce) {
      .editor-post-title__block .editor-post-title__input {
        transition-duration: 0s; } }
    @media (min-width: 600px) {
      .editor-post-title__block .editor-post-title__input {
        border-width: 1px; } }
    .editor-post-title__block .editor-post-title__input::-webkit-input-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title__block .editor-post-title__input::-moz-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title__block .editor-post-title__input:-ms-input-placeholder {
      color: rgba(22, 36, 53, 0.55); }
    .editor-post-title__block .editor-post-title__input:focus {
      border: 1px solid transparent;
      outline: 1px solid transparent;
      box-shadow: none; }
  .editor-post-title__block.is-focus-mode .editor-post-title__input {
    opacity: 0.5;
    transition: opacity 0.1s linear; }
    @media (prefers-reduced-motion: reduce) {
      .editor-post-title__block.is-focus-mode .editor-post-title__input {
        transition-duration: 0s; } }
    .editor-post-title__block.is-focus-mode .editor-post-title__input:focus {
      opacity: 1; }

.editor-post-title .editor-post-permalink {
  font-size: 13px;
  color: #191e23;
  height: auto;
  position: relative;
  top: -2px;
  width: 100%; }
  @media (min-width: 480px) {
    .editor-post-title .editor-post-permalink {
      position: absolute;
      top: -33px;
      right: 0;
      flex-wrap: nowrap;
      width: auto; } }
  @media (min-width: 600px) {
    .editor-post-title .editor-post-permalink {
      left: 2px;
      right: 2px; } }

.editor-post-trash.components-button {
  color: #b52727;
  border-color: #b52727;
  justify-content: center; }
  .editor-post-trash.components-button:not(:disabled):not([aria-disabled="true"]):hover, .editor-post-trash.components-button:not([aria-disabled="true"]):focus {
    color: #a02222;
    border-color: #a02222; }

.table-of-contents__popover.components-popover .components-popover__content {
  min-width: 380px; }

.components-popover.table-of-contents__popover {
  z-index: 99998; }

.table-of-contents__popover .components-popover__content {
  padding: 16px; }
  @media (min-width: 600px) {
    .table-of-contents__popover .components-popover__content {
      max-height: calc(100vh - 120px);
      overflow-y: auto; } }

.table-of-contents__popover hr {
  margin: 10px -16px 0; }

.table-of-contents__wrapper:focus {
  color: #191e23;
  outline-offset: -1px;
  outline: 1px dotted #555d66;
  outline-offset: 8px; }

.table-of-contents__counts {
  display: flex;
  flex-wrap: wrap;
  margin: 0; }

.table-of-contents__count {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #6c7781;
  padding-right: 8px;
  margin-bottom: 0; }
  .table-of-contents__count:last-child {
    padding-right: 0; }

.table-of-contents__number,
.table-of-contents__popover .word-count {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  color: #555d66; }

.table-of-contents__title {
  display: block;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600; }

.editor-template-validation-notice {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .editor-template-validation-notice .components-button {
    margin-left: 5px; }
